import CrudService from '../../services/crudService'
import { http } from '../../libs/react-mpk/services';
import AppMode from '../../AppMode'

let service = null
let serviceUri = '/file/manage'
if(AppMode.onPremise){
  service = new CrudService('/api/eunifikasi/file/manage/log/upload/excel');
  serviceUri = '/api/eunifikasi/file/manage'
} else {
  service = new CrudService('/file/manage/log/upload/excel');
}

service.importData = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUri}/upload/excel`,
    data
  })
}

service.getLog = async(query, fileId)=> {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUri}/validation/upload/excel/${fileId}`,
    query
  })
}

service.download = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUri}/download/template`,
    query
  })
}

service.downloadFile = async(fileId)=> {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUri}/download/upload/xls/${fileId}`
  })
}

service.getOrganization = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `/core/host/organization`,
    query
  })
}

service.downloadLog = async(id, query) => {
 return http.request({
    method: http.methods.GET,
    url: `${serviceUri}/download/upload/xls/log/${id}`,
    query
  })
}

service.reupload = async(data) => {
  return http.request({
     method: http.methods.POST,
     url: `${serviceUri}/log/upload/excel/check-failed`,
     data
   })
 }

export default service