import React, { useEffect, useState } from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import Modal from '../../libs/react-mpk/components/Modal'
import t from 'counterpart'
import service from './LogEmail.service'
import moment from 'moment'
import { toast } from '../../libs/react-mpk/services'
import { format } from '../../libs/react-mpk/services/number.service'
import { Chip, ListItem, Button } from 'react-md'
import errorService from '../../services/errorService'
import { reject } from 'lodash'
import { inject, observer } from 'mobx-react'
import iziToast from 'izitoast'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import DataForm from '../../libs/react-mpk/components/DataForm'
import AppMode from '../../AppMode'

const LogEmailHistory = ({
    className       = '',
    showCommandbar  = true,
    history,
    match,
    modalStore
}) => {

    const basePath = `log-data-email-email`
    const [data, setData] = useState({loading: true, content: {}})
    const baseId = 'mod-log-data-email-history'
    let [search, setSearch] = useState({})
    
    useEffect(() => {
        if(localStorage.getItem(`${baseId}-saved-search`)){
            setSearch(JSON.parse((localStorage.getItem(`${baseId}-saved-search`))))
          }
        async function initData(){
          try {
              let history = await service.getOne(match.params.id)
              setData({
                loading: false,
                content: history.data
              })
          } catch(e){
            errorService(e)
          }
        }
        initData()
    }, [])

    async function hapusExc(item) {
        try {
            var r = await service.delete(item.id)

            if(r.data && r.data.status == 1) {
            window.open(r.data.data.url)
            iziToast.success({
                message : r.data.message
            })
            } else {
            iziToast.info({
                message : r.data.message
            })
            }
        } catch(e) {
            errorService(e)
        }
    }

    async function hapus(item) {
        modalStore.showConfirm({
            title : t.translate('words.delete'),
            children : t.translate('words.hapusIni'),
            onSubmit : (callback) => {
            hapusExc(item)
            callback()
            }
        })
    }

    return (
      <>
        <TableWrapper useFilter={false}
          baseId = "mod-log-data-email-history"
          title = {t.translate('modules.logDataEmail.history')}
          className = {className}
          defaultData ={[]}
          showActionColumn = {false}
          defaultSortBy = "auditDate"
          isShowFilter={false}
          setPage={search.page} onFetchData = {(params) => {
              return (
                  new Promise(async (resolve, reject) => {
                    try{
                      params = {...params,...search}
                      let getPage = await service.history(match.params.id, params)
                      delete search.page; setSearch(search); getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
                      resolve(getPage)
                    } catch(e){
                      resolve()
                      errorService(e)
                    }
                  })
              )
          }}
          // sideHeader={
          //   <DataForm 
          //     baseId={`${baseId}-search`}
          //       defaultData={search}
          //       submitIconClassName = 'mdi mdi-magnify'
          //       submitLabel={`${t.translate('words.cari')}`}
          //       additionalAction={[
          //         {
          //           show: true,
          //           render: ()=> (
          //             <Button style={{ marginRight: 15 }} onClick={()=> {
          //               setSearch({})
          //               var newsearch = { page: 0 }; setSearch(newsearch); localStorage.removeItem(`${baseId}-saved-search`); localStorage.removeItem(`${baseId}-query`);
          //               // DataForm.reset(`${baseId}-search`)
          //               setTimeout(()=> {
          //                 document.getElementsByClassName('mdi-reload')[0].click()
          //               }, 1000)
          //             }}>{t.translate('words.hapus')}</Button>
          //           )
          //         }
          //       ]}
          //       onSubmit={(values, callback)=> {
          //         if(values['createdDate.auditDate']) values['createdDate.auditDate'] = new Date(values['createdDate.auditDate'])
          //         values.page = 0;setSearch(values);localStorage.setItem(`${baseId}-saved-search`, JSON.stringify(values))
          //         setTimeout(()=> {
          //           document.getElementsByClassName('mdi-reload')[0].click()
          //           callback("", false, false)
          //         }, 1000)
          //       }}
          //       definitions={[
          //         {
          //           render: (
          //             <div className="mpk-data-filter">
          //               <div className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}>
          //                 <p>{t.translate(`words.pencarian`)}</p>
          //               </div>
          //             </div>
          //           )
          //         },
          //         {
          //           inputType: inputTypes.INPUT,
          //           label: t.translate(`words.revisionId`),
          //           key: 'revisionId.equals',
          //           type: 'text'
          //         },
          //         {
          //           inputType: inputTypes.INPUT,
          //           label: t.translate(`words.type`),
          //           key: 'type.contains',
          //           type: 'text'
          //         },
          //         {
          //           inputType: inputTypes.INPUT,
          //           label: t.translate(`words.auditBy`),
          //           key: 'auditBy.contains',
          //           type: 'text'
          //         },
          //         {
          //           inputType: inputTypes.INPUT,
          //           label: t.translate(`words.auditDate`),
          //           key: 'auditDate.equals',
          //           type: 'date'
          //         },
          //     ]}
          //   />
          // }
          columns = {[
              {
                  label : t.translate('words.revisionId'),
                  searchable : false,
                  sortable : true,
                  key : 'revisionId',
                  type : TableWrapper.dataTypes.NUMBER,
                  render : (item) => (item.revisionId)
              },
              {
                  label : t.translate('words.type'),
                  searchable : true,
                  sortable : true,
                  key : 'type',
                  type : TableWrapper.dataTypes.STRING,
                  render : (item) => (item.type)
              },
              {
                  label : t.translate('words.auditBy'),
                  searchable : true,
                  sortable : true,
                  key : 'auditBy',
                  type : TableWrapper.dataTypes.STRING,
                  render : (item) => (item.auditBy)
              },
              {
                  label : t.translate('words.auditDate'),
                  searchable : true,
                  sortable : true,
                  key : 'auditDate',
                  type : TableWrapper.dataTypes.STRING,
                  render : (item) => (item.auditDate)
              },
              {
                label: t.translate('words.status'),
                searchable: true,
                sortable : true,
                key: 'status',
                type: TableWrapper.dataTypes.STRING,
                render: item => {
                  if(item.data.success){
                    return <Chip style={{ background: "#8BC34A", color: 'white' }}>{(item.data.success+"").toUpperCase()}</Chip>
                  } else {
                    return <Chip style={{ background: "#F44336", color: 'white' }}>{(item.data.success+"").toUpperCase()}</Chip>
                  } 
                }
              },
              {
                label: t.translate('words.sendAt'),
                searchable: true,
                sortable : true,
                key: 'sendAt',
                type: TableWrapper.dataTypes.STRING,
                render: item => (item.data.sendAt)
              },
              {
                label: t.translate('words.npwpProfile'),
                searchable: true,
                sortable : true,
                key: 'npwpProfile',
                type: TableWrapper.dataTypes.STRING,
                render: item => (item.data.npwpProfile)
              },
              {
                label: t.translate('words.identity'),
                searchable: true,
                sortable : true,
                key: 'identity',
                type: TableWrapper.dataTypes.STRING,
                render: item => (item.data.identity)
              },
              {
                label: t.translate('words.email'),
                searchable: true,
                sortable : true,
                key: 'email',
                type: TableWrapper.dataTypes.STRING,
                render: item => (item.data.email)
              },
              {
                label: t.translate('words.password'),
                searchable: true,
                sortable : true,
                key: 'password',
                type: TableWrapper.dataTypes.STRING,
                render: item => (item.data.password)
              },
              {
                label: t.translate('words.valid'),
                searchable: true,
                sortable : true,
                key: 'valid',
                type: TableWrapper.dataTypes.STRING,
                render: item => {
                  if(item.data.success){
                    return <Chip style={{ background: "#8BC34A", color: 'white' }}>{(item.data.valid+"").toUpperCase()}</Chip>
                  } else {
                    return <Chip style={{ background: "#F44336", color: 'white' }}>{(item.data.valid+"").toUpperCase()}</Chip>
                  } 
                }
              },
              {
                label: t.translate('words.message'),
                searchable: true,
                sortable : true,
                key: 'message',
                type: TableWrapper.dataTypes.STRING,
                render: item => (item.data.message)
              },
          ]}
          // actions = {[]}
          itemActions = {[]}
          showCommandbar={showCommandbar}
          showFilterePeriod={false}
          actions={[
              new TableWrapper.action(`${t.translate('words.kembali')}`, 'mdi mdi-arrow-left', (item) => { 
                if(AppMode.onPremise){
                  history.push(`/onpremise/log-email`)
                } else {
                  if(window.location.href.indexOf('open') != -1){
                    history.goBack()
                  } else {
                    history.goBack()
                  }
                }
              }, true)
          ]}
        />
      </>
    )
}

export default inject('temporaryStore', 'modalStore')(observer(LogEmailHistory))