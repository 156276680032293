import React, {useEffect, useState} from 'react'
import { inputTypes, formatTypes } from '../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './Doss2.service'
import { autorun, toJS } from 'mobx';
import UtilsService from './../../services/utilsService'
import _ from 'lodash'
import ErrorService from './../../services/errorService'
import AppMode from '../../AppMode'

const Doss2Form = ({ authStore, envStore, match, navigationStore }) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({loading: true, content: {}})
  const [kapCode, setKapCode] = useState([])
  const [kap, setKap] = useState([])
  const [kjs, setKjs] = useState([])
  const [showKjs, setShowKjs] = useState(true)
  const [disabledKapKJS, setDisabledKapKJS] = useState(false)
  const [customRules, setCustomRules] = useState({})
  const [inputMasks, setInputMasks] = useState('################')
  const [ssp, setSsp] = useState(true)
  const [label, setLabel] = useState(``)
  const kjs28 = ["401","402", "403", "405", "409", "410", "411", "413", "421",  "499"]
  const kjs22 = ["405"]
  const [listKjs, setListKjs] = useState(kjs28)

  const baseName = `doss2`
  const basePath = `doss2`

  useEffect(() => {
    async function initData(){
      let taxCodeGlobal = []
      try {
        let taxCodes = await service.getTaxObjectCode();
        // hardCode dulu
        taxCodes.data.push({
          id: "2b48c818-809f-44bd-8c49-41f5d5f38faf",
          type: "PPH4-2",
          description: "Pengawasan Konstruksi (Dengan Kualifikasi Usaha)",
          code: "28-409-06",
          rate : 0,
          ss : true,
          kap : '411128',
          kjs : '409'
        })
        taxCodes.data.push({
          id: "2b48c818-809f-44bd-8c49-41f5d5f38faf",
          type: "PPH15",
          description: "Participating Interest Eksploitasi Hulu Migas",
          code: "28-421-05",
          rate : 0,
          ss : true,
          kap : '411128',
          kjs : '421'
        })
        let taxCode  = _.groupBy(taxCodes.data, 'kap')
        taxCodeGlobal = taxCode;
        let taxCodeList = Object.keys(taxCode)
        let kapSelect = []
        taxCodeList.map((d)=> {
          if(d == '411128'){
            kapSelect.push({ label: d, value: d })
          }
        })
        // console.log(taxCode)
        setKap(kapSelect)
        setKapCode(taxCode)
        var kjs = []
        taxCode['411128'].map((d)=> {
          if(d.kjs == "401" ||d.kjs == "402" || d.kjs == "409" || d.kjs == "403" || d.kjs == "421" || d.kjs == "410" || d.kjs == "411" || d.kjs == "413" || d.kjs == "499"){
            d.label = d.code + " " + d.description
            kjs.push(d)
          }
        })
        kjs = _.sortBy(kjs, 'seq')
      } catch(e){}
      if(match.params.id == 'new') {
        try {
          let spt = JSON.parse(localStorage.getItem('spt'))
          setData({loading: false, content: {
            type: 'true',
            sptNpwp: spt.npwp,
            sptYear: spt.year,
            sptMonth: spt.month,
            sptRev: spt.rev,
            kap: "411128"
          }})
        } catch(e){
          ErrorService(e)
        }
      } else {
        // setDisabledKapKJS(true)
        const res = await service.getOne(match.params.id)
        res.data.type = res.data.type + ""
        if(res.data.kap == "411122"){
          setListKjs(kjs22)
        }
        if(res.data.kap == "411128"){
          setListKjs(kjs28)
        }
        if(res.data.type == "true"){
          setSsp(true)
          setLabel(t.translate(`words.nomorBuktiPenyetoran`))
        } else {
          setLabel(t.translate(`words.nomorPbk`))
          setSsp(false)
        }
        try {
          setShowKjs(false)
          var kjs = taxCodeGlobal[res.data.kap]
          var newKjs = []
          kjs.map((d)=> {
            if(d.ss == true){
              d.label = d.code + " " + d.description
              newKjs.push(d)
            }
          })
          // console.log(newKjs)
          setShowKjs(true)
          setKjs(newKjs)
        } catch(e){}
        
        //date ssp
        // if(!AppMode.onPremise) res.data.bpDate = res.data.date

        setData({loading: false, content:res.data})
        
      }
    }
    initData()
  }, [])

  var thousandSeparator = "."
  var decimalSeparator  = ","
  try {  
    if(localStorage.getItem('lang') == 'en'){
      thousandSeparator = ","
      decimalSeparator  = "."
    }
  } catch(e){}

  var backTo = `/product/index/daftar-spt/open/${match.params.sptId}/${basePath}`
  if(AppMode.onPremise){
    backTo = `/onpremise/daftar-spt/open/${match.params.sptId}/${basePath}`
  }
  
  return (
    <FormWrapper
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      backTo={backTo}
      baseId={`mod-form-${baseName}`}
      // title={t.translate(`modules.${baseName}.title`)}
      // hintMessage={t.translate(`modules.${baseName}.formTitle`)}
      hintMessage={t.translate('words.sspFormLabel')}
      onChange={(formData, key, value)=> {
        if(key == 'type' && value == 'true'){
          setCustomRules({ no: ['required', 'min:16'] })
          setInputMasks('################')
          setSsp(true)
          setLabel(t.translate(`words.nomorBuktiPenyetoran`))
        }else if(key == 'type' && value == 'false'){
          setCustomRules({ no: ['required'] })
          setInputMasks(null)
          setLabel(t.translate(`words.nomorPbk`))
          setSsp(false)
        }
        if(key == "kjs"){
          try {
            setShowKjs(false)
            formData['kop'] = null
            setTimeout(()=> {
              var kjs = kapCode[formData.kap]
              var kops = []
              try {
                kjs.map((d)=> {
                  if(d.kjs == value){
                    var kop = {
                      label: d.code + " " + d.description,
                      ...d
                    }
                    
                    if(d.ss == true){
                      kops.push(kop)
                    } 
                  }
                })
              } catch(e){}
              kops = kops
              setKjs(kops)
              setShowKjs(true)
            }, 200)
          } catch(e){}
        }
        if(key == "kap" && value == "411128"){
          setListKjs(kjs28)
        }
        if(key == "kap" && value == "411122"){
          setListKjs(kjs22)
        }
      }}
      customRules = {customRules}
      defaultData={data.content}
      definitions={[
        {
          render: (
            <div style={{ width: '100%' }}>
              <p>{t.translate(`words.jenisBuktiPenyetoran`)}</p>
            </div>
          )
        },
        {
          inputType: inputTypes.RADIO,
          label: t.translate(`words.suratSetoranPajak`),
          key: 'type',
          type: 'boolean',
          name: 'type',
          value: 'true',
        },
        {
          inputType: inputTypes.RADIO,
          label: t.translate(`words.pemindahbukuan`),
          key: 'type',
          type: 'boolean',
          name: 'type',
          value: 'false',
        },
        {
          inputType: inputTypes.INPUT,
          label: label,
          key: 'no',
          type: 'text',
          validation: ['required', 'min:16'],
        },
        // {
        //   inputType: inputTypes.AUTOCOMPLETE,
        //   label: t.translate(`words.kodeAkunPajak`),
        //   key: 'kap',
        //   validation: 'required',
        //   labelKey: 'label', 
        //   valueKey: 'value',
        //   data: kap,
        //   disabled: disabledKapKJS
        // },
        {
          inputType: inputTypes.SELECT,
          label: t.translate(`words.kodeAkunPajak`),
          key: 'kap',
          type: 'text',
          validation: 'required',
          options: ["411128","411122"],
          disabled: false
        },
        {
          inputType: inputTypes.SELECT,
          label: t.translate(`words.kodeJenisSetoran`),
          key: 'kjs',
          type: 'text',
          validation: 'required',
          options: listKjs,
          disabled: disabledKapKJS
        },
        {
          inputType: inputTypes.AUTOCOMPLETE,
          label: t.translate(`words.kop`),
          key: 'kop',
          validation: 'required',
          labelKey: 'label', 
          valueKey: 'label',
          data: kjs,
          show: showKjs,
          disabled: disabledKapKJS
        },
        // {
        //   inputType: inputTypes.INPUT,
        //   label: t.translate(`words.kop`),
        //   key: 'kop',
        //   type: 'text',
        //   validation: 'required',
        //   disabled: true
        // },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          thousandSeparator: thousandSeparator,
          decimalSeparator: decimalSeparator,
          isNumericString: true,
          label: t.translate(`words.jumlahPenghasilanBruto`),
          key: 'bruto',
          type: 'text',
          validation: 'required',
          allowNegative: false,
            isAllowed: (values)=> {
              if(values.floatValue == 0){
                return false
              } else {
                return true 
              }
            }
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          thousandSeparator: thousandSeparator,
          decimalSeparator: decimalSeparator,
          isNumericString: true,
          label: t.translate(`words.jumlahDisetor`),
          key: 'pph',
          type: 'text',
          validation: 'required'
        },
        {
          inputType: inputTypes.DATEPICKER,
          label: t.translate(`words.tanggal`),
          key: 'bpDate',
          // key: 'date',
          type: 'date',
          validation: 'required'
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.reference`),
          key: 'reference',
          // key: 'date',
          type: 'text',
        }
      ]}
      onSubmit={async (data, callback) => {
        try {
          var res = {}
          var formData = Object.assign({}, data)
          if(formData.type == 'true'){
            formData.type = true
          } else {
            formData.type = false
          }
          var getKjs = kjs.map((d)=> {
            if(d.label == formData.kop){
              formData.kop = d.code
            }
          })

          if(match.params.id == 'new') {
            res = await service.post(formData)
          } else {
            res = await service.put(formData.id, formData)
          }
          callback(t.translate('sentences.tersimpan'), false, false, false)
          navigationStore.redirectTo(backTo)     
        } catch(e){
          ErrorService(e)
          callback("", false, false, false)
          return
        } 
      }}
    />
  )
}

export default inject('authStore', 'envStore', 'navigationStore')(observer(Doss2Form))
