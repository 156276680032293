import React, { useEffect, useState } from 'react'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './MasterNpwp.service'
import ErrorService from './../../services/errorService'
import AppMode from '../../AppMode'
import { style } from 'react-dom-factories'

const MasterNpwpForm = ({ authStore, envStore, match, history }) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({ loading: true, content: {} })
  const [organization, setOrganization] = useState([])

  const baseName = `masterNpwp`
  const basePath = `master-npwp`

  const getOrganizations = async (values) => {
    try {
      var params = {
        page: 0,
        size: 1000
      }
      if (isNaN(values)) {
        params['field'] = 'name'
        params['query'] = values
      } else {
        params['field'] = 'npwp'
        params['query'] = values
      }
      let organization = await service.getOrganization(params)
      var getAll = organization.data
      var organizationsFilter = []
      try {
        getAll.map((og) => {
          og.label = og.npwp + " - " + og.name
          if (og.certExists) organizationsFilter.push({
            value: og,
            label: og.label
          })
        })
      } catch (e) {
        organization.data.map((d) => {
          d.label = d.npwp + " - " + d.name
          organizationsFilter.push({
            value: d,
            label: d.label
          })
        })
      }
      setOrganization(organizationsFilter)
      return organizationsFilter
    } catch (e) {
      return []
    }
  }

  useEffect(() => {
    async function initData() {
      try {
        if (match.params.id == 'new') {
          setData({ loading: false, content: {} })
          return true
        } else {
          const res = await service.getOne(match.params.id)
          setData({ loading: false, content: res.data })
        }
      } catch (e) {

      }
    }
    getOrganizations()
    initData()
  }, [])

  let backTo = ``
  var showSptIn;
  if(match.path.indexOf('open') != -1){
    if(AppMode.onPremise){
      backTo = `/onpremise/daftar-spt/open/${match.params.sptId}/${basePath}`
    } else {
      backTo = `/product/index/daftar-spt/open/${match.params.sptId}/${basePath}`
    }
    showSptIn = true
  } else {
    if(AppMode.onPremise){
      backTo = `/onpremise/${basePath}`
    } else {
      backTo = `/product/index/${basePath}`
    }
    showSptIn = false
  }

  return (
    <FormWrapper
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      style={{
        maxWidth: '80%'
      }}
      backTo={backTo}
      baseId={`mod-form-${baseName}`}
      title={t.translate(`modules.${baseName}.formTitle`)}
      defaultData={data.content}
      onChange={(formData, key, value) => {
        try {
          if (key == 'organization') {
            formData.input = formData.organization.npwp
            formData.organizationNpwp = formData.organization.npwp
          }
        } catch (e) { }
      }}
      definitions={[
        {
          inputType: inputTypes.REACT_SELECT,
          label: t.translate(`words.organization`),
          key: 'organization',
          validation: 'required',
          labelKey: 'label',
          valueKey: 'label',
          data: organization,
          options: organization,
          async: true,
          defaultOptions: organization,
          loadOptions: async (inputValues) => {
            if (inputValues.length % 2) {
              var options = await getOrganizations(inputValues)
              return options
            } else {
              return organization
            }
          },
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.input`),
          key: 'input',
          width: '100%',
          type: 'text',
          validation: 'required',
        },
        {
          inputType: inputTypes.SELECT,
          label: t.translate(`words.npwp16`),
          key: 'npwp16',
          width: '50%',
          type: 'text',
          options: [
              { label: 'Ya', value: 'Y' },
              { label: 'Tidak', value: 'N' },
          ]
        },
        {
          inputType: inputTypes.SELECT,
          label: t.translate(`words.nitku`),
          key: 'nitku',
          width: '50%',
          type: 'text',
          options: [
              { label: 'Ya', value: 'Y' },
              { label: 'Tidak', value: 'N' },
          ]
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: t.translate(`words.success`),
          key: 'success',
          width: '50%',
          type: 'text',
          style : {
            display : match.params.id == 'new' ? 'none' : null
          },
          disabled: true,
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.message`),
          key: 'message',
          width: '50%',
          type: 'text',
          style : {
            display : match.params.id == 'new' ? 'none' : null
          },
          disabled: true
        },
      ]}
      onSubmit={async (data, callback) => {
        var res = {}
        try {
          if (match.params.id == 'new') {
            var formData = Object.assign({}, data)
            delete formData.organization
            res = await service.post(formData)
          } else {
            var formData = Object.assign({}, data)
            delete formData.organization
            res = await service.put(formData.id, formData)
          }
          callback(t.translate('sentences.tersimpan'), false, false)
          var redirectTo = `${backTo}`
          if (AppMode.onPremise) {
            redirectTo = `/onpremise/${basePath}`
          }
          history.push(redirectTo)
        } catch (e) {
          ErrorService(e)
          callback("", false, false)
          return
        }
      }}
    />
  )
}

export default inject('authStore', 'envStore')(observer(MasterNpwpForm))
