import React, {useEffect, useState} from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import service from './Signer.service'
import errorService from './../../services/errorService'
import { inject, observer } from 'mobx-react'
import iziToast from 'izitoast'
import { Chip, ListItem, List, Button } from 'react-md'
import LoadingOverlay from 'react-loading-overlay'
import bluebird from 'bluebird'
import errorServiceGet from '../../services/errorServiceGet'
import DataForm from '../../libs/react-mpk/components/DataForm'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import SptService from './Signer.service'
import UtilsService from './../../services/utilsService'
import { toJS } from 'mobx'

const Signer = ({
  className       = '',
  showCommandbar  = true,
  history,
  modalStore,
  authStore,
  ...props
}) => {

  const basePath = `signer`
  const [ploading, setPloading] = useState({ loading: false, message: '' })
  const [data, setData] = useState([])
  const baseId = 'mod-signer'
  let [search, setSearch] = useState({})
  const [organization, setOrganization] = useState({})
  var insideSpt = false

  useEffect(()=> {
    if(localStorage.getItem(`${baseId}-saved-search`)){
      setSearch(JSON.parse((localStorage.getItem(`${baseId}-saved-search`))))
    }

    getOrganizations()

  }, [])
  
  async function hapusExc(item) {
    try {
        var proses = t.translate('words.hapus')
        var selected = JSON.parse(TableWrapper.getSelected(baseId))
        var selectedData = []
        selected.map((d)=> selectedData.push(data[d]))
        setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
        var errors = []
        bluebird.mapSeries(selectedData, async (di, di2)=> {
          setTimeout(async ()=> {
            try {
              let proccedThis = await service.delete(di.id)
            } catch(e){
              errors.push(await errorServiceGet(e, di, di2))
            }
            setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
            if((di2+1) == selectedData.length){
              setPloading({ loading: false, message: `` })
              if(errors.length == 0){
                iziToast.success({
                  title: proses,
                  message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
                })
              } else {
                modalStore.showInfo({
                  title: '',
                  children: (
                    <List>
                      {errors.map((er)=> {
                        return <ListItem primaryText={''} secondaryText={er}></ListItem>
                      })}
                    </List>
                  )  
                })
              }
              try{
                var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
                if(checkboxRoot.checked){
                  if(checkboxRoot.getAttribute('aria-checked')){
                    checkboxRoot.click()
                    checkboxRoot.click()
                  } else {
                    checkboxRoot.click()
                  }
                }
                document.getElementsByClassName('mdi-reload')[0].click()
              }catch(e){}
            }
          }, 1000 * di2)
        })
    } catch(e) {
        errorService(e)
    }
  }

  async function hapus(item) {
    modalStore.showConfirm({
      title : t.translate('words.delete'),
      children : t.translate('words.hapusIni'),
      onSubmit : (callback) => {
        hapusExc(item)
        callback()
      }
    })
  }

  async function setActiveExec(item){
    try {
      var proses = t.translate('words.active')
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      let res = await service.getActive(item)
      setPloading({ loading: false, message: `` })
      document.getElementsByClassName('mdi-reload')[0].click()
      iziToast.success({
        title: proses,
        message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
      })
    } catch(e){
      setPloading({ loading: false, message: `` })
      errorService(e)
    }
  }

  async function setActive(item){
    modalStore.showConfirm({
      children : t.translate('words.askActive'),
      onSubmit : (callback) => {
        setActiveExec(item)
        callback()
      }
    })
  }

  async function setNonActiveExec(item){
    try {
      var proses = t.translate('words.nonactive')
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      let res = await service.getNonActive(item)
      setPloading({ loading: false, message: `` })
      document.getElementsByClassName('mdi-reload')[0].click()
      iziToast.success({
        title: proses,
        message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
      })
    } catch(e){
      setPloading({ loading: false, message: `` })
      errorService(e)
    }
  }

  async function setNonActive(item){
    modalStore.showConfirm({
      children : t.translate('words.askNonactive'),
      onSubmit : (callback) => {
        setNonActiveExec(item)
        callback()
      }
    })
  }

  async function setDefaultExec(item){
    try {
      var proses = t.translate('words.default')
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      let res = await service.getDefault(item)
      setPloading({ loading: false, message: `` })
      document.getElementsByClassName('mdi-reload')[0].click()
      iziToast.success({
        title: proses,
        message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
      })
    } catch(e){
      setPloading({ loading: false, message: `` })
      errorService(e)
    }
  }

  async function setDefault(item){
    modalStore.showConfirm({
      children : t.translate('words.askDefault'),
      onSubmit : (callback) => {
        setDefaultExec(item)
        callback()
      }
    })
  }

  async function setReconExec(item){
    try {
      var proses = t.translate('words.recon')
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      let res = await service.getRecon(item)
      setPloading({ loading: false, message: `` })
      document.getElementsByClassName('mdi-reload')[0].click()
      iziToast.success({
        title: proses,
        message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
      })
    } catch(e){
      setPloading({ loading: false, message: `` })
      errorService(e)
    }
  }

  async function setRecon(item){
    modalStore.showConfirm({
      children : t.translate('words.askRecon'),
      onSubmit : (callback) => {
        setReconExec(item)
        callback()
      }
    })
  }

  const getOrganizations = async ()=> {
    let organizations = UtilsService.getOrganizations(toJS(authStore))
    organizations.map((d)=> {
      d.label = d.npwp + " - " + d.name
      try {
        delete d.permission
      } catch(e){}
    })
    var getAll = await bluebird.mapSeries(organizations, async (org, OrgIndex)=> {
      return new Promise(async (resolve)=> {
        setTimeout(async ()=> {
          try {
            let getSearch = await SptService.getCertStatus(org.npwp)
            if(getSearch && getSearch.data && getSearch.data.data){
              org = {
                ...org,
                ...getSearch.data.data
              }
              resolve(org)
            } else {
              resolve(org)
            }
          } catch(e){
            resolve(org)
          }
        }, 50 * OrgIndex)
      })
    })
    var organizationsFilter = []
    try {
      getAll.map((og)=> {
        if(og.certExists) organizationsFilter.push({
          value: og,
          label: og.label
        })
      })
    } catch(e){
      organizations.map((d)=> {
        organizationsFilter.push({
          value: d.npwp,
          label: d.label
        })
      })
    }
    setOrganization(organizationsFilter)
  }

  const getOrganizationsOp = async (values)=> {
    try {
      var params = {
        page: 0,
        size: 100,
        sort: 'createdDate,DESC'
      }
      if(isNaN(values)){
        params['name.contains'] = values
      } else {
        params['npwp.contains'] = values
      }
      let res = await SptService.getOrganizationOp(params)
      res.data.map((d)=> {
        d.label = d.npwp + " - " + d.name
        d.value = d.npwp
      })
      setOrganization(res.data)
      return res.data
    } catch(e){
      errorService(e)
      return []
    }
  }

  var actions = [
    new TableWrapper.action('Add', 'mdi mdi-plus', () => history.push(`${basePath}/new`), true),
    new TableWrapper.action(`${t.translate('words.Delete')}`, 'mdi mdi-delete', (item) => { hapus(item)}, true),
  ]

  var itemActions = [
    new TableWrapper.action(`${t.translate('words.Edit')}`, 'mdi mdi-pencil', (item) => history.push(`${basePath}/${item.id}`), true),
    new TableWrapper.action(`${t.translate('words.active')}`, 'mdi mdi-check', (item) => setActive(item), (item)=> { 
      if(item.active == false){
        return true
      } else {
        return false
      }
    }),
    new TableWrapper.action(`${t.translate('words.nonactive')}`, 'mdi mdi-close', (item) => setNonActive(item), (item)=> {
      if(item.active == true){
        return true
      } else {
        return false
      }
    }),
    new TableWrapper.action(`${t.translate('words.default')}`, 'mdi mdi-check-all', (item) => setDefault(item), true),
    new TableWrapper.action(`${t.translate('words.recon')}`, 'mdi mdi-file', (item) => setRecon(item), true),
    new TableWrapper.action(`${t.translate('words.Delete')}`, 'mdi mdi-delete', (item) => { hapus(item)}, true),
  ]

  var showCommandbars = true
  try {
    if(props.onSearch){
      showCommandbars = false
      actions = []
      itemActions = [
        new TableWrapper.action(`${t.translate('words.pick')}`, 'mdi mdi-check', (item) => {
          var data = {
            ...props.data.content,
            signerName: item.name,
            signerNpwp: item.npwp,
            actAs: item.actAs + "",
            signerId: item.identity
          }
          if(!item.identity){
            data.signerNpwp = item.nik
          }
          props.setData({ loading: true, content: data })
          setTimeout(()=> {
            var bpform = JSON.parse(localStorage.getItem('bpform'))
            bpform = Object.assign(bpform, data)
            localStorage.setItem('bpform', JSON.stringify(bpform))
            props.setData({ loading: false, content: data })
            props.setShowModal(false)
          }, 500)
        }, true),
      ]
    }
  } catch(e){}

  return (
    <>
      <LoadingOverlay
        style={{
          position: 'inherit',
          background: 'red'
        }}
        active={ploading.loading}
        spinner
        text={ploading.message}
        >
      </LoadingOverlay>
      <TableWrapper
        useFilter={false}
        baseId={baseId}
        title={t.translate('modules.signer.title')}
        selectable={true}
        className={className}
        defaultData={[]}
        defaultSortBy="name"
        onFetchData={(params)=> {
          return (
            new Promise(async (resolve, reject)=> {
              try{
                params = {...params,...search}
                let getPage = await service.get(params)
                setData(getPage.data)
                getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
                resolve(getPage)
              } catch(e){
                resolve()
                errorService(e)
              }
            })
          )
        }}
        sideHeader={
          <DataForm 
            baseId={`${baseId}-search`}
            defaultData={search}
            submitIconClassName = 'mdi mdi-magnify'
            submitLabel={`${t.translate('words.cari')}`}
            additionalAction={[
              {
                show: true,
                render: ()=> (
                  <Button style={{ marginRight: 15 }} onClick={()=> {
                    setSearch({})
                    localStorage.removeItem(`${baseId}-saved-search`)
                    setTimeout(()=> {
                      document.getElementsByClassName('mdi-reload')[0].click()
                    }, 1000)
                  }}>{t.translate('words.hapus')}</Button>
                )
              }
            ]}
            onSubmit={(values, callback)=> {
              if(values['npwpProfile.in'] && values['npwpProfile.in'].length > 0){
                var stringIn = ""
                values['npwpProfile.in'].map((d)=> {
                  stringIn += d.value.npwp + ","
                })
                values['npwpProfile.in'] = stringIn
              }
              if(values.allNpwps){
                var allNpwpString = ""
                if(organization && organization.length > 0){
                  organization.map((d)=> {
                    allNpwpString += d.value.npwp + ","
                  })
                }
                values['npwpProfile.in'] = allNpwpString
                delete values.allNpwps
              }
              
              setSearch(values)
              localStorage.setItem(`${baseId}-saved-search`, JSON.stringify(values))
              setTimeout(()=> {
                document.getElementsByClassName('mdi-reload')[0].click()
                callback("", false, false)
              }, 1000)
            }}
            definitions={[
            {
              render: (
                <div className="mpk-data-filter">
                  <div className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}>
                    <p>{t.translate(`words.pencarian`)}</p>
                  </div>
                </div>
              )
            },
            {
              inputType: inputTypes.CHECKBOX,
              label: t.translate(`words.allNpwps`),
              key: 'allNpwps',
              type: 'text',
            },
            {
              inputType: inputTypes.REACT_SELECT,
              label: t.translate(`words.npwpProfile`),
              key: 'npwpProfile.in',
              labelKey: 'label', 
              valueKey: 'label',
              data: organization,
              options: organization,
              async: true,
              defaultOptions: organization,
              isMulti: true,
              loadOptions: async (inputValues)=> {
                var filter = organization.filter((org)=> {
                  return org.label.indexOf(inputValues) != -1
                })
                return filter
              },
              show: !insideSpt
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: t.translate(`words.npwp`),
              key: 'npwp.contains',
              type: 'text',
              mask: '##.###.###.#-###.###',
              maskChar: '_',
              maskValue: 'string',
              width: '100%'
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: t.translate(`words.nik`),
              key: 'nik.contains',
              type: 'text',
              mask: '################',
              maskChar: '_',
              maskValue: 'string',
              width: '100%'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.name`),
              key: 'name.contains',
              type: 'text'
            },
            {
              inputType: inputTypes.CHECKBOX,
              label: t.translate(`words.active`),
              key: 'active.equals',
              type: 'text'
            },
          ]}
          />
        }
        columns={[
          {
            label: t.translate('words.active'),
            searchable: true,
            sortable : true,
            key: 'active',
            type: TableWrapper.dataTypes.BOOLEAN,
            render: item => {
              if(item.active){
                return <Chip style={{ background: "#4CAF50", color: 'white' }}>{t.translate("words.ya")}</Chip>
              } else {
                return <Chip style={{ background: "#F44336", color: 'white' }}>{t.translate("words.tidak")}</Chip>
              }
            }
          },
          {
            label: t.translate('words.used'),
            searchable: true,
            sortable : true,
            key: 'used',
            type: TableWrapper.dataTypes.BOOLEAN,
            render: item => {
              if(item.used){
                return <Chip style={{ background: "#4CAF50", color: 'white' }}>{t.translate("words.ya")}</Chip>
              } else {
                return <Chip style={{ background: "#F44336", color: 'white' }}>{t.translate("words.tidak")}</Chip>
              }
            }
          },
          {
            label: t.translate('words.npwpProfile'),
            searchable: true,
            sortable : true,
            key: 'npwpProfile',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.npwpProfile)
          },
          {
            label: t.translate('words.npwp'),
            searchable: true,
            sortable : true,
            key: 'npwp',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.npwp)
          },
          {
            label: t.translate('words.nik'),
            searchable: true,
            sortable : true,
            key: 'nik',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.nik)
          },
          {
            label: t.translate('words.name'),
            searchable: true,
            sortable : true,
            key: 'name',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.name)
          },
          {
            label: t.translate('words.actAs'),
            searchable: true,
            sortable : true,
            key: 'actAs',
            type: TableWrapper.dataTypes.BOOLEAN,
            render: item => {
              if(item.actAs){
                return <Chip style={{ background: "#4CAF50", color: 'white' }}>{(t.translate('words.wajibPajak')).toUpperCase()}</Chip>
              } else {
                return <Chip style={{ background: "#F44336", color: 'white' }}>{(t.translate('words.kuasa')).toUpperCase()}</Chip>
              }
            }
          },
          {
            label: t.translate('words.identity'),
            searchable: true,
            sortable : true,
            key: 'identity',
            type: TableWrapper.dataTypes.BOOLEAN,
            render: item => {
              if(item.identity){
                return <Chip style={{ background: "#grey", color: 'white' }}>NPWP</Chip>
              } else {
                return <Chip style={{ background: "#grey", color: 'white' }}>NIK</Chip>
              }
            }
          },
          {
            label: t.translate('words.status'),
            searchable: true,
            sortable : true,
            key: 'status',
            type: TableWrapper.dataTypes.STRING,
            render: item => {
              if(item.status == 'COMPLETED'){
                return <Chip style={{ background: "#4CAF50", color: 'white' }}>{item.status.toUpperCase()}</Chip>
              } else if(item.status == 'FAILED'){
                return <Chip style={{ background: "#F44336", color: 'white' }}>{item.status.toUpperCase()}</Chip>
              } {
                return <Chip style={{ background: "#grey", color: 'white' }}>{item.status.toUpperCase()}</Chip>
              }
            }
          },
          {
            label: t.translate('words.message'),
            searchable: true,
            sortable : true,
            key: 'message',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.message)
          },
        ]}
        actions={[
          new TableWrapper.action('Add', 'mdi mdi-plus', () => history.push(`${basePath}/new`), true),
          new TableWrapper.action(`${t.translate('words.Delete')}`, 'mdi mdi-delete', (item) => { hapus(item)}, true),
        ]}
        itemActions={itemActions}
        showCommandbar={showCommandbars}
        showFilterPeriod={false}
      />
    </>
  )
}

export default inject('temporaryStore', 'modalStore', 'authStore')(observer(Signer))
