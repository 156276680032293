import React, { useEffect, useState } from 'react'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './EmailConfig.service'
import { autorun, toJS } from 'mobx';
import UtilsService from './../../services/utilsService'
import ErrorService from './../../services/errorService'
import Bluebird from 'bluebird'
import RSelect from 'react-select'
import AppMode from '../../AppMode'
import ScheduleEmailService from '../ScheduleEmail/ScheduleEmail.service'
import iziToast from 'izitoast'
import DaftarSpt from './../DaftarSpt/DaftarSpt.service'

const DaftarSptForm = ({ authStore, envStore, match, history, setEmailConfigData }) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({
    loading: true, content: {
      useProxy: false
    }
  })
  const [useProxy, setUseProxy] = useState(false)

  const baseName = `emailConfig`
  const basePath = `emailConfig`

  useEffect(() => {
    async function initData() {
      try {
        let res = null
        if(AppMode.onPremise){
          res = await service.getByCompany(match.params.companyId)
        }else{
          res = await service.getByCompanyId(match.params.companyId)
          // console.log(res, "TELL ME WHY")
        }
        if (!res.data.port) res.data.port = 0
        if (!res.data.proxyPort) res.data.proxyPort = 0
        res.data.port = res.data.port + ""
        res.data.proxyPort = res.data.proxyPort + ""
        setUseProxy(res.data.useProxy)
        setEmailConfigData(res.data)
        setData({ loading: false, content: res.data })
      } catch (e) {
        setData({ loading: false, content: {} })
        ErrorService(e)
      }
    }
    initData()
  }, [])

  var backTo = `/product/index/${basePath}`
  if(AppMode.onPremise){
    backTo = `/onpremise/${basePath}`
  }

  return (
    <FormWrapper
      style={{
        maxWidth: '100%'
      }}
      loading={data.loading}
      actions={[
      ]}
      showCommandbar={false}
      baseId={`mod-form-${baseName}`}
      defaultData={data.content}
      onChange={(formData, key, value) => {
        if (key == "useProxy") {
          setUseProxy(value)
        }
        setEmailConfigData(formData)
      }}
      definitions={[
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.host`),
          key: 'host',
          width: '50%',
          type: 'text',
          validation: 'required'
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.username`),
          key: 'username',
          width: '50%',
          type: 'text',
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.port`),
          key: 'port',
          width: '50%',
          type: 'text',
          validation: 'required'
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.password`),
          key: 'password',
          width: '50%',
          type: 'password',
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.senderEmail`),
          key: 'fromEmail',
          width: '50%',
          type: 'email',
          validation: 'email'
        },
        //Tambahan Uci
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.protocol`),
          key: 'protocol',
          width: '50%',
          type: 'text',
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.senderName`),
          key: 'from',
          width: '100%',
          type: 'text',
          validation: 'required'
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: t.translate(`words.ssl`),
          key: 'ssl',
          width: '30%',
          type: 'text',
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: t.translate(`words.auth`),
          key: 'auth',
          width: '30%',
          type: 'text',
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: t.translate(`words.tls`),
          key: 'tls',
          width: '30%',
          type: 'text',
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: t.translate(`words.sendMailPassword`),
          key: 'sendMailPassword',
          width: '50%',
          type: 'text',
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: t.translate(`words.getEmailFromLt`),
          key: 'getEmailFromLt',
          width: '50%',
          type: 'text',
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: t.translate(`words.useProxy`),
          key: 'useProxy',
          width: '50%',
          type: 'text',
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.proxyHost`),
          key: 'proxyHost',
          width: '50%',
          type: 'text',
          show: useProxy
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.proxyUsername`),
          key: 'proxyUsername',
          width: '50%',
          type: 'text',
          show: useProxy
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.proxyPort`),
          key: 'proxyPort',
          width: '50%',
          type: 'text',
          show: useProxy
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.proxyPassword`),
          key: 'proxyPassword',
          width: '50%',
          type: 'password',
          show: useProxy
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.proxyType`),
          key: 'proxyType',
          width: '50%',
          type: 'text',
          show: useProxy
        },
      ]}
      // onSubmit={async (data, callback) => {
      //   try {
      //     var formData = Object.assign({}, data)
      //     var authStoreJson = toJS(authStore)
      //     formData.port = parseInt(formData.port)
      //     formData.proxyPort = parseInt(formData.proxyPort)
      //     formData.settingEmailId = formData.id
      //     formData.login = authStoreJson.user.username
      //     formData.userId = authStoreJson.user.id
      //     var scheduleEmail = await ScheduleEmailService.getByCompany(match.params.companyId, authStoreJson.user.username)
      //     scheduleEmail.data.scheduleEmailId = scheduleEmail.data.id
      //     delete scheduleEmail.data.id
      //     formData = Object.assign({ ...scheduleEmail.data, ...formData })

      //     var organization = await DaftarSpt.getOrganization({
      //       page: 0,
      //       size: 10000
      //     })
      //     formData.organizations = organization.data
      //     formData.organizations.map((d)=> {
      //       d.id = match.params.companyId
      //     })

      //     // let organizations = UtilsService.getOrganizations(toJS(authStore))
      //     // organizations.map((d) => {
      //     //   delete d.permission
      //     // })
      //     // formData.organizations = organizations

      //     var res = await service.updateEmail(formData)
      //     callback(t.translate('sentences.tersimpan'), false, false, false)
      //   } catch (e) {
      //     ErrorService(e)
      //     callback("", false, false, false)
      //     return
      //   }
      // }}
    />
  )
}

export default inject('authStore', 'envStore')(observer(DaftarSptForm))
