import React, { useEffect, useState } from 'react'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './Sertel.service'
import { autorun, toJS } from 'mobx'
import UtilsService from '../../services/utilsService'

const SertelForm = ({envStore, match}) => {
    const { inputTypes, definition } = FormWrapper
    const basePath = 'sertel'

    return (
        <FormWrapper
            baseId = "mod-organization-form"
            actions={[
                {
                  label: 'Reload',
                  iconClassName: 'mdi mdi-reload'
                }
            ]}
            backTo={`/product/index/${basePath}`}
            title={t.translate('modules.sertel.title')}
            definitions={[
                {
                    inputType : inputTypes.DIVIDER,
                    label : t.translate('column.general')
                },
                {
                    inputType : inputTypes.INPUT,
                    label : t.translate('words.name'),
                    key : 'name',
                    type : 'text',
                    required: true
                },
                {
                    inputType : inputTypes.INPUT,
                    label : t.translate('words.npwp'),
                    key : 'npwp',
                    type : 'text',
                    required : true
                },
                {
                    inputType : inputTypes.INPUT,
                    label : t.translate('words.email'),
                    key : 'email',
                    type : 'text',
                    required : true,
                    width : '50%'
                },
                {
                    inputType : inputTypes.INPUT,
                    label : t.translate('words.phone'),
                    key : 'phone',
                    type : 'text',
                    required : true,
                    width : '50%'
                },
                {
                    inputType : inputTypes.INPUT,
                    label : t.translate('words.address'),
                    key : 'address',
                    type : 'text',
                    required : true, 
                },
                {
                    inputType : inputTypes.INPUT,
                    label : t.translate('words.city'),
                    key : 'city',
                    type : 'text',
                    required : true,
                    width : '50%'
                },
                {
                    inputType : inputTypes.INPUT,
                    label : t.translate('words.postalCode'),
                    key : 'postalCode',
                    type : 'number',
                    required : true,
                    width : '50%'
                },
                {
                    inputType : inputTypes.DIVIDER,
                    label : t.translate('words.infoPic')
                },
                {
                    inputType : inputTypes.INPUT,
                    label : t.translate('words.name'),
                    key : 'name',
                    type : 'text',
                    required: true
                },
                {
                    inputType : inputTypes.INPUT,
                    label : t.translate('words.email'),
                    key : '',
                    type : 'text',
                    required : true
                },
                {
                    inputType : inputTypes.INPUT,
                    label : t.translate('words.position'),
                    key : '',
                    type : 'text',
                    required : true,
                    width : '50%'
                },
                {
                    inputType : inputTypes.INPUT,
                    label : t.translate('words.phone'),
                    key : '',
                    type : 'text',
                    required : true,
                    width : '50%'
                }
            ]}
            onSubmit={(data, callback) => {
                console.log(data);
                setTimeout(callback, 2000)
            }}
        />
    )
}

export default inject('envStore')(observer(SertelForm))