import React, { useEffect, useState } from 'react'
import { inputTypes, formatTypes } from '../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from '../RincianObjekPajak/RincianObjekPajak.service'
import { autorun, toJs } from 'mobx'
import UtilService from '../../services/utilsService'
import _ from 'lodash'
import {
    TableContainer,
    Table,
    TableHeader,
    TableBody,
    TableRow,
    TableCell,
    TextField,
    Tab,
} from 'react-md'

const RincianObjekPajakPph22Form = ({ authStore, envStore, match, history, ...props }) => {
    const { inputTypes, definition } = FormWrapper
    const [data, setData] = useState({ loading: true, content: {} })
    const [tableBody, setTableBody] = useState([])

    const baseName = `pph22`
    const basePath = `pph22`

    async function initData() {
        var disabled = true
        try {
            var pasals = props.data.filter((d) => {
                return d.type === "PPH22"
            })
            var tableBody = []
            var formData = {}
            tableBody.push([
                {
                    render: (
                        <TableCell><strong>{t.translate(`words.no`)}</strong></TableCell>
                    )
                },
                {
                    render: (
                        <TableCell style={{ width: 500 }}><strong>{t.translate(`words.uraian`)}</strong></TableCell>
                    )
                },
                {
                    render: (
                        <TableCell><strong>{t.translate(`words.kodeObjekPajak`)}</strong></TableCell>
                    )
                },
                {
                    render: (
                        <TableCell><strong>{t.translate(`words.jumlahDpp`)}</strong></TableCell>
                    )
                },
                {
                    render: (
                        <TableCell><strong>{t.translate(`words.jumlahPph`)}</strong></TableCell>
                    )
                },
            ])
            pasals.map((d, indexD) => {
                let isnum = /^\d+$/.test(d.no);
                if (d.no.length == 1 || !isnum){
                    if(!isnum){
                        if(d.no.length == "2"){
                            d.no = "0" + d.no
                        }
                    } else {
                        d.no = "0" + d.no
                    }
                }
                formData[`dpp${d.no}`] = d.dpp
                formData[`pph${d.no}`] = d.pph
                if(d.code == "00-000-00") d.code = " "
                if(d.code == "22-100-07") d.code = " "
                if(d.code == "22-100-15") d.code = " "
            })
            var pasal = _.sortBy(pasals, "no")
            try {
                formData['dppTotal'] = props.total.pph22.bruto
                formData['pphTotal'] = props.total.pph22.pph
            } catch (e) { }
            setData({ loading: false, content: formData })
            pasal.unshift({
                id: "2b48c818-809f-44bd-8c49-41f5d5f38faf",
                type: "PPH22",
                no: "1",
                description: "Import Barang ( yang disetorkan oleh DJBC )",
                code: " ",
                dpp: 0,
                pph: 0,
                show: true,
            })
            pasal.map((q, index) => {
                var qcode = q.code
                var no = q.no
                // if(index == 0 || index == 7 || index == 11 || index == 12 || index == 15) qcode = ""
                if (index == 1 || index == 2 || index == 3 || index == 4 || index == 5 || index == 13 || index == 14 || index == 16 || index == 17) no = ""
                if (index == 6) no = "2"
                if (index == 7) no = "3"
                if (index == 8) no = "4"
                if (index == 9) no = "5"
                if (index == 10) no = "6"
                if (index == 11) no = "7"
                if (index == 12) no = "8"
                if (index == 15) no = "9"
                if (index == 18) no = "10"
                if (index == 19) no = "11"
                if (index == 20) no = "12"
                if (index == 21) no = "13"
                if (index == 22) no = "14"
                if (index == 23) no = "15"
                if (index == 24) no = "16"
                if (index == 25) no = "17"
                var row = [
                    {
                        render: (
                            <TableCell>{no}</TableCell>
                        )
                    },
                    {
                        render: (
                            <TableCell style={{ whiteSpace: 'pre-wrap' }}>{q.description}</TableCell>
                        )
                    },
                    {
                        render: (
                            <TableCell>{qcode}</TableCell>
                        )
                    },
                ]
                if (no == "8" || no == "9" || no == "1") {
                    row.push(
                        {
                            render: (
                                <TableCell></TableCell>
                            )
                        },
                        {
                            render: (
                                <TableCell></TableCell>
                            )
                        }
                    )
                } else {
                    row.push(
                        {
                            inputType: inputTypes.INPUT_MASK_NUMBER,
                            thousandSeparator: thousandSeparator,
                            decimalSeparator: decimalSeparator,
                            isNumericString: true,
                            label: '',
                            key: `dpp${q.no}`,
                            type: 'text',
                            disabled: disabled,
                            className: 'inputRight'
                        },
                        {
                            inputType: inputTypes.INPUT_MASK_NUMBER,
                            thousandSeparator: thousandSeparator,
                            decimalSeparator: decimalSeparator,
                            isNumericString: true,
                            label: '',
                            key: `pph${q.no}`,
                            type: 'text',
                            className: 'inputRight',
                            disabled: disabled
                        }
                    )
                }
                tableBody.push(row)
            })
            // total
            tableBody.push([
                {
                    render: (
                        <TableCell style={{ whiteSpace: 'pre-wrap', fontWeight: 'bold' }}></TableCell>
                    )
                },
                {
                    render: (
                        <TableCell style={{ whiteSpace: 'pre-wrap', fontWeight: 'bold' }}>{t.translate('words.total')}</TableCell>
                    )
                },
                {
                    render: (
                        <TableCell></TableCell>
                    )
                },
                {
                    inputType: inputTypes.INPUT_MASK_NUMBER,
                    thousandSeparator: thousandSeparator,
                    decimalSeparator: decimalSeparator,
                    isNumericString: true,
                    label: '',
                    key: `dppTotal`,
                    type: 'text',
                    disabled: disabled,
                    className: 'totalBold inputRight'
                },
                {
                    inputType: inputTypes.INPUT_MASK_NUMBER,
                    thousandSeparator: thousandSeparator,
                    decimalSeparator: decimalSeparator,
                    isNumericString: true,
                    label: '',
                    key: `pphTotal`,
                    type: 'text',
                    disabled: disabled,
                    className: 'totalBold inputRight'
                },
            ])
            // total
            setTableBody(tableBody)
        } catch (e) {
            // console.log(e)
        }
    }

    useEffect(async () => {
        await initData()
    }, [])

    var thousandSeparator = "."
    var decimalSeparator = ","
    try {
        if (localStorage.getItem('lang') == 'en') {
            thousandSeparator = ","
            decimalSeparator = "."
        }
    } catch (e) { }

    return (
        <FormWrapper
            tableForm={true}
            style={{
                maxWidth: '100%',
            }}
            className="flex-none"
            loading={data.loading}
            actions={[]}
            onChange={(a, b, c) => {
                // // console.log(a,b,c)
            }}
            baseId={`mod-form-${baseName}`}
            hintMessage={t.translate(`modules.${baseName}.formTitle`)}
            defaultData={data.content}
            definitions={{
                header: [
                    // {
                    //     render: (
                    //         <TableCell>{t.translate(`words.no`)}</TableCell>
                    //     )
                    // },
                    // {
                    //     render: (
                    //         <TableCell style={{ width: 500 }}>{t.translate(`words.uraian`)}</TableCell>
                    //     )
                    // },
                    // {
                    //     render: (
                    //         <TableCell>{t.translate(`words.kodeObjekPajak`)}</TableCell>
                    //     )
                    // },
                    // {
                    //     render: (
                    //         <TableCell>{t.translate(`words.jumlahDpp`)}</TableCell>
                    //     )
                    // },
                    // {
                    //     render: (
                    //         <TableCell>{t.translate(`words.jumlahPph`)}</TableCell>
                    //     )
                    // }
                ],
                body: tableBody
            }}
            showCommandbar={false}
        />
    )
}

export default inject('authStore', 'envStore')(observer(RincianObjekPajakPph22Form))