import React, { useEffect } from 'react'
import { Router, Switch, Route } from "react-router-dom"
import { PageWrapper } from '../../libs/react-mpk/wrapper'
import { sso } from '../../libs/react-mpk/hocs'
import t from 'counterpart'
import { autorun, toJS } from 'mobx';
import { DashboardSample,MasterNpwpView, MasterNpwpForm, DashboardSpt, LogExportForm, LogExportView, TableSample, FormSample, EmailConfigFormView, LogEmailView, LogEmailHistoryView, ImportEssp, ImportLogCsv, ImportBp, ImportDossDopp, ImportSetorSendiri, ImportSspPbk, EbillingWidget, DataEsspView, DataEsspForm, Bp26View, BpHistory, PbkHistory, Bp26History, LawanTransaksiForm, Doss2HistoryView, LawanTransaksiView, BpView, Doss2View, DaftarSptView, DaftarHistorySpt, DaftarSptForm, SspView, SspForm, SspHistory, PbkView, PbkForm, SignerForm, SignerView, DaftarBpeView, ImportData, EksporData, OrganizationForm, UserForm, ImportLog, User, Organization, OrganizationForm_St, OrganizationTable_St, SertelForm_st, SertelTable_st, SignerTable_St, SignerForm_St, LogEmailInfoTable, Bp26HistoryDelete, BpHistoryDelete, Doss2HistoryDelete } from '../../modules'
// import { DashboardSample, TableSample, FormSample, EmailConfigFormView, LogEmailView, LogEmailHistoryView, ImportEssp, ImportLogCsv, ImportBp, ImportDossDopp, ImportSetorSendiri, ImportSspPbk, EbillingWidget, DataEsspView, DataEsspForm, Bp26View, BpHistory, PbkHistory, Bp26History, LawanTransaksiForm, Doss2HistoryView, LawanTransaksiView, BpView, Doss2View, DaftarSptView, DaftarHistorySpt, DaftarSptForm, SspView, SspForm, SspHistory, PbkView, PbkForm, SignerForm, SignerView, DaftarBpeView, ImportData, EksporData, OrganizationForm, UserForm, ImportLog, User, Organization, OrganizationForm_St, OrganizationTable_St, SertelForm_st, SertelTable_st, SignerTable_St, SignerForm_St} from '../../modules'
import Companies from '../../modules/Companies'
import http from '../../libs/react-mpk/services/http.service'
import errorService from '../../services/errorService'
import Company from '../../modules/Company/Company.table'

const Index = ({ authStore, envStore, history }) => {

  useEffect(async () => {
    try {
      if (localStorage.getItem("loading")) {
        http.setCompanyId(localStorage.getItem("loading"))
      } else {
        var query = {
          page: 0,
          size: 20
        }

        // let res = await http.stream(`${envStore.env.sso.host}/api/user_console/company_support`, {}, {
        //   useDefaultHost: false,
        //   useDefaultBaseUrl: false,
        //   query
        // })
        // var getDumpId = null
        // getDumpId = res.data[0].id
        // http.setCompanyId(getDumpId)
        // localStorage.setItem("loading", getDumpId) 
      }
    } catch (e) {
      errorService(e)
    }
  })

  // Tambahan Uci
  try{
    var title = document.title.split(":")
    document.title = title[1] ? ("e-Bunifikasi :"+title[1]+ "") : "e-Bunifikasi"
  }catch(e){
    document.title = "e-Bunifikasi"
  }

  useEffect(()=>{
    document.title = "e-Bunifikasi"
  }, [envStore])
  // Tambahan Uci


  return (
    <PageWrapper
      sidebarCollapsible={false}
      sidebarDefaultCollapse={false}
      inverseTheme={true}
      style={{ background: '#D14C21' }}
      sidebarMenu={[
        {
          "label": `${t.translate('menu.supportMenu')}`
        },
        {
          "label": `${t.translate('menu.title')}`,
          "children": [
            {
              "label": `${t.translate('modules.dashboard.title')}`,
              "index": "dashboard",
              "resourceUri": "",
              "iconClassName": "mdi mdi-layout",
              "path": `/product/index/dashboard-spt`,
              "childPath": []
            },
            // {
            //   "label":`${t.translate('modules.daftarSpt.title')}`,
            //   "index":"daftar-spt, daftar, spt, surat, pemberitahuan, tahunan, pajak",
            //   "resourceUri":"",
            //   "iconClassName":"mdi mdi-layout",
            //   "path":`/product/index/companies`,
            //   "childPath":[]
            // },
            {
              "label": `${t.translate('modules.daftarSpt.title')}`,
              "index": "daftar-spt, daftar, spt, surat, pemberitahuan, tahunan, pajak",
              "resourceUri": "",
              "iconClassName": "mdi mdi-layout",
              "path": `/product/index/daftar-spt`,
              "childPath": []
            },
            {
              "label": `${t.translate('modules.bp.menuTitle')}`,
              "index": "daftar-bp, bp, dn, dalam, negeri, dalam-negeri, bukti, potong",
              "resourceUri": "",
              "iconClassName": "mdi mdi-layout",
              "path": `/product/index/bp`,
              "childPath": []
            },
            {
              "label": `${t.translate('modules.bp26.menuTitle')}`,
              "index": "daftar-bp-26, bp, ln, luar, negeri, luar-negeri, bukti, potong",
              "resourceUri": "",
              "iconClassName": "mdi mdi-layout",
              "path": `/product/index/bp26`,
              "childPath": []
            },
            {
              "label": `${t.translate('modules.doss2.menuTitle')}`,
              "index": "daftar-pph-setor-sendiri, daftar, pph, setor, sendiri, ss",
              "resourceUri": "",
              "iconClassName": "mdi mdi-layout",
              "path": `/product/index/doss2`,
              "childPath": []
            },
            {
              "label":`${t.translate('menu.ssp')}`,
              "index":"ssp, daftar, setoran, surat",
              "resourceUri":"",
              "iconClassName":"mdi mdi-layout",
              "path":`/product/index/ssp`,
              "childPath":[]
            },
            {
              "label":`${t.translate('menu.pbk')}`,
              "index":"pbk, daftar, pajak, pemindahbukuan, buku, pindah",
              "resourceUri":"",
              "iconClassName":"mdi mdi-layout",
              "path":`/product/index/pbk`,
              "childPath":[]
            },
            {
              "label": `${t.translate('menu.bpe')}`,
              "index": "bpe",
              "resourceUri": "",
              "iconClassName": "mdi mdi-layout",
              "path": `/product/index/daftar-bpe`,
              "childPath": []
            },
          ]
        },
        {
          "label": `${t.translate('menu.logData')}`,
          "children": [
            {
              "label": `${t.translate('modules.log-export.title')}`,
              "index": "export, log",
              "resourceUri": "",
              "iconClassName": "mdi mdi-layout",
              "path": `/product/index/log-export`,
              "childPath": []
            },
            {
              "label": `${t.translate('modules.logDataEmail.title')}`,
              "index": "export, log",
              "resourceUri": "",
              "iconClassName": "mdi mdi-layout",
              "path": `/product/index/log-mail`,
              "childPath": []
            },
            {
              "label": `${t.translate('modules.log-excel.title')}`,
              "index": "export, log",
              "resourceUri": "",
              "iconClassName": "mdi mdi-layout",
              "path": `/product/index/log-excel`,
              "childPath": []
            },
            {
               "label": `${t.translate('modules.log-csv.title')}`,
               "index": "export, log",
               "resourceUri": "",
               "iconClassName": "mdi mdi-layout",
               "path": `/product/index/log-csv`,
               "childPath": []
             }
          ]
        },
        // {
        //   "label" : `${t.translate('menu.setting')}`,
        //   "children" : [
        //     {
        //       "label":`${t.translate('modules.masterNpwp.title')}`,
        //       "index":"master-npwp, pengaturan, npwp, pemadanan, master",
        //       "resourceUri":"",
        //       "iconClassName":"mdi mdi-layout",
        //       "path":`/product/index/master-npwp`,
        //       "childPath":[]
        //     },
        //   ]
        // }
      ]}
    >
      <Router history={history}>
        <Switch>
          {/* <Route
            path='/product/index/companies'
            render={props => (
              <Companies {...props}/>
            )}
          /> */}
          {/* <Route 
            path='/product/index/dashboard-spt'
            render={props => (
              <DashboardSpt {...props}/>
              // <></>
            )}
          /> */}
          {/* <Route
            path='/product/index/master-npwp/:id'
            render={props => (
              <MasterNpwpForm {...props} />
            )}
          />
          <Route
            path='/product/index/master-npwp'
            render={props => (
              <MasterNpwpView {...props} />
            )}
          /> */}
          <Route
            path='/product/index/dashboard-spt'
            render={props => (
              <DashboardSpt {...props} />
            )}
          />
          <Route
            path='/product/index/daftar-spt/:id/history'
            render={props => (
              <DaftarHistorySpt {...props} />
            )}
          />
          <Route
            path='/product/index/daftar-spt/:id'
            render={props => (
              <DaftarSptForm {...props} />
            )}
          />
          <Route
            path='/product/index/daftar-spt'
            render={props => (
              <DaftarSptView {...props} />
            )}
          />
          <Route
            path='/product/index/bp26/:id/delete-history'
            render={props => (
              <Bp26HistoryDelete {...props} />
            )}
          />
          <Route
            path='/product/index/bp26/:id/history'
            render={props => (
              <Bp26History {...props} />
            )}
          />
          <Route
            path='/product/index/bp26'
            render={props => (
              <Bp26View {...props} />
            )}
          />
          <Route
            path='/product/index/bp/:id/delete-history'
            render={props => (
              <BpHistoryDelete {...props} />
            )}
          />
          <Route
            path='/product/index/bp/:id/history'
            render={props => (
              <BpHistory {...props} />
            )}
          />
          <Route
            path='/product/index/bp'
            render={props => (
              <BpView {...props} />
            )}
          />
          <Route
            path='/product/index/doss2/:id/delete-history'
            render={props => (
              <Doss2HistoryDelete {...props} />
            )}
          />
          <Route
            path='/product/index/doss2/:id/history'
            render={props => (
              <Doss2HistoryView {...props} />
            )}
          />
          <Route
            path='/product/index/doss2'
            render={props => (
              <Doss2View {...props} />
            )}
          />
          <Route
            path='/product/index/daftar-bpe'
            render={props => (
              <DaftarBpeView {...props} />
            )}
          />
          <Route
            path='/product/index/log-export/:id'
            render={props => (
              <LogExportForm {...props} />
            )}
          />
          <Route
            path='/product/index/log-export'
            render={props => (
              <LogExportView {...props} />
            )}
          />
          <Route
            path='/product/index/log-mail/:id'
            render={props => (
              <LogEmailInfoTable {...props} />
            )}
          />
          <Route
            path='/product/index/log-mail'
            render={props => (
              <LogEmailView {...props} />
            )}
          />
          <Route
            path='/product/index/organization/:id'
            render={props => (
              <OrganizationForm_St {...props}/>
            )}
          />
          <Route
            path='/product/index/organization'
            render={props => (
              <OrganizationTable_St {...props}/>
            )}
          />
          <Route
            path='/product/index/sertel/:id'
            render={props => (
              <SertelForm_st {...props}/>
            )}
          />
          <Route
            path='/product/index/sertel'
            render={props => (
              <SertelTable_st {...props}/>
            )}
          />
          <Route
            path='/product/index/signer/:id'
            render={props => (
              <SignerForm_St {...props}/>
            )}
          />
          <Route
            path='/product/index/signer'
            render={props => (
              <SignerTable_St {...props}/>
            )}
          />
          <Route 
            path='/product/index/ssp/:id/history'
            render={props => (
              <SspHistory {...props}/>
            )}
          />
          <Route 
            path='/product/index/ssp/:id'
            render={props => (
              <SspForm {...props}/>
            )}
          />
          <Route
            path='/product/index/ssp'
            render={props => (
              <SspView {...props}/>
            )}
          />
          <Route 
            path='/product/index/pbk/:id/history'
            render={props => (
              <PbkHistory {...props}/>
            )}
          />
          <Route 
            path='/product/index/pbk/:id'
            render={props => (
              <PbkForm {...props}/>
            )}
          />
          <Route
            path='/product/index/pbk'
            render={props => (
              <PbkView {...props}/>
            )}
          />
          <Route
            path='/product/index/log-excel/:id/import-detail'
            render={props => (
              <ImportLog {...props}/>
            )}
          />
          <Route
            path='/product/index/log-excel'
            render={props => (
              <ImportData {...props}/>
            )}
          />
          <Route
            path='/product/index/log-csv/:id/import-detail-csv'
            render={props => (
              <ImportLogCsv {...props}/>
            )}
          />
          <Route
            path='/product/index/log-csv'
            render={props => (
              <ImportBp {...props}/>
            )}
          />
        </Switch>
      </Router>
    </PageWrapper>
  )
}

export default sso({
  basePath: '/product/index'
})(Index)