import CrudService from '../../services/crudService';
import { http } from '../../libs/react-mpk/services';
import AppMode from '../../AppMode'
import axios from 'axios'

let service = null
let serviceUrl = '/bp/manage/dn'
let serviceObject = '/bp/object'
if(AppMode.onPremise){
  service = new CrudService('/api/eunifikasi/bp/manage/dn');
  serviceUrl = '/api/eunifikasi/bp/manage/dn'
  serviceObject = '/api/eunifikasi/bp/object'
} else {
  service = new CrudService('/bp/manage/dn');
}

service.exportCurrent = async(data, params)=> {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/export`,
    data,
    config: {
      params: params
    }
  })
}

service.exportAll = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/export/search`,
    query
  })
}

service.getTaxObjectCode = async(type)=> {
  return http.request({
    method: http.methods.GET,
    url: `${serviceObject}?size=200&&type.equals=${type}`,
  })
}
service.postResend = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/resend`,
    data
  })
}

service.getReport = async(id)=> {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/report/${id}`,
  })
}

service.postReport = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/report`,
    data
  })
}

service.getRequestReport = async(id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/request/report/${id}`
  })
}

service.getReupload = async(id)=> {
  return http.request({
    method : http.methods.GET,
    url : `${serviceUrl}/reupload/${id}`,
  })
}

service.sptSummary = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/spt/summary`,
    query
  })
}

service.summary = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/summary`,
    query
  })
}

service.history = async(id) => {
  return http.request({
    method : http.methods.GET,
    url : `${serviceUrl}/history/${id}`
  })
}

service.historyDelete = async(id) => {
  return http.request({
    method : http.methods.GET,
    url : `${serviceUrl}/delete-logs/${id}`
  })
}

service.sendMail = async(data, email) => {
  return http.request({
    method : http.methods.POST,
    url : `${serviceUrl}/send`,
    data,
    config: {
      params: email
    }
  })
}

service.sendMailBulk = async(data, email)=> {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/send/search`,
    data,
    config: {
      params: {
        email: email,
        ...data
      }
    }
  })
}

service.sendMailBulkOp = async(data, email)=> {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/send/search`,
    query: data,
    // config: {
    //   params: data
    // }
  })
}

service.getOrganization = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `/core/host/organization`,
    query
  })
}

service.reportBulk = async(data, all)=> {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/report/search`,
    query: data
  })
}

service.reportBulkOp = async(data, all)=> {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/report/search`,
    query: data
  })
}

service.getAppInfo = async (query)=> {
  return http.request({
    method: http.methods.GET,
    url: `/api/application-info`,
    query
  })
}

//Tambahan Uci
service.forceFinish = async(id,query)=> {
  return http.request({
    method: http.methods.PUT,
    url: `/bp/manage/fix/case/spt-finish-bp-status-force-finish?type=dn&ids=${id}`,
  })
}

service.forceCompleted = async(id,query)=> {
  return http.request({
    method: http.methods.PUT,
    url: `/bp/manage/fix/case/bp-status-created-force-completed?type=dn&ids=${id}`,
  })
}

service.forceCompletedFromUpdate = async(id,query)=> {
  return http.request({
    method: http.methods.PUT,
    url: `/bp/manage/fix/case/bp-status-updating-force-completed?type=dn&ids=${id}`,
  })
}
//Tambahan Uci
// Tambahan Uci
service.getLogFiles = async (query) => {
  return http.request({
    method : http.methods.GET,
    url : `${serviceUrl}/spt/log/files`,
    query
  })
}

service.syncBp = async(query) => {
  return http.request({
    method: http.methods.GET,
    url: `/bp/sync/bp`,
    query
  })
}

service.setCompleted = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `/bp/manage/dn/set-completed`,
    data
  })
}

service.pemadananNpwp = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `/bp/manage/dn/pemadanan`,
    data
  })
}
// Tambahan Uci

export default service;
