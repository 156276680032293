import CrudService from '../../services/crudService';
import { http } from '../../libs/react-mpk/services';
import AppMode from '../../AppMode'

// /api/eunifikasi/log/export

let service = null
let serviceUrl = '/export/manage/log/export'
if(AppMode.onPremise){
  service = new CrudService('/api/eunifikasi/manage/log/export');
  serviceUrl = '/api/eunifikasi/manage/log/export'
} else {
  service = new CrudService('/export/manage/log/export');
}

service.getBySpt = async(query, sptId)=> {
    return http.request({
      method: http.methods.GET,
      url: `${serviceUrl}/spt/${sptId}`,
      query
    })
}

service.history = async(id, query)=> {
    return http.request({
      method: http.methods.GET,
      url: `${serviceUrl}/history/${id}`,
      query
    })
}

export default service;