import React from 'react'
import { inject, observer } from 'mobx-react'
import { format } from '../../../libs/react-mpk/services/number.service'
import './Card.scss'
import AnimatedNumber from "animated-number-react";

const ReportCard = ({
  className = '',
  href      = '',
  thumbnail = false,
  color     = '#354052',
  primaryText = '',
  subtitle = '',
  title = '',
  icon = 'mdi mdi-file-pdf',
  theme = 'primary',
  style = {},
  value = 0,
  data = {},
  useDetail = true,
  ...props
}) => {
  var formatValue = (value) => format(value.toFixed(0));
  
  return (
    <div class="c-dashboardInfo col-lg-3 col-md-6">
      <div class="wrap">
        <AnimatedNumber
          value={value}
          formatValue={formatValue}
          className='hind-font caption-12 c-dashboardInfo__count'
        />
        <h4 class="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">{(title+"").toUpperCase()}
        </h4>
        {useDetail ?
          <>
            {/* {data.length > 0 &&
              <> */}
                <hr style={{width : '100%'}}/>
                <section class="stats stats_row">
                  {Object.keys(data).map((d)=>{
                    return(
                      <div class="stats_item half_whole">
                      <div class="txt_faded">
                        <label class="txt_label space_n_b">
                          {(d+"").toUpperCase()}
                        </label>
                        <div class="txt_serif stats_item_number txt_success">
                          {data[d]}
                        </div>
                      </div>
                    </div>
                    )
                  })
                  }
                </section>
              {/* </>
            } */}
          </>
          :
          <>
            {/* <section class="stats stats_row">
              <div class="stats_item half_whole">
                <div class="txt_serif stats_item_number txt_success">
                  <i className={icon} />
                </div>
              </div>
            </section> */}
          </>
        }
      </div>
    </div>
  )
}

export default inject('envStore', 'navigationStore')(observer(ReportCard));