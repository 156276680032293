import React, { useEffect, useState } from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import Modal from '../../libs/react-mpk/components/Modal'
import t from 'counterpart'
import service from './DaftarSptArchive.service'
import moment from 'moment'
import { toast } from '../../libs/react-mpk/services'
import { format } from '../../libs/react-mpk/services/number.service'
import Hint from '../../libs/react-mpk/components/Hint/Hint'
import { Chip, ListItem, List, Button, Dialog, DialogHeader, DialogContent, DialogTitle, Radio } from 'react-md'
import errorService from '../../services/errorService'
import ErrorService from '../../services/errorService'
import { TableCheckbox } from 'react-md'
import { inject, observer } from 'mobx-react'
import iziToast from 'izitoast'
import LoadingOverlay from 'react-loading-overlay'
import bluebird from 'bluebird'
import errorServiceGet from '../../services/errorServiceGet'
import DataForm from '../../libs/react-mpk/components/DataForm'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import AppMode from '../../AppMode'
import fileSaver from 'file-saver'
import SptService from '../DaftarSptArchive/DaftarSptArchive.service'
import UtilsService from './../../services/utilsService'
import Bluebird from 'bluebird'
import { autorun, toJS } from 'mobx';

const DaftarSptArchive = ({
  modalStore,
  className = '',
  showCommandbar = true,
  history,
  match,
  authStore
}) => {

  const basePath = `daftar-spt-archive`
  const [ploading, setPloading] = useState({ loading: false, message: '' })
  const [data, setData] = useState([])
  let [search, setSearch] = useState({})
  const [showDialog, setShowDialog] = useState(false)
  const [dialogData, setDialogData] = useState({})
  const [organization, setOrganization] = useState([])
  const baseId = 'mod-daftar-spt-archive'
  const [dialogOpPdf, setDialogOpPdf] = useState(false);
  const [dialogOpPdfBpe, setDialogOpPdfBpe] = useState(false)
  const [dialogOpSptSummaryIndukPdf, setDialogOpSptSummaryIndukPdf] = useState(false)
  const [enableApproval, setEnableApproval] = useState(true)
  const [approvalState, setApprovalState] = useState({
    approvalDialog: false
  })
  const [exportDialog, setExportDialog] = useState(false);
  const [sptSummary, setDialogOpSptSummary] = useState(false);
  var insideSpt = false

  useEffect(async () => {
    try {
      if(match.path.indexOf("archive") != -1){
        // history.push('/temp');
        // history.goBack();
      } else {
      }
    } catch(e){
      // console.log(e)
    }
    if (localStorage.getItem(`${baseId}-saved-search`)) {
      setSearch(JSON.parse((localStorage.getItem(`${baseId}-saved-search`))))
    }
    try {
      var getAppInfo = await service.getAppInfo()
      if (getAppInfo && getAppInfo.data && getAppInfo.enableTemplateRoleEunifikasi) {
        setEnableApproval(true)
      } else {
        // setEnableApproval(false)
      }
    } catch (e) { }
    if (AppMode.onPremise) {
      await getOrganizationsOp()
    } else {
      await getOrganizations()
    }
  }, [])

  const getOrganizations = async (values)=> {
    var params = {
      page: 0,
      size: 1000
    }
    if(isNaN(values)){
      params['field'] = 'name'
      params['query'] = values
    } else {
      params['field'] = 'npwp'
      params['query'] = values
    }
    let organization = await service.getOrganization(params)
    var getAll = organization.data
    var organizationsFilter = []
    try {
      getAll.map((og)=> {
        og.label = og.npwp + " - " + og.name
        if(og.certExists) organizationsFilter.push({
          value: og,
          label: og.label
        })
      })
    } catch(e){
      organization.data.map((d)=> {
        d.label = d.npwp + " - " + d.name
        organizationsFilter.push({
          value: d,
          label: d.label
        })
      })
    }
    setOrganization(organizationsFilter)
    return organizationsFilter
  }

  const getOrganizationsOp = async (values) => {
    try {
      var params = {
        page: 0,
        size: 100,
        sort: 'createdDate,DESC'
      }
      if (isNaN(values)) {
        params['name.contains'] = values
      } else {
        params['npwp.contains'] = values
      }
      let res = await SptService.getOrganizationOp(params)
      res.data.map((d) => {
        d.label = d.npwp + " - " + d.name
        d.value = d.npwp
      })
      setOrganization(res.data)
      return res.data
    } catch (e) {
      ErrorService(e)
      return []
    }
  }

  async function submitExc(item) {
    try {
      var proses = t.translate('words.submitSPT')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d) => selectedData.push(data[d]))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries(selectedData, async (di, di2) => {
        setTimeout(async () => {
          try {
            let proccedThis = await service.submitSpt(di.id)
          } catch (e) {
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2 + 1}/${selectedData.length}` })
          if ((di2 + 1) == selectedData.length) {
            setPloading({ loading: false, message: `` })
            if (errors.length == 0) {
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er) => {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )
              })
            }
            try {
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if (checkboxRoot.checked) {
                if (checkboxRoot.getAttribute('aria-checked')) {
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            } catch (e) { }
          }
        }, 1000 * di2)
      })
    } catch (e) {
      errorService(e)
    }
  }

  async function submit(item) {
    modalStore.showConfirm({
      title: t.translate('words.submitSPT'),
      children: t.translate('words.confirmSubmitSpt'),
      onSubmit: (callback1) => {
        callback1()
        modalStore.showConfirm({
          dialogstyle: {
            width: '100px',
          },
          title: t.translate('words.submitSPT'),
          children: <div className="dialogInduk mpk-hint"><Hint>{t.translate('words.induk24')}</Hint></div>,
          onSubmit: (callback) => {
            submitExc(item)
            callback()
          }
        })
      }
    })
  }

  async function postExc(item) {
    try {
      var proses = t.translate('words.postSPT')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d) => selectedData.push(data[d]))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries(selectedData, async (di, di2) => {
        setTimeout(async () => {
          try {
            let proccedThis = await service.postingSpt(di.id)
          } catch (e) {
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2 + 1}/${selectedData.length}` })
          if ((di2 + 1) == selectedData.length) {
            setPloading({ loading: false, message: `` })
            if (errors.length == 0) {
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er) => {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )
              })
            }
            try {
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if (checkboxRoot.checked) {
                if (checkboxRoot.getAttribute('aria-checked')) {
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            } catch (e) { }
          }
        }, 1000 * di2)
      })
    } catch (e) {
      errorService(e)
    }
  }

  async function post(item) {
    modalStore.showConfirm({
      title: t.translate('words.postSPT'),
      children: t.translate('words.confirmPostSpt'),
      onSubmit: (callback) => {
        postExc(item)
        callback()
      }
    })
  }

  async function checkExc(item) {
    try {
      var proses = t.translate('words.check')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d) => selectedData.push(data[d]))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries(selectedData, async (di, di2) => {
        setTimeout(async () => {
          try {
            let proccedThis = await service.checkSpt({
              month: di.month,
              year: di.year,
              npwp: di.npwp
            })
          } catch (e) {
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2 + 1}/${selectedData.length}` })
          if ((di2 + 1) == selectedData.length) {
            setPloading({ loading: false, message: `` })
            if (errors.length == 0) {
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er) => {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )
              })
            }
            try {
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if (checkboxRoot.checked) {
                if (checkboxRoot.getAttribute('aria-checked')) {
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            } catch (e) { }
          }
        }, 1000 * di2)
      })
    } catch (e) {
      errorService(e)
    }
  }

  async function check(item) {
    modalStore.showConfirm({
      title: t.translate('words.check'),
      children: t.translate('words.confirmCheck'),
      onSubmit: (callback) => {
        checkExc(item)
        callback()
      }
    })
  }

  async function cetakIndukExc(item) {
    try {
      var proses = t.translate('words.reportInduk')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d) => selectedData.push(data[d]))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      if (selected.length > 0) {
        if (selected.length == 1) {
          let proccedThis = await service.report({
            sptNpwp: selectedData[0].npwp,
            sptYear: selectedData[0].year,
            sptMonth: selectedData[0].month,
            sptRev: selectedData[0].rev,
          })
          const blob = new Blob([proccedThis.data.data.url], { type: 'pdf' })
          fileSaver.saveAs(blob, `${proccedThis.data.data.filename}`)
          setPloading({ loading: false, message: `` })
          iziToast.success({
            title: proses,
            message: `${t.translate('words.proses')} ${proses} ${t.translate('words.berhasilDicetak')}`
          })
        } else {
          // let res = await service.downloadAll(selectedData)
          // window.open(res.data.url)
          setPloading({ loading: false, message: `` })
          iziToast.info({
            title: proses,
            message: `Fitur Bulk Belum tersedia silahkan pilih satu data`
          })
        }
      }
    } catch (e) {
      // console.log(e)
      setPloading({ loading: false, message: `` })
      errorService(e)
    }
    try {
      var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
      if (checkboxRoot.checked) {
        if (checkboxRoot.getAttribute('aria-checked')) {
          checkboxRoot.click()
          checkboxRoot.click()
        } else {
          checkboxRoot.click()
        }
      }
      document.getElementsByClassName('mdi-reload')[0].click()
    } catch (e) { }
  }

  async function cetakInduk(item) {
    modalStore.showConfirm({
      title: t.translate('words.reportInduk'),
      children: t.translate('words.confirmReportInduk'),
      onSubmit: (callback) => {
        cetakIndukExc(item)
        callback()
      }
    })
  }

  async function hapusExc(item) {
    try {
      var proses = t.translate('words.hapus')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d) => selectedData.push(data[d]))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries(selectedData, async (di, di2) => {
        setTimeout(async () => {
          try {
            let proccedThis = await service.delete(di.id)
          } catch (e) {
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2 + 1}/${selectedData.length}` })
          if ((di2 + 1) == selectedData.length) {
            setPloading({ loading: false, message: `` })
            if (errors.length == 0) {
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er) => {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )
              })
            }
            try {
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if (checkboxRoot.checked) {
                if (checkboxRoot.getAttribute('aria-checked')) {
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            } catch (e) { }
          }
        }, 1000 * di2)
      })
    } catch (e) {
      errorService(e)
    }
  }

  async function hapus(item) {
    modalStore.showConfirm({
      title: t.translate('words.delete'),
      children: t.translate('words.hapusIni'),
      onSubmit: (callback) => {
        hapusExc(item)
        callback()
      }
    })
  }

  async function pembetulanExec(item) {
    try {
      var proses = t.translate('words.pembetulan')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d) => selectedData.push(data[d]))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries(selectedData, async (di, di2) => {
        setTimeout(async () => {
          try {
            let proccedThis = await service.pembetulan(di.id)
          } catch (e) {
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2 + 1}/${selectedData.length}` })
          if ((di2 + 1) == selectedData.length) {
            setPloading({ loading: false, message: `` })
            if (errors.length == 0) {
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er) => {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )
              })
            }
            try {
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if (checkboxRoot.checked) {
                if (checkboxRoot.getAttribute('aria-checked')) {
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            } catch (e) { }
          }
        }, 1000 * di2)
      })
    } catch (e) {
      errorService(e)
    }
  }

  async function pembetulan(item) {
    modalStore.showConfirm({
      title: t.translate('words.pembetulan'),
      children: t.translate('words.pembetulanIni'),
      onSubmit: (callback) => {
        pembetulanExec(item)
        callback()
      }
    })
  }

  async function downloadXlsSearch(item) {
    if(AppMode.onPremise){
      history.push(`/onpremise/daftar-spt/export`)
    } else {
      history.push(`/product/company/${match.params.companyId}/daftar-spt/export`)
    }
  }

  async function downloadXls(item) {
    var exportType = localStorage.getItem("exportType")
    var selected = JSON.parse(TableWrapper.getSelected(baseId))
    if(selected.length > 0){
      modalStore.showConfirm({
        title: "",
        children: (
          <div>
            <h1>{t.translate('words.downloadExportBp')}</h1>
            <p>{t.translate('words.confirmExportBp')}</p>
            <p>
              <Radio onChange={(value)=> { localStorage.setItem("exportType", "0") }} value="0" name="exportChoice" label={t.translate('words.export1')}></Radio>
            </p>
            <p>
              <Radio onChange={(value)=> { localStorage.setItem("exportType", "1")  }} value="1" name="exportChoice" label={t.translate('words.export2')}></Radio>
            </p>
            <p>
              <Radio onChange={(value)=> { localStorage.setItem("exportType", "2") }} value="2" name="exportChoice" label={t.translate('words.export3')}></Radio>
            </p>
            <p>
              <Radio onChange={(value)=> { localStorage.setItem("exportType", "4") }} value="4" name="exportChoice" label={t.translate('words.export5')}></Radio>
            </p>
            <p>
              <Radio onChange={(value)=> { localStorage.setItem("exportType", "3") }} value="3" name="exportChoice" label={t.translate('words.export4')}></Radio>
            </p>
          </div>
        ),
        onSubmit: (callback) => {
          downloadXlsExec(item)
          callback()
        }
      })
    } else {
      iziToast.info({
        title: t.translate("words.Info"),
        message: t.translate("words.pilihLebihDariSatu")
      })
    }
  }

  async function downloadXlsExec() {
    var exportType = localStorage.getItem("exportType")
    if(exportType == "0" || exportType == "1" || exportType == "2" || exportType == "4"){
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      if(selected.length > 0){
        downloadXlsExec2() 
      } else {
        iziToast.info({
          title: t.translate("words.Info"),
          message: t.translate("words.pilihLebihDariSatu")
        })
      }
    } else {
      downloadXlsSearch()
    }
  }

  async function downloadXlsExec2() {
    var exportType = localStorage.getItem("exportType")
    try {
      var proses = t.translate('words.downloadExportBp')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d) => selectedData.push(data[d].id))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      if(exportType == "0"){
        if (selected.length > 0) {
          if(AppMode.onPremise){
            let res = await service.downloadAllXlsOP(selectedData)
          } else {
            if (selected.length == 1) {
              let res = await service.donwloadIdXls(selectedData[0])
              window.open(res.data.url)
            } else {
              let res = await service.downloadAllXls(selectedData)
            }
          }
        }
      } else if(exportType == "1"){
        if(AppMode.onPremise){
          let res = await service.downloadAllXlsDetailOP(selectedData)
        } else {
          let res = await service.downloadAllXlsDetail(selectedData)
        }
      } else if(exportType == "4") {
        if(AppMode.onPremise){
          let res = await service.downloadAllXlsSummarySPTDetailOP(selectedData)
        } else {
          let res = await service.downloadAllXlsSummarySPTDetail(selectedData)
        }
      } else {
        if(AppMode.onPremise){
          let res = await service.downloadAllXlsSummarySPTOP(selectedData)
        } else {
          let res = await service.downloadAllXlsSummarySPT(selectedData)
        }
      }
      setPloading({ loading: false, message: `` })
      iziToast.success({
        title: proses,
        message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDoneLogExport')}`
      })
    } catch (e) {
      // console.log(e)
      setPloading({ loading: false, message: `` })
      errorService(e)
    }
    try {
      var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
      if (checkboxRoot.checked) {
        if (checkboxRoot.getAttribute('aria-checked')) {
          checkboxRoot.click()
          checkboxRoot.click()
        } else {
          checkboxRoot.click()
        }
      }
      document.getElementsByClassName('mdi-reload')[0].click()
    } catch (e) { }
  }

  async function download(item) {
    modalStore.showConfirm({
      title: t.translate('words.downloadSPT'),
      children: t.translate('words.confirmSptDownload'),
      onSubmit: (callback) => {
        downloadExc(item)
        callback()
      }
    })
  }

  async function downloadExc() {
    try {
      var proses = t.translate('words.downloadSPT')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d) => selectedData.push(data[d].id))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      if (selected.length > 0) {
        if (selected.length == 1) {
          let res = await service.donwloadId(selectedData[0])
          // window.open(res.data.url)
          setPloading({ loading: false, message: `` })
          iziToast.success({
            title: proses,
            message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDoneLogExport')}`
          })
        } else {
          // let res = await service.downloadAll(selectedData)
          // window.open(res.data.url)
          setPloading({ loading: false, message: `` })
          iziToast.info({
            title: proses,
            message: `Fitur Bulk Belum tersedia silahkan pilih satu data`
          })
        }
      }
    } catch (e) {
      // console.log(e)
      setPloading({ loading: false, message: `` })
      errorService(e)
    }
    try {
      var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
      if (checkboxRoot.checked) {
        if (checkboxRoot.getAttribute('aria-checked')) {
          checkboxRoot.click()
          checkboxRoot.click()
        } else {
          checkboxRoot.click()
        }
      }
      document.getElementsByClassName('mdi-reload')[0].click()
    } catch (e) { }
  }

  async function downloadSearch(item) {
    if (AppMode.onPremise) {
      await getOrganizationsOp()
    } else {
      await getOrganizations()
    }
    setShowDialog(true)
  }

  var actions = []

  async function handlePengajuanApproval() {
    modalStore.showConfirm({
      title: t.translate('words.pengajuanApproval'),
      children: t.translate('words.prosesSpt'),
      onSubmit: (callback) => {
        handlePengajuanApprovalExec()
        callback()
      }
    })
  }

  async function handlePengajuanApprovalExec() {
    var selected = JSON.parse(TableWrapper.getSelected(baseId))
    var selectedData = []
    selected.map((d) => {
      var item = data[d]
      if (item.status == "POSTING") {
        selectedData.push(item)
      }
    })
    var stateId = selectedData.reduce((p, d) => {
      if (d.flowState) p.push(d.flowState.id);
      return p;
    }, []);
    var states = await service.getState(stateId);
    var stateMap = states.data.reduce((p, d) => {
      p[d.id] = d;
      return p;
    }, {})
    setApprovalState({
      approvalDialog: true,
      approvalList: selectedData,
      approvalType: "Pengajuan",
      stateMap: stateMap,
      stateList: states.data
    })
  }

  async function handleApproval() {
    modalStore.showConfirm({
      title: t.translate('words.approval'),
      children: t.translate('words.prosesSpt'),
      onSubmit: (callback) => {
        handleApprovalExec()
        callback()
      }
    })
  }

  async function handleApprovalExec() {
    var selected = JSON.parse(TableWrapper.getSelected(baseId))
    var selectedData = []
    selected.map((d) => {
      var item = data[d]
      if (item.status == "WAITING") {
        selectedData.push(item)
      }
    })
    var stateId = selectedData.reduce((p, d) => {
      if (d.flowState) p.push(d.flowState.id);
      return p;
    }, []);
    var states = await service.getState(stateId);
    var stateMap = states.data.reduce((p, d) => {
      p[d.id] = d;
      return p;
    }, {})
    setApprovalState({
      approvalDialog: true,
      approvalList: selectedData,
      approvalType: "Approval",
      stateMap: stateMap,
      stateList: states.data
    })
  }

  async function handleCancelApproval() {
    modalStore.showConfirm({
      title: t.translate('words.cancelApproval'),
      children: t.translate('words.prosesSpt'),
      onSubmit: (callback) => {
        handleCancelApprovalExec()
        callback()
      }
    })
  }

  async function handleCancelApprovalExec() {
    var proses = t.translate('words.cancelApproval')
    var selected = JSON.parse(TableWrapper.getSelected(baseId))
    var selectedData = []
    selected.map((d) => selectedData.push(data[d]))
    setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
    var selainWaitingDanApproved = []
    var datas = []
    var dataExc = []
    if (selectedData.length > 0) {
      selectedData.map((d) => {
        if (d.status == 'WAITING' || d.status == 'APPROVED') {
          datas.push(d)
          dataExc.push({
            formType: "EUNIFIKASI",
            formId: d.id,
            organizationId: null
          })
        } else {
          selainWaitingDanApproved.push(d)
        }
      })
    }
    if (selainWaitingDanApproved.length > 0) {
      iziToast.warning({
        title: proses,
        message: `${t.translate('words.cancelApprovalHint')}`
      })
      setPloading({ loading: false, message: `` })
    } else {
      try {
        await service.cancelBulk(dataExc);
        setPloading({ loading: false, message: `` })
        iziToast.success({
          title: proses,
          message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
        })
      } catch (e) {
        setPloading({ loading: false, message: `` })
        ErrorService(e)
      }
    }
    try {
      var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
      if (checkboxRoot.checked) {
        if (checkboxRoot.getAttribute('aria-checked')) {
          checkboxRoot.click()
          checkboxRoot.click()
        } else {
          checkboxRoot.click()
        }
      }
      document.getElementsByClassName('mdi-reload')[0].click()
    } catch (e) { }
  }

  async function downloadOpPdf(item) {
    if (AppMode.onPremise) {
      await getOrganizationsOp()
    } else {
      await getOrganizations()
    }
    setDialogOpPdf(true)
  }

  async function downloadOpPdfBpe(item) {
    if (AppMode.onPremise) {
      await getOrganizationsOp()
    } else {
      await getOrganizations()
    }
    setDialogOpPdfBpe(true)
  }

  async function downloadOpPdfInduk(item) {
    if (AppMode.onPremise) {
      await getOrganizationsOp()
    } else {
      await getOrganizations()
    }
    setDialogOpSptSummaryIndukPdf(true)
  }

  async function summarySPt(item) {
    if (AppMode.onPremise) {
      await getOrganizationsOp()
    } else {
      await getOrganizations()
    }
    setDialogOpSptSummary(true)
  }

  if (AppMode.onPremise){
    //actions.unshift(new TableWrapper.action(`${t.translate('words.cetakBulk')}`, 'mdi mdi-file-pdf'))
    actions.unshift(new TableWrapper.action(`${t.translate('words.pdfBp')}`, 'mdi mdi-file-pdf', (item) => { downloadOpPdf(item) }, true))
    actions.unshift(new TableWrapper.action(`${t.translate('words.pdfBpe')}`, 'mdi mdi-file-pdf', (item) => { downloadOpPdfBpe(item) }, true))
    actions.unshift(new TableWrapper.action(`${t.translate('words.pdfSummaryInduk')}`, 'mdi mdi-file-pdf', (item) => { downloadOpPdfInduk(item) }, true))
    actions.unshift(new TableWrapper.action(`${t.translate('words.exportBpOp')}`, 'mdi mdi-file-excel', (item) => { downloadXls(item) }, true))
    // actions.unshift(new TableWrapper.action(`${t.translate('words.pdfSummary')}`, 'mdi mdi-file-excel', (item) => { summarySPt(item) }, true))
  }

  if (AppMode.onPremise && enableApproval) {
  } else {
    actions.unshift(new TableWrapper.action(`${t.translate('words.downloadExportBp')}`, 'mdi mdi-file-excel', (item) => { downloadXls(item) }, true))
    actions.unshift(new TableWrapper.action(`${t.translate('words.downloadSPT')}`, 'mdi mdi-file-pdf', (item) => { download(item) }, true));
  }

  var approvalTitle = t.translate('words.pengajuanApproval')
  var approvalDef = [
    {
      inputType: inputTypes.INPUT,
      label: t.translate(`words.note`),
      key: 'globalNote',
      type: 'text',
      width: '100%'
    },
  ]
  if (approvalState && approvalState.approvalType) {
    if (approvalState.approvalType == "Approval") {
      approvalTitle = t.translate('words.approval')
      approvalDef = []
      if (approvalState.stateList && approvalState.stateList.length > 0) {
        approvalState.stateList.map((d) => {
          var options = []
          d.actions.map((e) => {
            options.push({
              label: e.name,
              value: e.name + "." + e.id,
              item: e
            })
          })
          approvalDef.push({
            inputType: inputTypes.SELECT,
            label: "Flow state " + d.name,
            key: 'globalAction.' + d.id,
            type: 'text',
            width: '100%',
            options: options
          })
        })
      }
      approvalDef.push({
        inputType: inputTypes.INPUT,
        label: t.translate(`words.note`),
        key: 'globalNote',
        type: 'text',
        width: '100%'
      })
    }
  }

  var exportData = ()=> {
    setExportDialog(true)
  }

  var exportSelected = ()=> {

  }

  var exportAll = ()=> {

  }

  var columns = [
    {
      label: t.translate('words.npwp'),
      searchable: true,
      sortable: true,
      key: 'npwp',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.npwp)
    },
    {
      label: t.translate('words.name'),
      searchable: true,
      sortable: true,
      key: 'name',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.name)
    },
    {
      label: t.translate('words.month'),
      searchable: true,
      sortable: true,
      key: 'month',
      type: TableWrapper.dataTypes.NUMBER,
      render: item => (item.month)
    },
    {
      label: t.translate('words.year'),
      searchable: true,
      sortable: true,
      key: 'year',
      type: TableWrapper.dataTypes.NUMBER,
      render: item => (item.year)
    },
    {
      label: t.translate('words.rev'),
      searchable: true,
      sortable: true,
      key: 'rev',
      type: TableWrapper.dataTypes.NUMBER,
      render: item => (item.rev)
    },
    {
      label: t.translate('words.status'),
      searchable: true,
      sortable: true,
      key: 'status',
      type: TableWrapper.dataTypes.STRING,
      render: item => {
        if (item.status == "POSTING") {
          return <Chip style={{ background: "#FFC108", color: 'white' }}>{(item.status + "").toUpperCase()}</Chip>
        } else if (item.status == "COMPLETED") {
          return <Chip style={{ background: "#2096F3", color: 'white' }}>{(item.status + "").toUpperCase()}</Chip>
        } else if (item.status == "FINISH") {
          return <Chip style={{ background: "#8BC34A", color: 'white' }}>{(item.status + "").toUpperCase()}</Chip>
        } else if (item.status == "FAILED") {
          return <Chip style={{ background: "#F44336", color: 'white' }}>{(item.status + "").toUpperCase()}</Chip>
        } else if (item.status == "DELETED") {
          return <Chip style={{ background: "grey", color: 'white' }}>{(item.status + "").toUpperCase()}</Chip>
        } else {
          return <Chip style={{ background: "black", color: 'white' }}>{(item.status + "").toUpperCase()}</Chip>
        }
      }
    },
    {
      label : t.translate('words.approvalStatus'),
      searchable : true,
      sortable : true,
      key : 'approvalStatus',
      type : TableWrapper.dataTypes.STRING,
      render : item =>{
        if (item.approvalStatus !== null && item.approvalStatus !== undefined) {
          return <Chip style={{ background: "grey", color: 'white' }}>{(item.approvalStatus + "").toUpperCase()}</Chip>
        } else {
          return "-"
        }
      }
    },
    {
      label: t.translate('words.message'),
      searchable: true,
      sortable: true,
      key: 'message',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.message)
    },
    {
      label: t.translate('words.createdBy'),
      searchable: true,
      sortable: true,
      key: 'createdBy',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.createdBy)
    },
    {
      label: t.translate('words.createdDate'),
      searchable: true,
      sortable: true,
      key: 'createdDate',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.createdDate)
    },
    {
      label: t.translate('words.lastModifiedBy'),
      searchable: true,
      sortable: true,
      key: 'lastModifiedBy',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.lastModifiedBy)
    },
    {
      label: t.translate('words.lastModifiedDate'),
      searchable: true,
      sortable: true,
      key: 'lastModifiedDate',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.lastModifiedDate)
    },
  ]

  if(!AppMode.onPremise){
     delete columns[6]
  }

  return (
    <>
      <LoadingOverlay
        style={{
          position: 'inherit',
          background: 'red'
        }}
        active={ploading.loading}
        spinner
        text={ploading.message}
      >
      </LoadingOverlay>
      <Dialog
        visible={exportDialog}
        disableFocusOnMount={() => { }}
        onRequestClose={() => {
          setExportDialog(false)
        }}
        style={{
          width: 800,
        }}
      >
        <DialogHeader>
          <DialogTitle>{t.translate('words.exportData')}</DialogTitle>
        </DialogHeader>
        <DialogContent>
          <Button themeType="outline" theme="primary" style={{ marginRight: '12px' }} onClick={()=> exportSelected()}>{t.translate('words.exportDataSelected')}</Button>
          <Button themeType="outline" onClick={()=> exportAll()}>{t.translate('words.exportDataAll')}</Button>
        </DialogContent>
      </Dialog>
      <Dialog
        visible={dialogOpPdf}
        disableFocusOnMount={() => { }}
        onRequestClose={() => {
          setDialogOpPdf(false)
        }}
        style={{
          width: 800,
        }}
      >
        <DialogHeader>
          <DialogTitle>{t.translate('words.pdfBp')}</DialogTitle>
        </DialogHeader>
        <DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>
          <DataForm
            baseId={`${baseId}-bp-pdf`}
            defaultData={{
              merge: false,
              mergeAll: false,
              allNpwp: false
            }}
            hintShowIcon={true}
            definitions={[
              {
                inputType: inputTypes.REACT_SELECT,
                label: t.translate(`words.npwp`),
                key: 'npwp',
                labelKey: 'label',
                valueKey: 'label',
                data: organization,
                options: organization,
                async: true,
                defaultOptions: organization,
                isMulti: true,
                loadOptions: async (inputValues)=> {
                  if(AppMode.onPremise){
                    if(inputValues.length % 2){
                      var options = await getOrganizationsOp(inputValues)
                      return options
                    } else {
                      return organization
                    }
                  } else {
                    if(inputValues.length % 2){
                      var options = await getOrganizations(inputValues)
                      return options
                    } else {
                      return organization
                    }
                  }
                },
              },
              {
                inputType: inputTypes.SELECT,
                label: t.translate(`words.masaAwal`),
                key: 'masa1',
                validation: 'required',
                type: 'text',
                width: '50%',
                options: [
                  { label: '1', value: 1 },
                  { label: '2', value: 2 },
                  { label: '3', value: 3 },
                  { label: '4', value: 4 },
                  { label: '5', value: 5 },
                  { label: '6', value: 6 },
                  { label: '7', value: 7 },
                  { label: '8', value: 8 },
                  { label: '9', value: 9 },
                  { label: '10', value: 10 },
                  { label: '11', value: 11 },
                  { label: '12', value: 12 },
                ]
              },
              {
                inputType: inputTypes.SELECT,
                label: t.translate(`words.masaAkhir`),
                key: 'masa2',
                validation: 'required',
                type: 'text',
                width: '50%',
                options: [
                  { label: '1', value: 1 },
                  { label: '2', value: 2 },
                  { label: '3', value: 3 },
                  { label: '4', value: 4 },
                  { label: '5', value: 5 },
                  { label: '6', value: 6 },
                  { label: '7', value: 7 },
                  { label: '8', value: 8 },
                  { label: '9', value: 9 },
                  { label: '10', value: 10 },
                  { label: '11', value: 11 },
                  { label: '12', value: 12 },
                ]
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.dariTahun`),
                key: 'tahun1',
                validation: 'required',
                type: 'text',
                width: '50%'
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.keTahun`),
                key: 'tahun2',
                validation: 'required',
                type: 'text',
                width: '50%'
              },
              {
                inputType: inputTypes.CHECKBOX,
                label: t.translate(`words.merge`),
                key: 'merge',
                type: 'text',
                width: '33%'
              },
              {
                inputType: inputTypes.CHECKBOX,
                label: t.translate(`words.mergeAll`),
                key: 'mergeAll',
                type: 'text',
                width: '33%'
              },
              {
                inputType: inputTypes.CHECKBOX,
                label: t.translate(`words.allNpwp`),
                key: 'allNpwp',
                type: 'text',
                width: '33%'
              },
            ]}
            additionalAction={[
              {
                show: true,
                render: () => (
                  <Button style={{ marginRight: 15 }} onClick={() => setDialogOpPdf(false)}>{t.translate('words.close')}</Button>
                )
              }
            ]}
            onSubmit={async (values, callback) => {
              try {
                var npwps = []
                var formData = Object.assign({}, values)
                try {
                  values.npwp.map((d) => {
                    if (d.value == "") { } else {
                      if (d.value && d.value.npwp) {
                        npwps.push(d.value.npwp)
                      }
                    }
                  })
                } catch(e){}
                try {
                  if (formData.allOrg) {
                    let organizations = UtilsService.getOrganizations(toJS(authStore))
                    organizations.map((d) => {
                      npwps.push(d.npwp)
                    })
                  }
                } catch (e) { }
                // formData.npwp = npwps
                var orgNpwps = []
                var orgIds = []
                if (formData.npwp && formData.npwp.length > 0) {
                  formData.npwp.map((d) => {
                    orgNpwps.push(d.npwp)
                    orgIds.push(d.id)
                  })
                }
                formData.orgNpwps = orgNpwps
                formData.orgIds = orgIds
                delete formData.npwp
                let res = await service.opPdfBp(formData)
                // window.open(res.data.url)
                callback(`${t.translate('words.pdfBp')} ${t.translate('words.prosesDone')}${t.translate('words.cekLog')}`, false, false, false)
                setDialogOpPdf(false)
              } catch (e) {
                setDialogOpPdf(false)
                errorService(e)
                iziToast.error({
                  message: `${t.translate('words.pdfBp')} ${t.translate('words.prosesFail')}${t.translate('words.cekLog')}`
                })
                callback('', false, false, false)
              }
            }}
          >
          </DataForm>
        </DialogContent>
      </Dialog>
      <Dialog
        visible={dialogOpPdfBpe}
        disableFocusOnMount={() => { }}
        onRequestClose={() => {
          setDialogOpPdfBpe(false)
        }}
        style={{
          width: 800,
        }}
      >
        <DialogHeader>
          <DialogTitle>{t.translate('words.pdfBpe')}</DialogTitle>
        </DialogHeader>
        <DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>
          <DataForm
            baseId={`${baseId}-bpe-pdf`}
            defaultData={{
              merge: false,
              mergeAll: false,
              allNpwp: false
            }}
            hintShowIcon={true}
            definitions={[
              {
                inputType: inputTypes.REACT_SELECT,
                label: t.translate(`words.npwp`),
                key: 'npwp',
                labelKey: 'label',
                valueKey: 'label',
                data: organization,
                options: organization,
                async: true,
                defaultOptions: organization,
                isMulti: true,
                loadOptions: async (inputValues)=> {
                  if(AppMode.onPremise){
                    if(inputValues.length % 2){
                      var options = await getOrganizationsOp(inputValues)
                      return options
                    } else {
                      return organization
                    }
                  } else {
                    if(inputValues.length % 2){
                      var options = await getOrganizations(inputValues)
                      return options
                    } else {
                      return organization
                    }
                  }
                },
              },
              {
                inputType: inputTypes.SELECT,
                label: t.translate(`words.masaAwal`),
                key: 'masa1',
                validation: 'required',
                type: 'text',
                width: '50%',
                options: [
                  { label: '1', value: 1 },
                  { label: '2', value: 2 },
                  { label: '3', value: 3 },
                  { label: '4', value: 4 },
                  { label: '5', value: 5 },
                  { label: '6', value: 6 },
                  { label: '7', value: 7 },
                  { label: '8', value: 8 },
                  { label: '9', value: 9 },
                  { label: '10', value: 10 },
                  { label: '11', value: 11 },
                  { label: '12', value: 12 },
                ]
              },
              {
                inputType: inputTypes.SELECT,
                label: t.translate(`words.masaAkhir`),
                key: 'masa2',
                validation: 'required',
                type: 'text',
                width: '50%',
                options: [
                  { label: '1', value: 1 },
                  { label: '2', value: 2 },
                  { label: '3', value: 3 },
                  { label: '4', value: 4 },
                  { label: '5', value: 5 },
                  { label: '6', value: 6 },
                  { label: '7', value: 7 },
                  { label: '8', value: 8 },
                  { label: '9', value: 9 },
                  { label: '10', value: 10 },
                  { label: '11', value: 11 },
                  { label: '12', value: 12 },
                ]
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.dariTahun`),
                key: 'tahun1',
                validation: 'required',
                type: 'text',
                width: '50%'
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.keTahun`),
                key: 'tahun2',
                validation: 'required',
                type: 'text',
                width: '50%'
              },
              {
                inputType: inputTypes.CHECKBOX,
                label: t.translate(`words.allNpwp`),
                key: 'allNpwp',
                type: 'text',
                width: '100%'
              },
            ]}
            additionalAction={[
              {
                show: true,
                render: () => (
                  <Button style={{ marginRight: 15 }} onClick={() => setDialogOpPdfBpe(false)}>{t.translate('words.close')}</Button>
                )
              }
            ]}
            onSubmit={async (values, callback) => {
              try {
                var npwps = []
                var formData = Object.assign({}, values)
                try {
                  values.npwp.map((d) => {
                    if (d.value == "") { } else {
                      if (d.value && d.value.npwp) {
                        npwps.push(d.value.npwp)
                      }
                    }
                  })
                } catch(e){}
                try {
                  if (formData.allOrg) {
                    let organizations = UtilsService.getOrganizations(toJS(authStore))
                    organizations.map((d) => {
                      npwps.push(d.npwp)
                    })
                  }
                } catch (e) { }
                // formData.npwp = npwps
                var orgNpwps = []
                var orgIds = []
                if (formData.npwp && formData.npwp.length > 0) {
                  formData.npwp.map((d) => {
                    orgNpwps.push(d.npwp)
                    orgIds.push(d.id)
                  })
                }
                formData.orgNpwps = orgNpwps
                formData.orgIds = orgIds
                delete formData.npwp
                let res = await service.opPdfBpe(formData)
                // window.open(res.data.url)
                callback(`${t.translate('words.pdfBpe')} ${t.translate('words.prosesDone')}${t.translate('words.cekLog')}`, false, false, false)
                setDialogOpPdfBpe(false)
              } catch (e) {
                setDialogOpPdfBpe(false)
                errorService(e)
                iziToast.error({
                  message: `${t.translate('words.pdfBpe')} ${t.translate('words.prosesFail')}${t.translate('words.cekLog')}`
                })
                callback('', false, false, false)
              }
            }}
          >
          </DataForm>
        </DialogContent>
      </Dialog>
      <Dialog
        visible={sptSummary}
        disableFocusOnMount={() => { }}
        onRequestClose={() => {
          setDialogOpSptSummary(false)
        }}
        style={{
          width: 800,
        }}
      >
        <DialogHeader>
          <DialogTitle>{t.translate('words.pdfSummary')}</DialogTitle>
        </DialogHeader>
        <DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>
          <DataForm
            baseId={`${baseId}-spt-pdf`}
            defaultData={{
              merge: false,
              mergeAll: false,
              allNpwp: false
            }}
            hintShowIcon={true}
            definitions={[
              {
                inputType: inputTypes.REACT_SELECT,
                label: t.translate(`words.npwp`),
                key: 'npwp',
                labelKey: 'label',
                valueKey: 'label',
                data: organization,
                options: organization,
                async: true,
                defaultOptions: organization,
                isMulti: true,
                loadOptions: async (inputValues)=> {
                  if(AppMode.onPremise){
                    if(inputValues.length % 2){
                      var options = await getOrganizationsOp(inputValues)
                      return options
                    } else {
                      return organization
                    }
                  } else {
                    if(inputValues.length % 2){
                      var options = await getOrganizations(inputValues)
                      return options
                    } else {
                      return organization
                    }
                  }
                },
              },
              {
                inputType: inputTypes.SELECT,
                label: t.translate(`words.masaAwal`),
                key: 'masa1',
                type: 'text',
                width: '50%',
                options: [
                  { label: '1', value: 1 },
                  { label: '2', value: 2 },
                  { label: '3', value: 3 },
                  { label: '4', value: 4 },
                  { label: '5', value: 5 },
                  { label: '6', value: 6 },
                  { label: '7', value: 7 },
                  { label: '8', value: 8 },
                  { label: '9', value: 9 },
                  { label: '10', value: 10 },
                  { label: '11', value: 11 },
                  { label: '12', value: 12 },
                ]
              },
              {
                inputType: inputTypes.SELECT,
                label: t.translate(`words.masaAkhir`),
                key: 'masa2',
                type: 'text',
                width: '50%',
                options: [
                  { label: '1', value: 1 },
                  { label: '2', value: 2 },
                  { label: '3', value: 3 },
                  { label: '4', value: 4 },
                  { label: '5', value: 5 },
                  { label: '6', value: 6 },
                  { label: '7', value: 7 },
                  { label: '8', value: 8 },
                  { label: '9', value: 9 },
                  { label: '10', value: 10 },
                  { label: '11', value: 11 },
                  { label: '12', value: 12 },
                ]
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.dariTahun`),
                key: 'tahun1',
                type: 'text',
                width: '50%'
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.keTahun`),
                key: 'tahun2',
                type: 'text',
                width: '50%'
              },
              {
                inputType: inputTypes.CHECKBOX,
                label: t.translate(`words.allNpwp`),
                key: 'allNpwp',
                type: 'text',
                width: '100%'
              },
            ]}
            additionalAction={[
              {
                show: true,
                render: () => (
                  <Button style={{ marginRight: 15 }} onClick={() => setDialogOpSptSummary(false)}>{t.translate('words.close')}</Button>
                )
              }
            ]}
            onSubmit={async (values, callback) => {
              try {
                var npwps = []
                var formData = Object.assign({}, values)
                values.npwp.map((d) => {
                  if (d.value == "") { } else {
                    if (d.value && d.value.npwp) {
                      npwps.push(d.value.npwp)
                    }
                  }
                })
                try {
                  if (formData.allOrg) {
                    let organizations = UtilsService.getOrganizations(toJS(authStore))
                    organizations.map((d) => {
                      npwps.push(d.npwp)
                    })
                  }
                } catch (e) { }
                // formData.npwp = npwps
                var orgNpwps = []
                var orgIds = []
                if (formData.npwp && formData.npwp.length > 0) {
                  formData.npwp.map((d) => {
                    orgNpwps.push(d.npwp)
                    orgIds.push(d.id)
                  })
                }
                formData.orgNpwps = orgNpwps
                formData.orgIds = orgIds
                delete formData.npwp
                let res = await service.opSptSummary(formData)
                // window.open(res.data.url)
                callback(`${t.translate('words.pdfSummary')} ${t.translate('words.prosesDone')}${t.translate('words.cekLog')}`, false, false, false)
                setDialogOpSptSummary(false)
              } catch (e) {
                setDialogOpSptSummary(false)
                errorService(e)
                iziToast.error({
                  message: `${t.translate('words.pdfSummary')} ${t.translate('words.prosesFail')}${t.translate('words.cekLog')}`
                })
                callback('', false, false, false)
              }
            }}
          >
          </DataForm>
        </DialogContent>
      </Dialog>
      <Dialog
        visible={dialogOpSptSummaryIndukPdf}
        disableFocusOnMount={() => { }}
        onRequestClose={() => {
          setDialogOpSptSummaryIndukPdf(false)
        }}
        style={{
          width: 800,
        }}
      >
        <DialogHeader>
          <DialogTitle>{t.translate('words.pdfSummaryInduk')}</DialogTitle>
        </DialogHeader>
        <DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>
          <DataForm
            baseId={`${baseId}-bpe-pdf`}
            defaultData={{
              merge: false,
              mergeAll: false,
              allNpwp: false
            }}
            hintShowIcon={true}
            definitions={[
              {
                inputType: inputTypes.REACT_SELECT,
                label: t.translate(`words.npwp`),
                key: 'npwp',
                labelKey: 'label',
                valueKey: 'label',
                data: organization,
                options: organization,
                async: true,
                defaultOptions: organization,
                isMulti: true,
                loadOptions: async (inputValues)=> {
                  if(AppMode.onPremise){
                    if(inputValues.length % 2){
                      var options = await getOrganizationsOp(inputValues)
                      return options
                    } else {
                      return organization
                    }
                  } else {
                    if(inputValues.length % 2){
                      var options = await getOrganizations(inputValues)
                      return options
                    } else {
                      return organization
                    }
                  }
                },
              },
              {
                inputType: inputTypes.SELECT,
                label: t.translate(`words.masaAwal`),
                key: 'masa1',
                validation: 'required',
                type: 'text',
                width: '50%',
                options: [
                  { label: '1', value: 1 },
                  { label: '2', value: 2 },
                  { label: '3', value: 3 },
                  { label: '4', value: 4 },
                  { label: '5', value: 5 },
                  { label: '6', value: 6 },
                  { label: '7', value: 7 },
                  { label: '8', value: 8 },
                  { label: '9', value: 9 },
                  { label: '10', value: 10 },
                  { label: '11', value: 11 },
                  { label: '12', value: 12 },
                ]
              },
              {
                inputType: inputTypes.SELECT,
                label: t.translate(`words.masaAkhir`),
                key: 'masa2',
                validation: 'required',
                type: 'text',
                width: '50%',
                options: [
                  { label: '1', value: 1 },
                  { label: '2', value: 2 },
                  { label: '3', value: 3 },
                  { label: '4', value: 4 },
                  { label: '5', value: 5 },
                  { label: '6', value: 6 },
                  { label: '7', value: 7 },
                  { label: '8', value: 8 },
                  { label: '9', value: 9 },
                  { label: '10', value: 10 },
                  { label: '11', value: 11 },
                  { label: '12', value: 12 },
                ]
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.dariTahun`),
                key: 'tahun1',
                validation: 'required',
                type: 'text',
                width: '50%'
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.keTahun`),
                key: 'tahun2',
                validation: 'required',
                type: 'text',
                width: '50%'
              },
              {
                inputType: inputTypes.CHECKBOX,
                label: t.translate(`words.allNpwp`),
                key: 'allNpwp',
                type: 'text',
                width: '100%'
              },
            ]}
            additionalAction={[
              {
                show: true,
                render: () => (
                  <Button style={{ marginRight: 15 }} onClick={() => setDialogOpSptSummaryIndukPdf(false)}>{t.translate('words.close')}</Button>
                )
              }
            ]}
            onSubmit={async (values, callback) => {
              try {
                var npwps = []
                var formData = Object.assign({}, values)
                try {
                  values.npwp.map((d) => {
                    if (d.value == "") { } else {
                      if (d.value && d.value.npwp) {
                        npwps.push(d.value.npwp)
                      }
                    }
                  })
                } catch(e){}
                try {
                  if (formData.allOrg) {
                    let organizations = UtilsService.getOrganizations(toJS(authStore))
                    organizations.map((d) => {
                      npwps.push(d.npwp)
                    })
                  }
                } catch (e) { }
                // formData.npwp = npwps
                var orgNpwps = []
                var orgIds = []
                if (formData.npwp && formData.npwp.length > 0) {
                  formData.npwp.map((d) => {
                    orgNpwps.push(d.npwp)
                    orgIds.push(d.id)
                  })
                }
                formData.orgNpwps = orgNpwps
                formData.orgIds = orgIds
                delete formData.npwp
                let res = await service.opSptSummaryIndukPdf(formData)
                // window.open(res.data.url)
                callback(`${t.translate('words.pdfSummaryInduk')} ${t.translate('words.prosesDone')}${t.translate('words.cekLog')}`, false, false, false)
                setDialogOpSptSummaryIndukPdf(false)
              } catch (e) {
                setDialogOpSptSummaryIndukPdf(false)
                errorService(e)
                iziToast.error({
                  message: `${t.translate('words.pdfSummaryInduk')} ${t.translate('words.prosesFail')}${t.translate('words.cekLog')}`
                })
                callback('', false, false, false)
              }
            }}
          >
          </DataForm>
        </DialogContent>
      </Dialog>
      
      <Dialog
        visible={approvalState.approvalDialog}
        disableFocusOnMount={() => { }}
        onRequestClose={() => {
          setApprovalState({
            approvalDialog: false
          })
        }}
        style={{
          width: 800,
        }}
      >
        <DialogHeader>
          <DialogTitle>{approvalTitle}</DialogTitle>
        </DialogHeader>
        <DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>
          <DataForm
            style={{
              maxWidth: '100%'
            }}
            baseId={`${baseId}-pengajuan-approval`}
            defaultData={dialogData}
            hintShowIcon={true}
            definitions={approvalDef}
            onChange={(formData, key, value) => {
              try {
                if (key.split('.')[0] == 'globalAction') {
                  // console.log(value, "TELL ME")
                  formData.actionId = value.split('.')[1]
                }
              } catch (e) { }
              // noteOnChange(e, v) {
              //   if(this.typingObj) clearTimeout(this.typingObj);

              //   this.typingObj = setTimeout(async ()=> {
              //     var formState = {...this.props.formState}
              //     this.props.approvalList.forEach((d, i)=> {
              //       if(!formState[d.id]) formState[d.id] = {}
              //       formState[d.id].note = v;
              //     })

              //     this.props.initialize(formState);
              //   }, this.typingTimeout);
              // }

              // actionOnChange(e, v, pv, f) {
              //   var flowStateId = f.split(".")[1];

              //   var formState = {...this.props.formState}
              //   this.props.approvalList.forEach((d, i)=> {
              //     if(d.flowState === flowStateId) {
              //       if(!formState[d.id]) formState[d.id] = {}
              //       formState[d.id].actionId = v;
              //     }
              //   });

              //   this.props.initialize(formState);
              // }
            }}
            onSubmit={async (values, callback) => {
              try {
                var proses = t.translate('words.pengajuanApproval')
                var list = []
                approvalState.approvalList.map((d, i) => {
                  var v = values[d.id];
                  if (!v) v = {};
                  if (values.globalAction) {
                    v.actionId = values.globalAction[d.flowState.id]
                  }
                  // console.log(values, "TELL ME")
                  var data = {
                    ...v,
                    actionId: values.actionId,
                    note: values.globalNote,
                    formType: "EUNIFIKASI",
                    formId: d.id,
                    organizationId: null,
                    companyId: JSON.parse(localStorage.getItem('user')).company.id,
                    npwp: d.npwp,
                    month: d.month,
                    year: d.year,
                    pembetulan: d.rev,
                    status: d.status,
                  };
                  if (d.flowState) data.stateId = d.flowState.id
                  list.push(data);
                })
                if (approvalState.approvalType == 'Pengajuan') {
                  await service.pengajuanBulk(list)
                } else {
                  await service.actionBulk(list)
                }
                setApprovalState({
                  approvalDialog: false
                })
                callback("", false, false, false)
                iziToast.success({
                  title: proses,
                  message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
                })
              } catch (e) {
                ErrorService(e)
                setApprovalState({
                  approvalDialog: false
                })
              }
              try {
                var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
                if (checkboxRoot.checked) {
                  if (checkboxRoot.getAttribute('aria-checked')) {
                    checkboxRoot.click()
                    checkboxRoot.click()
                  } else {
                    checkboxRoot.click()
                  }
                }
                document.getElementsByClassName('mdi-reload')[0].click()
              } catch (e) { }
            }}
          ></DataForm>
        </DialogContent>
      </Dialog>
      <Dialog
        visible={showDialog}
        disableFocusOnMount={() => { }}
        onRequestClose={() => {
          setShowDialog(false)
        }}
        style={{
          width: 800,
        }}
      >
        <DialogHeader>
          <DialogTitle>{t.translate('words.downloadSPTSearch')}</DialogTitle>
        </DialogHeader>
        <DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>
          <DataForm
            baseId={`${baseId}-send-email`}
            defaultData={dialogData}
            hintShowIcon={true}
            definitions={[
              {
                inputType: inputTypes.REACT_SELECT,
                label: t.translate(`words.npwp`),
                key: 'npwp',
                labelKey: 'label',
                valueKey: 'label',
                data: organization,
                options: organization,
                async: true,
                defaultOptions: organization,
                isMulti: true,
                loadOptions: async (inputValues)=> {
                  if(AppMode.onPremise){
                    if(inputValues.length % 2){
                      var options = await getOrganizationsOp(inputValues)
                      return options
                    } else {
                      return organization
                    }
                  } else {
                    if(inputValues.length % 2){
                      var options = await getOrganizations(inputValues)
                      return options
                    } else {
                      return organization
                    }
                  }
                },
              },
              {
                inputType: inputTypes.SELECT,
                label: t.translate(`words.masaAwal`),
                key: 'start_month',
                type: 'text',
                width: '50%',
                options: [
                  { label: '1', value: 1 },
                  { label: '2', value: 2 },
                  { label: '3', value: 3 },
                  { label: '4', value: 4 },
                  { label: '5', value: 5 },
                  { label: '6', value: 6 },
                  { label: '7', value: 7 },
                  { label: '8', value: 8 },
                  { label: '9', value: 9 },
                  { label: '10', value: 10 },
                  { label: '11', value: 11 },
                  { label: '12', value: 12 },
                ]
              },
              {
                inputType: inputTypes.SELECT,
                label: t.translate(`words.masaAkhir`),
                key: 'end_month',
                type: 'text',
                width: '50%',
                options: [
                  { label: '1', value: 1 },
                  { label: '2', value: 2 },
                  { label: '3', value: 3 },
                  { label: '4', value: 4 },
                  { label: '5', value: 5 },
                  { label: '6', value: 6 },
                  { label: '7', value: 7 },
                  { label: '8', value: 8 },
                  { label: '9', value: 9 },
                  { label: '10', value: 10 },
                  { label: '11', value: 11 },
                  { label: '12', value: 12 },
                ]
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.dariTahun`),
                key: 'start_year',
                type: 'text',
                width: '50%'
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.keTahun`),
                key: 'end_year',
                type: 'text',
                width: '50%'
              },
              {
                inputType: inputTypes.CHECKBOX,
                label: t.translate(`words.lastRev`),
                key: 'last_rev',
                type: 'text',
                width: '50%'
              },
              {
                inputType: inputTypes.CHECKBOX,
                label: t.translate(`words.allOrg`),
                key: 'allOrg',
                type: 'text',
                width: '50%'
              },
            ]}
            additionalAction={[
              {
                show: true,
                render: () => (
                  <Button style={{ marginRight: 15 }} onClick={() => setShowDialog(false)}>{t.translate('words.close')}</Button>
                )
              }
            ]}
            onSubmit={async (values, callback) => {
              // console.log(values)
              try {
                var npwps = []
                var formData = Object.assign({}, values)
                values.npwp.map((d) => {
                  if (d.value == "") { } else {
                    if (d.value && d.value.npwp) {
                      npwps.push(d.value.npwp)
                    }
                  }
                })
                try {
                  if (formData.allOrg) {
                    let organizations = UtilsService.getOrganizations(toJS(authStore))
                    organizations.map((d) => {
                      npwps.push(d.npwp)
                    })
                  }
                } catch (e) { }
                formData.npwp = npwps
                let res = await service.downloadSearch(formData)
                window.open(res.data.url)
                callback(`${t.translate('words.downloadSPTSearch')} ${t.translate('words.prosesDone')}`, false, false, false)
                setShowDialog(false)
              } catch (e) {
                setShowDialog(false)
                errorService(e)
                iziToast.error({
                  message: `${t.translate('words.downloadSPTSearch')} ${t.translate('words.prosesFail')}`
                })
                callback('', false, false, false)
              }
            }}
          >
          </DataForm>
        </DialogContent>
      </Dialog>
      <TableWrapper useFilter={false}
        baseId={baseId}
        title={t.translate('modules.daftarSptArchive.title')}
        className={className}
        selectable={true}
        defaultData={[]}
        defaultSortBy="createdDate"
        setPage={search.page} onFetchData={(params) => {
          return (
            new Promise(async (resolve, reject) => {
              params = {...params,...search}
              try {
                params = {...params,...search}
                let getPage = await service.get(params)
                setData(getPage.data)
                delete search.page; setSearch(search); getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
                resolve(getPage)
              } catch(e){
                resolve()
                ErrorService(e)
              }
            })
          )
        }}
        sideHeader={
          <DataForm
            baseId={`${baseId}-search`}
            defaultData={search}
            submitIconClassName='mdi mdi-magnify'
            submitLabel={`${t.translate('words.cari')}`}
            additionalAction={[
              {
                show: true,
                render: () => (
                  <Button style={{ marginRight: 15 }} onClick={() => {
                    setSearch({})
                    var newsearch = { page: 0 }; setSearch(newsearch); localStorage.removeItem(`${baseId}-saved-search`); localStorage.removeItem(`${baseId}-query`);
                    setTimeout(() => {
                      document.getElementsByClassName('mdi-reload')[0].click()
                    }, 1000)
                  }}>{t.translate('words.hapus')}</Button>
                )
              }
            ]}
            onSubmit={(values, callback) => {
              if (values['createdDate.greaterOrEqualThan']) values['createdDate.greaterOrEqualThan'] = new Date(values['createdDate.greaterOrEqualThan'])
              if (values['createdDate.lessOrEqualThan']) values['createdDate.lessOrEqualThan'] = new Date(values['createdDate.lessOrEqualThan'])
              if(AppMode.onPremise){
                if(values['npwp.in'] && values['npwp.in'].length > 0){
                  var stringIn = ""
                  values['npwp.in'].map((d)=> {
                    stringIn += d.value + ","
                  })
                  values['npwp.in'] = stringIn
                }
              } else {
                if(values['npwp.in'] && values['npwp.in'].length > 0){
                  var stringIn = ""
                  values['npwp.in'].map((d)=> {
                    stringIn += d.value.npwp + ","
                  })
                  values['npwp.in'] = stringIn
                }
                if(values.allNpwps){
                  var allNpwpString = ""
                  if(organization && organization.length > 0){
                    organization.map((d)=> {
                      allNpwpString += d.value.npwp + ","
                    })
                  }
                  values['npwp.in'] = allNpwpString
                  delete values.allNpwps
                }
              }
              
              values.page = 0;setSearch(values);localStorage.setItem(`${baseId}-saved-search`, JSON.stringify(values))
              setTimeout(() => {
                document.getElementsByClassName('mdi-reload')[0].click()
                callback("", false, false)
              }, 1000)
            }}
            definitions={[
              {
                render: (
                  <div className="mpk-data-filter">
                    <div className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}>
                      <p>{t.translate(`words.pencarian`)}</p>
                    </div>
                  </div>
                )
              },
              // {
              //   render: (
              //     <div style={{ width: '100%' }}>
              //       {!AppMode.onPremise &&
              //         <Button onClick={()=> exportData()} style={{ width: '100%' }} theme="primary" themeType="contained">{t.translate(`words.exportData`)}</Button>
              //       }
              //     </div>
              //   )
              // },
              {
                inputType: inputTypes.CHECKBOX,
                label: t.translate(`words.allNpwps`),
                key: 'allNpwps',
                type: 'text',
                show : AppMode.onPremise ? false : true
              },
              {
                inputType: inputTypes.REACT_SELECT,
                label: t.translate(`words.npwp`),
                key: 'npwp.in',
                labelKey: 'label', 
                valueKey: 'label',
                data: organization,
                options: organization,
                async: true,
                defaultOptions: organization,
                isMulti: true,
                loadOptions: async (inputValues)=> {
                  if(AppMode.onPremise){
                    if(inputValues.length % 2){
                      var options = await getOrganizationsOp(inputValues)
                      return options
                    } else {
                      return organization
                    }
                  } else {
                    if(inputValues.length % 2){
                      var options = await getOrganizations(inputValues)
                      return options
                    } else {
                      return organization
                    }
                  }
                },
                show: !insideSpt
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.name`),
                key: 'name.contains',
                type: 'text'
              },
              {
                inputType: inputTypes.SELECT,
                label: t.translate(`words.month1`),
                key: 'month.greaterOrEqualThan',
                type: 'text',
                options: [
                  { label: '1', value: '1' },
                  { label: '2', value: '2' },
                  { label: '3', value: '3' },
                  { label: '4', value: '4' },
                  { label: '5', value: '5' },
                  { label: '6', value: '6' },
                  { label: '7', value: '7' },
                  { label: '8', value: '8' },
                  { label: '9', value: '9' },
                  { label: '10', value: '10' },
                  { label: '11', value: '11' },
                  { label: '12', value: '12' },
                ]
              },
              {
                inputType: inputTypes.SELECT,
                label: t.translate(`words.month2`),
                key: 'month.lessOrEqualThan',
                type: 'text',
                options: [
                  { label: '1', value: '1' },
                  { label: '2', value: '2' },
                  { label: '3', value: '3' },
                  { label: '4', value: '4' },
                  { label: '5', value: '5' },
                  { label: '6', value: '6' },
                  { label: '7', value: '7' },
                  { label: '8', value: '8' },
                  { label: '9', value: '9' },
                  { label: '10', value: '10' },
                  { label: '11', value: '11' },
                  { label: '12', value: '12' },
                ]
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.year`),
                key: 'year.equals',
                type: 'text'
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.rev`),
                key: 'rev.equals',
                type: 'text'
              },
              {
                inputType: inputTypes.SELECT,
                label: t.translate(`words.status`),
                key: 'status.equals',
                type: 'text',
                options: [
                  { label: 'CREATED', value: 'CREATED' },
                  { label: 'POSTING', value: 'POSTING' },
                  { label: 'COMPLETED', value: 'COMPLETED' },
                  { label: 'FINISH', value: 'FINISH' },
                  { label: 'FAILED', value: 'FAILED' },
                ]
              },
              {
                inputType: inputTypes.DATEPICKER,
                label: t.translate(`words.tanggal1`),
                key: 'createdDate.greaterOrEqualThan',
                type: 'date'
              },
              {
                inputType: inputTypes.DATEPICKER,
                label: t.translate(`words.tanggal2`),
                key: 'createdDate.lessOrEqualThan',
                type: 'date'
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.createdBy`),
                key: 'createdBy.contains',
                type: 'text'
              },
            ]}
          />
        }
        columns={columns}
        // columns={[
        //   {
        //     label: t.translate('words.npwp'),
        //     searchable: true,
        //     sortable: true,
        //     key: 'npwp',
        //     type: TableWrapper.dataTypes.STRING,
        //     render: item => (item.npwp)
        //   },
        //   {
        //     label: t.translate('words.name'),
        //     searchable: true,
        //     sortable: true,
        //     key: 'name',
        //     type: TableWrapper.dataTypes.STRING,
        //     render: item => (item.name)
        //   },
        //   {
        //     label: t.translate('words.month'),
        //     searchable: true,
        //     sortable: true,
        //     key: 'month',
        //     type: TableWrapper.dataTypes.NUMBER,
        //     render: item => (item.month)
        //   },
        //   {
        //     label: t.translate('words.year'),
        //     searchable: true,
        //     sortable: true,
        //     key: 'year',
        //     type: TableWrapper.dataTypes.NUMBER,
        //     render: item => (item.year)
        //   },
        //   {
        //     label: t.translate('words.rev'),
        //     searchable: true,
        //     sortable: true,
        //     key: 'rev',
        //     type: TableWrapper.dataTypes.NUMBER,
        //     render: item => (item.rev)
        //   },
        //   {
        //     label: t.translate('words.status'),
        //     searchable: true,
        //     sortable: true,
        //     key: 'status',
        //     type: TableWrapper.dataTypes.STRING,
        //     render: item => {
        //       if (item.status == "POSTING") {
        //         return <Chip style={{ background: "#FFC108", color: 'white' }}>{(item.status + "").toUpperCase()}</Chip>
        //       } else if (item.status == "COMPLETED") {
        //         return <Chip style={{ background: "#2096F3", color: 'white' }}>{(item.status + "").toUpperCase()}</Chip>
        //       } else if (item.status == "FINISH") {
        //         return <Chip style={{ background: "#8BC34A", color: 'white' }}>{(item.status + "").toUpperCase()}</Chip>
        //       } else if (item.status == "FAILED") {
        //         return <Chip style={{ background: "#F44336", color: 'white' }}>{(item.status + "").toUpperCase()}</Chip>
        //       } else if (item.status == "DELETED") {
        //         return <Chip style={{ background: "grey", color: 'white' }}>{(item.status + "").toUpperCase()}</Chip>
        //       } else {
        //         return <Chip style={{ background: "black", color: 'white' }}>{(item.status + "").toUpperCase()}</Chip>
        //       }
        //     }
        //   },
        //   {
        //     label: t.translate('words.message'),
        //     searchable: true,
        //     sortable: true,
        //     key: 'message',
        //     type: TableWrapper.dataTypes.STRING,
        //     render: item => (item.message)
        //   },
        //   {
        //     label: t.translate('words.createdBy'),
        //     searchable: true,
        //     sortable: true,
        //     key: 'createdBy',
        //     type: TableWrapper.dataTypes.STRING,
        //     render: item => (item.createdBy)
        //   },
        //   {
        //     label: t.translate('words.createdDate'),
        //     searchable: true,
        //     sortable: true,
        //     key: 'createdDate',
        //     type: TableWrapper.dataTypes.STRING,
        //     render: item => (item.createdDate)
        //   },
        //   {
        //     label: t.translate('words.lastModifiedBy'),
        //     searchable: true,
        //     sortable: true,
        //     key: 'lastModifiedBy',
        //     type: TableWrapper.dataTypes.STRING,
        //     render: item => (item.lastModifiedBy)
        //   },
        //   {
        //     label: t.translate('words.lastModifiedDate'),
        //     searchable: true,
        //     sortable: true,
        //     key: 'lastModifiedDate',
        //     type: TableWrapper.dataTypes.STRING,
        //     render: item => (item.lastModifiedDate)
        //   },
        // ]}
        actions={actions}
        itemActions={[
          new TableWrapper.action(`${t.translate('words.BukaSpt')}`, 'mdi mdi-import', (item) => {
            localStorage.setItem('spt', JSON.stringify(item))
            if (AppMode.onPremise) {
              window.location.href = `/#/onpremise/${basePath}/open/${item.id}/dashboard`
            } else {
              window.location.href = `${basePath}/open/${item.id}/dashboard`
            }
            //history.push(`${basePath}/open/${item.id}/dashboard`)
          }, true),
          new TableWrapper.action(`${t.translate('words.historySpt')}`, 'mdi mdi-history', (item) => {
            localStorage.setItem('spt', JSON.stringify(item))
            history.push(`${basePath}/${item.id}/history`)
          })
        ]}
        showCommandbar={showCommandbar}
        showFilterPeriod={false}
      />
    </>
  )
}

export default inject('modalStore', 'authStore')(observer(DaftarSptArchive))
