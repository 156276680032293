import CrudService from '../../services/crudService';
import { http } from '../../libs/react-mpk/services';
import AppMode from '../../AppMode'

let service = null
let serviceUrl = '/bp/manage/ss2'
let serviceObject = '/bp/object'
if(AppMode.onPremise){
  service = new CrudService('/api/eunifikasi/bp/manage/ss2');
  serviceUrl = '/api/eunifikasi/bp/manage/ss2'
  serviceObject = '/api/eunifikasi/bp/object'
} else {
  service = new CrudService('/bp/manage/ss2');
}

service.exportCurrent = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/export`,
    data
  })
}

service.exportAll = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/export/search`,
    query
  })
}

service.getTaxObjectCode = async(type)=> {
  return http.request({
    method: http.methods.GET,
    url: `${serviceObject}?size=400`,
  })
}

service.history = async(id) => {
  return http.request({
    method : http.methods.GET,
    url : `${serviceUrl}/history/${id}`
  })
}

service.historyDelete = async(id) => {
  return http.request({
    method : http.methods.GET,
    url : `${serviceUrl}/delete-logs/${id}`
  })
}

service.sptSummary = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/spt/summary`,
    query
  })
}

service.summary = async(query) => {
  return http.request({
    method : http.methods.GET,
    url : `${serviceUrl}/summary`,
    query
  })
}

service.getReupload = async(id)=> {
  return http.request({
    method : http.methods.GET,
    url : `${serviceUrl}/reupload/${id}`,
  })
}

service.getOrganization = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `/core/host/organization`,
    query
  })
}

service.getAppInfo = async (query)=> {
  return http.request({
    method: http.methods.GET,
    url: `/api/application-info`,
    query
  })
}

//Tambahan Uci
service.forceFinish = async(id,query)=> {
  return http.request({
    method: http.methods.PUT,
    url: `/bp/manage/fix/case/spt-finish-bp-status-force-finish?type=dn&ids=${id}`,
  })
}

service.forceCompleted = async(id,query)=> {
  return http.request({
    method: http.methods.PUT,
    url: `/bp/manage/fix/case/bp-status-created-force-completed?type=dn&ids=${id}`,
  })
}

service.forceCompletedFromUpdate = async(id,query)=> {
  return http.request({
    method: http.methods.PUT,
    url: `/bp/manage/fix/case/bp-status-updating-force-completed?type=dn&ids=${id}`,
  })
}
//Tambahan Uci

export default service;