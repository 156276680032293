import React, { useEffect, useState, useCallback, useMemo } from "react";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import Modal from "../../libs/react-mpk/components/Modal";
import t from "counterpart";
import service from "./ImportBp.service";
import moment from "moment";
import { toast } from "../../libs/react-mpk/services";
import { inject, observer } from "mobx-react";
import { autorun, toJS } from "mobx";
import {
  Chip,
  ListItem,
  List,
  Button,
  Dialog,
  DialogHeader,
  DialogContent,
  DialogTitle,
  FontIcon,
} from "react-md";
import ErrorService from "./../../services/errorService";
import { format } from "../../libs/react-mpk/services/number.service";
import errorService from "./../../services/errorService";
import iziToast from "izitoast";
import LoadingOverlay from "react-loading-overlay";
import bluebird from "bluebird";
import errorServiceGet from "../../services/errorServiceGet";
import DataForm from "../../libs/react-mpk/components/DataForm";
import { inputTypes } from "../../libs/react-mpk/config/constant";
import { FormWrapper } from "../../libs/react-mpk/wrapper";
import { useDropzone } from "react-dropzone";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import AppMode from "../../AppMode";

const ImportBpCsv = ({
  className = "",
  showCommandbar = true,
  history,
  match,
  modalStore,
  temporaryStore,
}) => {
  const basePath = `log-csv`;
  const [ploading, setPloading] = useState({ loading: false, message: "" });
  const [data, setData] = useState([]);
  const baseId = `mod-log-csv`;
  let [search, setSearch] = useState({});
  let [dialog, setDialog] = useState(false);
  let [dialogTemplate, setDialogTemplate] = useState(false);
  let [dialogItem, setDialogItem] = useState({});
  const [exportDialog, setExportDialog] = useState(false);
  let [dialogItemCsv, setDialogItemCsv] = useState({});
  let [dialogCsv, setDialogCsv] = useState(false);
  const [myFiles, setMyFiles] = useState([]);
  const templatePassphrase = [
    ["npwp;password"],
    ["010016681093000;testing12"]
  ];
  const [pencarian, setPencarian] = useState([]) //Tambahan uci

  var dalamSpt = false
  try {
    if (window.location.href.indexOf('open') != -1) {
      dalamSpt = true
    } else {
      dalamSpt = false
    }
  } catch(e){}

  const baseStyle = {
    flex: 1,
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    padding: "110px",
    height: "300px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };

  const addCsv = async (item) => {
    setDialogItemCsv(item);
    setDialogCsv(true);
  };
  const onDrop = useCallback(
    (acceptedFiles) => {
      setMyFiles([...myFiles, ...acceptedFiles]);
    },
    [myFiles]
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    maxFiles: "1",
    accept: ".csv",
  });
  const removeAll = () => {
    setMyFiles([]);
  };
  const removeFile = (file) => () => {
    const newFiles = [...myFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setMyFiles(newFiles);
  };

  const files = myFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes{" "}
      <Button
        class="btn-sm pull-left"
        onClick={removeFile(file)}
        buttonType="icon"
        style={{ marginTop: "5px" }}
      >
        <FontIcon className="mdi mdi-trash-can-outline"></FontIcon>
      </Button>
    </li>
  ));
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(onDrop ? acceptStyle : {}),
      ...(removeFile ? rejectStyle : {}),
    }),
    [removeFile, onDrop]
  );

  useEffect(() => {
    if (localStorage.getItem(`${baseId}-saved-search`)) {
      setSearch(JSON.parse(localStorage.getItem(`${baseId}-saved-search`)));
    }
  }, []);

  async function importDataExec(item) {}

  async function importData(item) {
    setDialogItem(item);
    setDialog(true);
  }

  const multiSearch = (values) =>{
    try{
      var formData = Object.assign({}, values)
      if(values){
        Object.keys(values).map((d)=>{
          var kolom = d.split('.')[0]
          var params = d.split('.')[1]
          if(kolom && params){
            if(params === 'in'){
              if(kolom === "rev" || kolom === 'year' || kolom === 'type' || kolom === 'status' || kolom === 'refFileId' || kolom === "npwpProfile"){
                d = kolom+".equals"
                formData[d] = values[kolom+"."+params]
                delete formData[kolom+"."+params]
              }else{
                d = kolom+".contains"
                formData[d] = formData[kolom+"."+params]
                delete formData[kolom+"."+params]
              }
            }
          }
        })

        setPencarian(formData)
      }
    }catch(e){}
  }

  async function hapusExc(item) {
    try {
      var proses = t.translate("words.hapus");
      var selected = JSON.parse(TableWrapper.getSelected(baseId));
      var selectedData = [];
      selected.map((d) => {
        let x = data[d];
        if (x.status === "FAILED") {
          selectedData.push(x);
        }
      });

      if (selectedData.length === 0) {
        iziToast.error({
          title: "Gagal Hapus",
          message: `Hanya log dengan status FAILED yang dapat dihapus.`,
        });

        return;
      }

      setPloading({
        loading: true,
        message: `${t.translate("words.prosesData")}`,
      });
      var errors = [];
      bluebird.mapSeries(selectedData, async (di, di2) => {
        setTimeout(async () => {
          try {
            let proccedThis = await service.delete(di.id);
          } catch (e) {
            errors.push(await errorServiceGet(e, di, di2));
          }
          setPloading({
            loading: true,
            message: `${t.translate("words.prosesData")} ${di2 + 1}/${
              selectedData.length
            }`,
          });
          if (di2 + 1 == selectedData.length) {
            setPloading({ loading: false, message: `` });
            if (errors.length == 0) {
              iziToast.success({
                title: proses,
                message: `${t.translate(
                  "words.proses"
                )} ${proses} ${t.translate("words.prosesDone")}`,
              });
            } else {
              modalStore.showInfo({
                title: "",
                children: (
                  <List>
                    {errors.map((er) => {
                      return (
                        <ListItem
                          primaryText={""}
                          secondaryText={er}
                        ></ListItem>
                      );
                    })}
                  </List>
                ),
              });
            }
            try {
              var checkboxRoot = document.getElementById(
                `${baseId}-selectable-rows-root-checkbox`
              );
              if (checkboxRoot.checked) {
                if (checkboxRoot.getAttribute("aria-checked")) {
                  checkboxRoot.click();
                  checkboxRoot.click();
                } else {
                  checkboxRoot.click();
                }
              }
              document.getElementsByClassName("mdi-reload")[0].click();
            } catch (e) {}
          }
        }, 1000 * di2);
      });
    } catch (e) {
      errorService(e);
    }
  }

  async function hapus(item) {
    modalStore.showConfirm({
      title: t.translate("words.delete"),
      children: t.translate("words.hapusIni"),
      onSubmit: (callback) => {
        hapusExc(item);
        callback();
      },
    });
  }

  async function downloadTemplate() {
    setDialogTemplate(true);
    // try {
    //   let res = await service.download({
    //     type: 'BP'
    //   })
    //   window.open(res.data.data.url)
    // } catch(e){
    //   errorService(e)
    // }
  }

  async function downloadTemplateFile(item) {
    setPloading({
      loading: true,
      message: `${t.translate("words.prosesData")}`,
    });
    try {
      let res = await service.downloadFile(item.id);
      if (res.data.data.url) {
        window.open(res.data.data.url);
        setPloading({ loading: false, message: `` });
      }
    } catch (e) {
      setPloading({ loading: false, message: `` });
      errorService(e);
    }
  }

  var exportData = () => {
    setExportDialog(true);
  };

  var exportSelected = () => {};

  var exportAll = () => {};

  async function downloadLogExc(item) {
    setPloading({
      loading: true,
      message: `${t.translate("words.prosesData")}`,
    });
    setTimeout(async () => {
      try {
        let res = await service.downloadLog(item.id);
        if(res.data.status == 1){
          setPloading({ loading: false, message: `` });
          window.open(res.data.data.url);
        } else {
          setPloading({ loading: false, message: `` });
          iziToast.info({
            message: res.data.message
          })
        }
      } catch (e) {
        setPloading({ loading: false, message: `` });
        errorService(e);
      }
    }, 1000);
  }

  async function downloadLog(item) {
    modalStore.showConfirm({
      title: t.translate("words.importLog"),
      children: t.translate("words.confirmSptDownload"),
      onSubmit: (callback) => {
        downloadLogExc(item);
        callback();
      },
    });
  }

  var optionsDownloadTemplate = [];
  optionsDownloadTemplate = [
    { label: "Penandatangan (Signer)", value: "SIGNER" },
    { label: "BP", value: "BP" },
    { label: "Setor Sendiri", value: "SSP-SS" },
    { label: "SSP/PBK", value: "SSP" },
    // { label: "DOSS/DOPP", value: "DOSSPP" },
    { label: "Lawan Transaksi Luar Negeri", value: "LT-LN" },
    { label: "Lawan Transaksi Dalam Negeri", value: "LT-DN" },
    { label: "E-Billing ESSP", value: "EBILLING_ESSP" },
    // { label: 'MIGRASI EBUPOT', value: 'MIGRASI_EBUPOT' },
    { label: 'Contoh E-Billing ESSP', value: 'EBILLING_ESSP_EXAMPLE'},
    // { label: 'Lisensi Custom Feature', value: 'CUSTOM_FEATURE_LICENSE'},
  ]
  if(AppMode.onPremise){
    optionsDownloadTemplate = [
      { label: "SIGNER", value: "SIGNER" },
      { label: "BP", value: "BP" },
      { label: "Setor Sendiri", value: "SSP-SS" },
      // { label: "DOSS/DOPP", value: "DOSSPP" },
      { label: "SSP/PBK", value: "SSP" },
      { label: "Lawan Transaksi Luar Negeri", value: "LT-LN" },
      { label: "Lawan Transaksi Dalam Negeri", value: "LT-DN" },
      // { label: 'E-Billing ESSP', value: 'EBILLING_ESSP' },
      // { label: 'MIGRASI EBUPOT', value: 'MIGRASI_EBUPOT' },
      // { lebel: 'Contoh E-Billing ESSP', value: 'EBILLING_IMPORT_ESSP_EUNIFIKASI_EXAMPLE'}
    ];
  }

  var optionsDownloadTemplateImport = [];
  optionsDownloadTemplateImport = [
    { label: "Penandatangan (Signer)", value: "SIGNER" },
    { label: "BP", value: "BP" },
    { label: "Setor Sendiri", value: "SSP-SS" },
    { label: "SSP/PBK", value: "SSP" },
    // { label: "DOSS/DOPP", value: "DOSSPP" },
    { label: "Lawan Transaksi Luar Negeri", value: "LT-LN" },
    { label: "Lawan Transaksi Dalam Negeri", value: "LT-DN" },
    { label: "E-Billing ESSP", value: "EBILLING_ESSP" },
    // { label: 'MIGRASI EBUPOT', value: 'MIGRASI_EBUPOT' },
    { label: 'Contoh E-Billing ESSP', value: 'EBILLING_ESSP_EXAMPLE'},
    { label: 'Lisensi Custom Feature', value: 'CUSTOM_FEATURE_LICENSE'},
  ]
  if(AppMode.onPremise){
    optionsDownloadTemplateImport = [
      { label: "SIGNER", value: "SIGNER" },
      { label: "BP", value: "BP-CSV" },
      { label: "Setor Sendiri", value: "SSP-SS" },
      // { label: "DOSS/DOPP", value: "DOSSPP" },
      { label: "SSP/PBK", value: "SSP" },
      { label: "Lawan Transaksi Luar Negeri", value: "LT-LN" },
      { label: "Lawan Transaksi Dalam Negeri", value: "LT-DN" },
      // { label: 'E-Billing ESSP', value: 'EBILLING_ESSP' },
      // { label: 'MIGRASI EBUPOT', value: 'MIGRASI_EBUPOT' },
      // { lebel: 'Contoh E-Billing ESSP', value: 'EBILLING_IMPORT_ESSP_EUNIFIKASI_EXAMPLE'}
    ];
  }

  // Tambahan Uci
  async function reuploadExc(item) {
    try {
      var proses = t.translate('words.reuploadBp')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d) => selectedData.push(data[d].id))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      if (selectedData.length > 0) {
        setTimeout(async() => {
          try {
            let proccedThis = await service.reupload(selectedData)
            setPloading({ loading: false, message: `` })
            iziToast.success({
              title: proses,
              message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
            })
          } catch (e) {
            setPloading({ loading: false, message: `` })
            errorService(e)
          }
          document.getElementsByClassName('mdi-reload')[0].click()
        }, 1000);
      } else {
        setPloading({ loading: false, message: `` })
        iziToast.warning({
          title : proses,
          message : t.translate('words.recheckSelectedData')
        })
      }
    } catch (e) {
      errorService(e)
    }
  }

  async function reupload(item) {
    modalStore.showConfirm({
      title: t.translate('words.reuploadBp'),
      children: t.translate('words.reuploadBpConfirm'),
      onSubmit: (callback) => {
        reuploadExc(item)
        callback()
      }
    })
  }
  // Tambahan Uci

  return (
    <>
      <LoadingOverlay
        style={{
          position: "inherit",
          background: "red",
        }}
        active={ploading.loading}
        spinner
        text={ploading.message}
      ></LoadingOverlay>
      <Dialog
        disableFocusOnMount={true}
        initialFocus="csv-dialog"
        id="csv-dialog"
        onFocus={() => {}}
        visible={dialogCsv}
        onRequestClose={() => {
          setDialogCsv(false);
        }}
        style={{
          width: 1024,
          margin: "0px !important",
        }}
        width={1024}
        aria-labelledby="csv-dialog"
      >
        <DialogContent>
          <FormWrapper
            style={{
              margin: "0px !important",
              width: "100%",
              maxWidth: "100%",
            }}
            defaultData={[]}
            action={{}}
            hintMessage={t.translate("words.uploadCsvInfo")}
            showCommandbar={false}
            baseId={baseId}
            title={t.translate("modules.organization.title")}
            definitions={[
              {
                render: (
                  <section
                    className="container"
                    style={{ width: "100%", maxWidth: "100%" }}
                  >
                    <div {...getRootProps({ className: "dropzone", style })}>
                      <input {...getInputProps()} />
                      <p>
                        Drag 'n' drop some files here, or click to select files
                      </p>
                    </div>
                    {files.length > 0 && (
                      <Button
                        onClick={removeAll}
                        themeType="outline"
                        theme="primary"
                        style={{ marginTop: "20px" }}
                      >
                        Remove All
                      </Button>
                    )}
                    <aside>
                      <h4>Files</h4>
                      <ul key="values">{files}</ul>
                    </aside>
                  </section>
                ),
              },
            ]}
            onSubmit={async (value, callback) => {
              try {
                var proses = t.translate("words.import");
                setPloading({
                  loading: true,
                  message: `${t.translate("words.prosesData")}`,
                });
                if (myFiles.length > 0) {
                  const reader = new FileReader();
                  var formData = new FormData();

                  formData.append("file", myFiles[0]);
                  formData.append("type", "CERT");

                  var res = await service.addCsv(formData);

                  setMyFiles([]);
                  iziToast.success({
                    title: proses,
                    message: `${t.translate(
                      "words.proses"
                    )} ${proses} ${t.translate(
                      "words.prosesDoneLogImportCsv"
                    )}`,
                  });
                  callback("", false, false, false);
                  setPloading({ loading: false, message: "" });
                  setDialogCsv(false);
                  history.push(
                    `/product/index/log-csv`
                  );
                } else {
                  iziToast.warning({
                    title: proses,
                    message: `${t.translate(
                      "words.proses"
                    )} ${proses} ${t.translate("words.prosesFail")}`,
                  });
                  setPloading({ loading: false, message: proses });
                  callback("", false, false, false);
                  return;
                }
              } catch (e) {
                setMyFiles([]);
                setPloading({ loading: false, message: proses });
                setDialogCsv(false);
                errorService(e);
                callback("", false, false, false);
              }
            }}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        visible={exportDialog}
        disableFocusOnMount={() => {}}
        onRequestClose={() => {
          setExportDialog(false);
        }}
        style={{
          width: 800,
        }}
      >
        <DialogHeader>
          <DialogTitle>{t.translate("words.exportData")}</DialogTitle>
        </DialogHeader>
        <DialogContent>
          <Button
            themeType="outline"
            theme="primary"
            style={{ marginRight: "12px" }}
            onClick={() => exportSelected()}
          >
            {t.translate("words.exportDataSelected")}
          </Button>
          <Button themeType="outline" onClick={() => exportAll()}>
            {t.translate("words.exportDataAll")}
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog
        disableFocusOnMount={true}
        initialFocus="file-import-excel-dialog"
        id="file-import-excel-dialog"
        onFocus={() => {}}
        visible={dialog}
        onRequestClose={() => {
          setDialog(false);
        }}
        style={{
          width: 1024,
          margin: "0px !important",
        }}
        width={1024}
        aria-labelledby="file-import-excel-dialog"
      >
        <DialogContent>
          <FormWrapper
            style={{
              margin: "0px !important",
              width: "100%",
              maxWidth: "100%",
            }}
            defaultData={{
              npwp: dialogItem.npwp,
              isValidateFirst : true,
            }}
            hintMessage={
              "Pastikan isi File Import anda sudah benar dan format file sesuai dengan template file.csv !"
            }
            showCommandbar={false}
            baseId="mod-file-import-excel-form"
            title={t.translate("modules.organization.title")}
            definitions={[
              {
                inputType: inputTypes.SELECT,
                label: t.translate("words.type"),
                key: "type",
                validation: "required",
                options: optionsDownloadTemplateImport,
              },
              {
                inputType: inputTypes.FILE_INPUT,
                label: t.translate("words.file"),
                key: "file",
                type: "file",
                validation: "required",
                multiple: true,
                multiline: true,
                accept: ".csv",
              },
              {
                inputType : inputTypes.CHECKBOX,
                label : t.translate('words.isValidateFirst'),
                key : 'isValidateFirst',
                type : 'text',
              }
            ]}
            onSubmit={async (values, callback) => {
              try {
                var proses = t.translate("words.import");
                var selected = JSON.parse(TableWrapper.getSelected(baseId));
                var selectedData = values.file;
                selected.map((d) => selectedData.push(data[d]));
                // console.log(values.file);
                setPloading({
                  loading: true,
                  message: `${t.translate("words.prosesData")}`,
                });
                var errors = [];
                bluebird.mapSeries(values.file, async (di, di2) => {
                  setTimeout(async () => {
                    try {
                      var formData = new FormData();
                      formData.append("file", di.file);
                      formData.append("type", values.type);
                      formData.append('isValidateFirst', values.isValidateFirst)
                      await service.importData(formData);
                    } catch (e) {
                      errors.push(await errorServiceGet(e, di, di2));
                    }
                    setPloading({
                      loading: true,
                      message: `${t.translate("words.prosesData")} ${di2 + 1}/${
                        selectedData.length
                      }`,
                    });
                    if (di2 + 1 == selectedData.length) {
                      setPloading({ loading: false, message: `` });
                      if (errors.length == 0) {
                        iziToast.success({
                          title: proses,
                          message: `${t.translate(
                            "words.proses"
                          )} ${proses} ${t.translate("words.prosesDone")}`,
                        });
                      } else {
                        modalStore.showInfo({
                          title: "",
                          children: (
                            <List>
                              {errors.map((er) => {
                                return (
                                  <ListItem
                                    primaryText={""}
                                    secondaryText={er}
                                  ></ListItem>
                                );
                              })}
                            </List>
                          ),
                        });
                      }
                      setDialog(false);
                      try {
                        var checkboxRoot = document.getElementById(
                          `${baseId}-selectable-rows-root-checkbox`
                        );
                        if (checkboxRoot.checked) {
                          if (checkboxRoot.getAttribute("aria-checked")) {
                            checkboxRoot.click();
                            checkboxRoot.click();
                          } else {
                            checkboxRoot.click();
                          }
                        }
                        document
                          .getElementsByClassName("mdi-reload")[0]
                          .click();
                      } catch (e) {}
                    }
                  }, 1000 * di2);
                });
              } catch (e) {
                setDialog(false);
                errorService(e);
              }
            }}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        disableFocusOnMount={true}
        initialFocus="file-import-template-dialog"
        id="file-import-template-dialog"
        onFocus={() => {}}
        visible={dialogTemplate}
        onRequestClose={() => {
          setDialogTemplate(false);
        }}
        style={{
          width: 1024,
          margin: "0px !important",
        }}
        width={1024}
        aria-labelledby="file-import-template-dialog"
      >
        <DialogContent>
          <FormWrapper
            style={{
              margin: "0px !important",
              width: "100%",
              maxWidth: "100%",
            }}
            defaultData={{
              npwp: dialogItem.npwp,
            }}
            showCommandbar={false}
            baseId="mod-file-import-excel-form"
            title={t.translate("modules.organization.title")}
            definitions={[
              {
                inputType: inputTypes.SELECT,
                label: t.translate("words.type"),
                key: "type",
                validation: "required",
                options: [
                  { label: "Passphrase (CERT)", value: "passphrase" },
                  { label: "Penandatangan (Signer)", value: "SIGNER" },
                  { label: "BP", value: "BP-CSV" },
                  { label: "Setor Sendiri", value: "SSP-SS" },
                  { label: "SSP/PBK", value: "SSP" },
                  // { label: "DOSS/DOPP", value: "DOSSPP" },
                  { label: "Lawan Transaksi Luar Negeri", value: "LT-LN" },
                  { label: "Lawan Transaksi Dalam Negeri", value: "LT-DN" },
                  { label: "E-Billing ESSP", value: "EBILLING_ESSP" },
                  // { label: 'MIGRASI EBUPOT', value: 'MIGRASI_EBUPOT' },
                  { label: 'Contoh E-Billing ESSP', value: 'EBILLING_ESSP_EXAMPLE'},
                  // { label: 'Lisensi Custom Feature', value: 'CUSTOM_FEATURE_LICENSE'},
                ],
              },
            ]}
            submitLabel={t.translate("words.download")}
            onSubmit={async (values, callback) => {
              try {
                setPloading({
                  loading: true,
                  message: `${t.translate("words.prosesData")}`,
                });
                if(values.type == "passphrase"){
                  let csvContent = "data:text/csv;charset=utf-8," 
                  + templatePassphrase.map(e => e.join(",")).join("\n");
                  var encodedUri = encodeURI(csvContent);
                  var link = document.createElement("a");
                  link.setAttribute("href", encodedUri);
                  link.setAttribute("download", "template_import_passphrase.csv");
                  document.body.appendChild(link); // Required for FF

                  link.click()
                  setPloading({ loading: false, message: `` });
                }else{
                  let res = await service.download({
                    type: values.type,
                  });
                  setPloading({ loading: false, message: `` });
                  window.open(res.data.data.url);
                }
                setDialogTemplate(false);
                callback("", true, false, false, false);
              } catch (e) {
                setPloading({ loading: false, message: `` });
                setDialogTemplate(false);
                errorService(e);
                callback("", true, false, false, false);
              }
            }}
          />
        </DialogContent>
      </Dialog>
      <TableWrapper
        useFilter={false}
        baseId={baseId}
        title={t.translate("menu.log-csv")}
        className={className}
        selectable={true}
        defaultData={[]}
        defaultSortBy="createdDate"
        setPage={search.page} onFetchData={(params) => {
          // params['type.equals'] = 'BP'
          return new Promise(async (resolve, reject) => {
            params = {...params,...search}
            try {
              let getPage = null;
              if (window.location.href.indexOf("open") != -1) {
                var spt = JSON.parse(localStorage.getItem("spt"));
                params.npwpProfile = spt.npwp;
                getPage = await service.getBySpt(params);
              } else {
                getPage = await service.get(params);
              }
              getPage.headers["x-pagination-count"] =
                getPage.headers["x-total-count"];
              setData(getPage.data);
              resolve(getPage);
            } catch (e) {
              resolve({ data: [] });
              errorService(e);
            }
          });
        }}
        sideHeader={
          <DataForm
            baseId={`${baseId}-search`}
            defaultData={pencarian}
            submitIconClassName="mdi mdi-magnify"
            submitLabel={`${t.translate("words.cari")}`}
            additionalAction={[
              {
                show: true,
                render: () => (
                  <Button
                    style={{ marginRight: 15 }}
                    onClick={() => {
                      setSearch({});
                      var newsearch = { page: 0 }; setSearch(newsearch); localStorage.removeItem(`${baseId}-saved-search`); localStorage.removeItem(`${baseId}-query`);;
                      setPencarian(newsearch)
                      setTimeout(() => {
                        document
                          .getElementsByClassName("mdi-reload")[0]
                          .click();
                      }, 1000);
                    }}
                  >
                    {t.translate("words.hapus")}
                  </Button>
                ),
              },
            ]}
            onSubmit={(values, callback) => {
              var formData = Object.assign({}, values)
              if (values["createdDate.greaterOrEqualThan"])
                values["createdDate.greaterOrEqualThan"] = new Date(
                  values["createdDate.greaterOrEqualThan"]
                );
              if (values["createdDate.lessOrEqualThan"])
                values["createdDate.lessOrEqualThan"] = new Date(
                  values["createdDate.lessOrEqualThan"]
                );

              if(formData['type.equals']){
                if(Object.values(formData['type.equals']).length > 0){
                  var stringIn = ""
                  Object.values(formData['type.equals']).map((d) => {
                    stringIn += d.value + ","
                  })
                  formData['type.equals'] = values['type.equals']
                  values['type.in'] = stringIn
                  delete values['type.equals']
                }
              }
              
              if((values['originalFilename.contains']+"").indexOf(',') != -1){
                values['originalFilename.in'] = values['originalFilename.contains']
                delete values['originalFilename.contains']
              }
              setPencarian(formData)
              setSearch(values);
              localStorage.setItem(
                `${baseId}-saved-search`,
                JSON.stringify(values)
              );
              setTimeout(() => {
                document.getElementsByClassName("mdi-reload")[0].click();
                callback("", false, false);
              }, 1000);
            }}
            definitions={[
              {
                render: (
                  <div className="mpk-data-filter">
                    <div
                      className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}
                    >
                      <p>{t.translate(`words.pencarian`)}</p>
                    </div>
                  </div>
                ),
              },
              {
                inputType: inputTypes.REACT_SELECT,
                label: t.translate("words.type"),
                key: "type.equals",
                options: optionsDownloadTemplate,
                isMulti : true
              },
              {
                render: (
                  <div className="message mpk-font weight-B mpk-flex align-center" style={{ marginTop:'-24px',width: '100%', fontSize: 10, padding: '8px 0', marginBottom: '16px', color:'hsl(0, 0%, 50%)' }}>
                  <FontIcon
                    iconClassName="mdi mdi-information"
                    style={{fontSize:10, color:'hsl(0, 0%, 50%)'}}
                    className="mpk-margin-S margin-right"
                  />
                  {t.translate('words.infoMultiple')}
                </div>
                )
              },
              // {
              //   inputType: inputTypes.INPUT_MASK_NUMBER,
              //   label: t.translate(`words.sptNpwp`),
              //   key: "sptNpwp.contains",
              //   type: "text",
              //   mask: "##.###.###.#-###.###",
              //   maskChar: "_",
              //   maskValue: "string",
              //   width: "100%",
              // },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.filename`),
                key: "originalFilename.contains",
                type: "text",
              },
              {
                render: (
                  <div className="message mpk-font weight-B mpk-flex align-center" style={{ marginTop:'-24px',width: '100%', fontSize: 10, padding: '8px 0', marginBottom: '16px', color:'hsl(0, 0%, 50%)' }}>
                  <FontIcon
                    iconClassName="mdi mdi-information"
                    style={{fontSize:10, color:'hsl(0, 0%, 50%)'}}
                    className="mpk-margin-S margin-right"
                  />
                  {t.translate('words.infoMultiple')}
                </div>
                )
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.message`),
                key: "message.contains",
                type: "text",
              },
              // {
              //   label: t.translate('words.progress'),
              //   searchable: true,
              //   sortable : true,
              //   width : 'auto',
              //   key: 'progress',
              //   type: TableWrapper.dataTypes.NUMBER,
              //   render: (item) => {
              //     return (
              //       <Progress
              //         percent={item.progress}
              //       />
              //     )
              //   }
              // },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.createdBy`),
                key: "createdBy.contains",
                type: "text",
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.lastModifiedBy`),
                key: "lastModifiedBy.contains",
                type: "text",
              },
              // {
              //   inputType: inputTypes.INPUT,
              //   label: t.translate(`words.tanggal1`),
              //   key: 'createdDate.greaterOrEqualThan',
              //   type: 'text'
              // },
              // {
              //   inputType: inputTypes.INPUT,
              //   label: t.translate(`words.tanggal2`),
              //   key: 'createdDate.lessOrEqualThan',
              //   type: 'text'
              // },
            ]}
          />
        }
        columns={[
          {
            label: t.translate("words.success"),
            searchable: true,
            sortable: true,
            key: "success",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => {
              if (item.success) {
                return (
                  <Chip style={{ background: "#8BC34A", color: "white" }}>
                    {t.translate(`words.ya`)}
                  </Chip>
                );
              } else {
                return (
                  <Chip style={{ background: "#F44336", color: "white" }}>
                    {t.translate(`words.tidak`)}
                  </Chip>
                );
              }
            },
          },
          {
            label: t.translate('words.status'),
            searchable: true,
            sortable : true,
            key: 'status',
            type: TableWrapper.dataTypes.STRING,
            render: item => {
              if(item.status && item.status == "COMPLETED"){
                return <Chip style={{ background: "#049e51", color: 'white' }}>{(item.status+"").toUpperCase()}</Chip>
              } 
              if(item.status && item.status == "FAILED"){
                return <Chip style={{ background: "#F44336", color: 'white' }}>{(item.status+"").toUpperCase()}</Chip>
              }
              if(item.status && item.status == "PROCESS"){
                return <Chip style={{ background: "#FFC108", color: 'white' }}>{(item.status+"").toUpperCase()}</Chip>
              }else {
                return <Chip>{(item.status+"").toUpperCase()}</Chip>
              } 
            }
          },
          {
            label: t.translate('words.progress'),
            searchable: true,
            sortable : true,
            key: 'progress',
            type: TableWrapper.dataTypes.NUMBER,
            render: (item) => {
              if(item.progress > 100) item.progress = 100
              return (
                <div className='progress'>
                  {item.status == "FAILED" &&
                    <Progress 
                      percent={100}
                      strokeWidth ={6}
                      status = "error"
                    />
                  }
                  {item.status !== "FAILED" &&
                    <Progress 
                      percent={item.progress}
                      strokeWidth ={6}
                      theme={{
                        success: {
                          color: '#049e51',
                          trailColor : '#e0e0e0'
                        },
                        active: {
                          color: '#FFC108',
                          trailColor : '#e0e0e0'
                        },
                        default: {
                          color: 'blue',
                          trailColor : '#e0e0e0'
                        }
                      }}
                    />
                  }
                </div>
              )
            }
          },
          {
            label: t.translate('words.counter'),
            searchable: true,
            sortable : true,
            key: 'counter',
            type: TableWrapper.dataTypes.STRING,
            render: item => {
              try {
                return item.counter + " / " + item.total
              } catch(e){
                return ""
              }
            }
          },
          {
            label: t.translate("words.type"),
            searchable: true,
            sortable: true,
            key: "type",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.type,
          },
          {
            label: t.translate("words.npwp"),
            searchable: true,
            sortable: true,
            key: "npwp",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.npwp,
          },
          {
            label: t.translate("words.filename"),
            searchable: true,
            sortable: true,
            key: "originalFilename",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.originalFilename,
          },
          {
            label: t.translate("words.md5"),
            searchable: true,
            sortable: true,
            key: "md5",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.md5,
          },
          {
            label: t.translate("words.fileSize"),
            searchable: true,
            sortable: true,
            key: "fileSize",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.fileSize,
          },
          {
            label: t.translate("words.message"),
            searchable: true,
            sortable: true,
            key: "message",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.message,
          },
          {
            label: t.translate("words.createdBy"),
            searchable: true,
            sortable: true,
            key: "createdBy",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.createdBy,
          },
          {
            label: t.translate("words.createdDate"),
            searchable: true,
            sortable: true,
            key: "createdDate",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.createdDate,
          },
          {
            label: t.translate("words.lastModifiedBy"),
            searchable: true,
            sortable: true,
            key: "lastModifiedBy",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.lastModifiedBy,
          },
          {
            label: t.translate("words.lastModifiedDate"),
            searchable: true,
            sortable: true,
            key: "lastModifiedDate",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.lastModifiedDate,
          },
        ]}
        actions={[
          new TableWrapper.action(
            `${t.translate(`words.reuploadBp`)}`,
            "mdi mdi-progress-upload",
            (item) => {
              reupload(item);
            },
            true
          ),
          // new TableWrapper.action(
          //   `${t.translate(`words.uploadCsv`)}`,
          //   "mdi mdi-file-import",
          //   (item) => {
          //     addCsv(item);
          //   },
          //   true
          // ),
          // new TableWrapper.action(
          //   `${t.translate("words.downloadTemplate")}`,
          //   "mdi mdi-file-delimited",
          //   (item) => {
          //     downloadTemplate(item);
          //   },
          //   true
          // ),
          // new TableWrapper.action(
          //   `${t.translate("words.import")}`,
          //   "mdi mdi-file-import",
          //   (item) => {
          //     importData(item);
          //   },
          //   true
          // ),
          // new TableWrapper.action(
          //   "Delete",
          //   "mdi mdi-delete",
          //   (item) => {
          //     hapus(item);
          //   },
          //   true
          // ),
        ]}
        itemActions={[
          new TableWrapper.action(
            `${t.translate("words.importDetail")}`,
            "mdi mdi-history",
            (item) => {
              window.bunifikasiImportFilename = item.originalFilename
              history.push(`${dalamSpt ? 'import' : 'log-csv'}/${item.id}/import-detail-csv`);
            }
          ),
          new TableWrapper.action(
            `${t.translate("words.download")}`,
            "mdi mdi-download",
            (item) => {
              downloadTemplateFile(item);
            },
            true
          ),
          new TableWrapper.action(
            `${t.translate("words.importLog")}`,
            "mdi mdi-download",
            (item) => {
              downloadLog(item);
            }
          ),
        ]}
        showCommandbar={showCommandbar}
        showFilterPeriod={false}
      />
    </>
  );
};
export default inject("temporaryStore", "modalStore")(observer(ImportBpCsv));
