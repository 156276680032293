import CrudService from '../../services/crudService'
import { http } from '../../libs/react-mpk/services';
import axios from 'axios'

let service = new CrudService('/Sertel')

service.uploadSertel = async(data)=> {
    return axios({
      method: http.methods.POST,
      url: `/api/sso/cert/upload`,
      data
    })
}

service.getCertStatus = async(npwp)=> {
  return http.request({
    method: http.methods.GET,
    url: `/core/cert/search?npwp=${npwp}`
  })
}


export default service