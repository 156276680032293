import CrudService from '../../services/crudService';
import { http } from '../../libs/react-mpk/services';
import AppMode from '../../AppMode'

let service = null
let serviceUrl = '/bp/manage/ln'
let serviceObject = '/bp/object'
let serviceNegara = '/master/country'
if(AppMode.onPremise){
  service = new CrudService('/api/eunifikasi/bp/manage/ln');
  serviceUrl = '/api/eunifikasi/bp/manage/ln'
  serviceObject = '/api/eunifikasi/bp/object'
  serviceNegara = '/api/eunifikasi/master/country'
} else {
  service = new CrudService('/bp/manage/ln');
}

service.exportCurrent = async(data, params)=> {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/export`,
    data,
    config: {
      params: params
    }
  })
}

service.exportAll = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/export/search`,
    query
  })
}

service.getTaxObjectCode = async(type)=> {
    return http.request({
      method: http.methods.GET,
      url: `${serviceObject}?size=200&&type.equals=${type}`,
    })
}

service.getNegara = async(name)=> {
  return http.request({
    method: http.methods.GET,
    url: `${serviceNegara}?size=1000`, //Tambahan Uci
    query: {
      'sort': 'name,ASC',
      'name.contains': name
    }
  })
}

service.history = async(id) => {
  return http.request({
    method : http.methods.GET,
    url : `${serviceUrl}/history/${id}`
  })
}

service.historyDelete = async(id) => {
  return http.request({
    method : http.methods.GET,
    url : `${serviceUrl}/delete-logs/${id}`
  })
}

service.postResend = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/resend`,
    data
  })
}

service.getReport = async(id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/report/${id}`
  })
}

service.postReport = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/report`,
    data
  })
}

service.getRequestReport = async(id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/request/report/${id}`
  })
}

service.getReupload = async(id) => {
  return http.request({
    method : http.methods.GET,
    url : `${serviceUrl}/reupload/${id}`
  })
}

service.sptSummary = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/spt/summary`,
    query
  })
}

service.summary = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/summary`,
    query
  })
}

service.sendMail = async(data, email) => {
  return http.request({
    method : http.methods.POST,
    url : `${serviceUrl}/send`,
    data,
    config: {
      params: {
        email: email,
        ...data
      }
    }
  })
}


service.sendMailBulk = async(data, email)=> {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/send/search`,
    data,
    config: {
      params: data
    }
  })
}

service.sendMailBulkOp = async(data, email)=> {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/send/search`,
    query: data,
    // config: {
    //   params: data
    // }
  })
}

service.getOrganization = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `/core/host/organization`,
    query
  })
}

service.reportBulk = async(data, all)=> {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/report/search`,
    query: data
  })
}

service.reportBulkOp = async(data, all)=> {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/report/search`,
    query: data
  })
}

service.getAppInfo = async (query)=> {
  return http.request({
    method: http.methods.GET,
    url: `/api/application-info`,
    query
  })
}

//Tambahan Uci
service.forceFinish = async(id,query)=> {
  return http.request({
    method: http.methods.PUT,
    url: `/bp/manage/fix/case/spt-finish-bp-status-force-finish?type=ln&ids=${id}`,
  })
}

service.forceCompleted = async(id,query)=> {
  return http.request({
    method: http.methods.PUT,
    url: `/bp/manage/fix/case/bp-status-created-force-completed?type=ln&ids=${id}`,
  })
}

service.forceCompletedFromUpdate = async(id,query)=> {
  return http.request({
    method: http.methods.PUT,
    url: `/bp/manage/fix/case/bp-status-updating-force-completed?type=ln&ids=${id}`,
  })
}
//Tambahan Uci
// Tambahan Uci
service.getLogFiles = async (query) => {
  return http.request({
    method : http.methods.GET,
    url : `${serviceUrl}/spt/log/files`,
    query
  })
}

service.syncBp = async(query) => {
  return http.request({
    method: http.methods.GET,
    url: `/bp/sync/bp`,
    query
  })
}

service.setCompleted = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `/bp/manage/ln/set-completed`,
    data
  })
}
// Tambahan Uci

export default service;