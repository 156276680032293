import React, {useEffect, useState} from 'react'
import { inputTypes, formatTypes } from '../../libs/react-mpk/config/constant'
import { clearFormat } from '../../libs/react-mpk/services/number.service'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './OpLogin.service'
import { autorun, toJS } from 'mobx';
import UtilsService from '../../services/utilsService'
import {
  TableContainer,
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Button
} from 'react-md'
import ErrorService from '../../services/errorService'
import izitoast from 'izitoast'
import LoadingOverlay from 'react-loading-overlay'
import errorService from '../../services/errorService'

const OpResetPassword = ({ authStore, envStore, match, history, ...props }) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({loading: true, content: {}})
  const [ploading, setPloading] = useState({ loading: false, message: '' })

  const baseName = `login`
  const basePath = `login`

  useEffect(() => {
  }, [])

  return (
    <>
      <div className="App mpk-full viewport-height account">
        <div className="bg">
          <div className="parallelogram-1"></div>
          <div className="parallelogram-2"></div>
          <div className="parallelogram-3"></div>
          <div className="parallelogram-4"></div>
          <div className="parallelogram-5"></div>
        <LoadingOverlay
          style={{
            position: 'inherit',
            background: 'red',
          }}
          active={ploading.loading}
          spinner
          text={ploading.message}
          >
        </LoadingOverlay>
        <div style={{ textAlign: 'center', marginTop: '100px', height: 54, }}>
          <img src="./images/unifikasi-picturemark-d.svg" style={{ position: 'relative', height: 64, width: 92 }} />
          <img src="./images/e-Buni-logotype-d.svg" style={{ position: 'relative', height: 30, margin: 9.5 }} />
          <img src="./images/logo-pajakku.svg" style={{ position: 'relative', height: 45}} />
        </div>
        <FormWrapper
          showCommandbar={false}
          style={{
            maxWidth: '20%',
            padding: 40,
            position: 'relative',
            marginTop: '36px',
          }}
          className="fullWidth"
          baseId={`mod-form-${baseName}`}
          definitions={[
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.email`),
              key: 'email',
              type: 'email',
              validation: 'required',
            },
          ]}
          additionalAction={[
            {
              show: true,
              render: ()=> (
                <Button onClick={()=> window.location.href=`/#/${basePath}`}>{t.translate("words.signIn")}</Button>
              )
            }
          ]}
          submitLabel="Send"
          onSubmit={ async (values, callback )=> {
            try {
              let res = await service.resetPassword(values)
              callback(res.data.message, false, false, false)
            } catch(e){
              errorService(e)
            }
            callback('', false, false, false)
          }}
        >
      </FormWrapper>
        </div>
      </div>
    </>
  )
}

export default inject('authStore', 'envStore')(observer(OpResetPassword))
