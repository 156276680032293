import React, {useEffect, useState} from 'react'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './LogExport.service'
import { toJS } from 'mobx';
import UtilsService from './../../services/utilsService'
import _ from 'lodash'
import errorService from './../../services/errorService'
import { Button, ListItem, List, Card, FontIcon, Divider } from 'react-md'
import iziToast from 'izitoast'
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
import { Text, TextContainer } from '@react-md/typography';
import Plot from 'react-plotly.js'
import AppMode from '../../AppMode'

const LogExportForm = ({ 
  authStore,
  match, 
  navigationStore, 
  params 
}) => {
  const { inputTypes, definition } = FormWrapper
  const [customRules, setCustomRules] = useState({})
  const [data, setData] = useState({loading: true, content: {}})
  const basePath = 'log-export'
  const baseId = 'mod-log-export-form'
  const plotdata = [{
    domain: { x: [0, 1], y: [0, 1] },
    value: data.content.progress,
    title: { text: "Export (%)" },
    type: "indicator",
    mode: "gauge+number+delta",
    delta: { reference: 100 },
    gauge: {
      axis: { range: [null, 100] },
      steps: [
        { range: [0, 50], color: "white" },
        { range: [50, 100], color: "yellowgreen" }
      ],
      threshold: {
        line: { color: "tomato", width: 4 },
        thickness: 0.75,
        value: 110
      }
    }
  }]
  const layout = {width: 600, height: 450, margin: { t: 0, b: 0 }, font: { color: "darkblue", family: "Arial" } }

  useEffect(() => {
    async function initData(){
      if(match.params.id == 'new') {
        try {
          setData({loading: false})
        } catch(e){
          errorService(e)
        }
      } else {
        const res = await service.getOne(match.params.id)
        if(res.data.status == "FAILED") res.data.progress = 100
        setData({loading: false, content: res.data})
      }
    }
    initData()
  }, [])

  var backTo = `/product/index/${basePath}`
  if(AppMode.onPremise) backTo = `/onpremise/logExport`

  return (
    <FormWrapper 
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      style = {{
        maxWidth : '100%'
      }}
      editable = {false}
      backTo = {backTo}
      baseId={`mod-form-${basePath}`}
      title={t.translate(`modules.${basePath}.formTitle`)}
      onChange={(formData, key, value)=> {}}
      customRules = {customRules}
      defaultData={data.content}
      definitions={[
        {
          render : (
            <div style={{ width: '100%' }}>
                <List className="mpk-paper mpk-flex-justify-center">
                  <ListItem rightAddon={
                    // <Progress type="circle" percent={100} status="success" strokeWidth = {6}/>
                    <Plot
                      data={plotdata}
                      layout={layout}
                      onInitialized={(figure) => this.setState(figure)}
                      onUpdate={(figure) => this.setState(figure)}
                    />
                  } 
                  style={{
                    fontSize: 30,
                    fontWeight: 500
                  }}
                  primaryText={
                    <>
                    <TextContainer>
                      {/* <Text type="subtitle-1">Progress</Text> */}
                      <Text type="headline-3">Proses Export</Text>
                      <Text type="subtitle-1">{data.content.type}</Text>
                    </TextContainer>
                    <Button themeType="contained" theme="error" style={{color: 'white'}} onClick={()=> window.open(data.content.url)}>
                      <FontIcon className="mdi mdi-download"></FontIcon>
                      Download File
                    </Button>
                    </>
                  } />
                  {/* <ListItem secondaryText={<strong>{t.translate('words.deposit')}</strong>} /> */}
               </List>
            </div>
          )
        },
        {
          inputType: inputTypes.DIVIDER,
          label: t.translate('words.detail'),
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.id`),
          key: 'id',
          type: 'text',
          width : '50%',
          disabled: true,
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.objectName`),
          key: 'objectName',
          type: 'text',
          width : '50%',
          disabled: true,
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.counter`),
          key: 'counter',
          type: 'text',
          width : '50%',
          disabled: true,
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.total`),
          key: 'total',
          type: 'text',
          width : '50%',
          disabled: true,
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.message`),
          key: 'message',
          type: 'text',
          width : '50%',
          disabled: true,
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.status`),
          key: 'status',
          type: 'text',
          width : '50%',
          disabled: true,
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.createdBy`),
          key: 'createdBy',
          type: 'text',
          width : '50%',
          disabled: true,
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.createdDate`),
          key: 'createdDate',
          type: 'text',
          width : '50%',
          disabled: true,
        },
      ]}
      submitLabel = "download file"
      onSubmit= {[]}
    />
  )
}

export default inject('authStore', 'envStore', 'navigationStore')(observer(LogExportForm))
