import CrudService from '../../services/crudService'
import { http } from '../../libs/react-mpk/services';
import AppMode from '../../AppMode'

let service = null
let serviceUri = '/file/manage'
if(AppMode.onPremise){
  service = new CrudService('/api/eunifikasi/file/manage/log/upload/csv');
  serviceUri = '/api/eunifikasi/file/manage'
} else {
  service = new CrudService('/file/manage/log/upload/csv');
}

service.importData = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUri}/upload/csv`,
    data
  })
}
service.addCsv = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUri}/upload/csv`,
    data
  })
}

service.getLog = async(query, fileId)=> {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUri}/validation/upload/csv/${fileId}`,
    query
  })
}

service.download = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUri}/download/template`,
    query
  })
}

service.downloadFile = async(fileId)=> {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUri}/download/upload/csv/${fileId}`
  })
}

service.downloadLog = async(id, query) => {
  return http.request({
     method: http.methods.GET,
     url: `${serviceUri}/download/upload/csv/log/${id}`,
     query
   })
 }

 service.reupload = async(data) => {
  return http.request({
     method: http.methods.POST,
     url: `${serviceUri}/log/upload/csv/check-failed`,
     data
   })
 }
 
export default service