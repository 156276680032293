import Cookies from 'js-cookie';  //Tambahan Uci

var build_env_js_app_mode = 'development'

try {
    // build_env_js_app_mode = document.cookie.split('appmode=').join('').split(';')[0]
    build_env_js_app_mode = Cookies.get('appmode') //Tambahan Uci
    if(build_env_js_app_mode == "development"){
        build_env_js_app_mode = 'dev'
    } else {
        build_env_js_app_mode = 'prod'
    }
} catch(e){
    // console.log(e)
}

var config = {
    onPremise: false,
    perubahanDjp: true,
    mode: build_env_js_app_mode,
    wsUrl: 'wss://bunifikasi.staging.pajakku.com/notification/ws'
}

if(config.mode == 'dev'){
    config.wsUrl = 'wss://bunifikasi.staging.pajakku.com/notification/ws'
} else {
    config.wsUrl = 'wss://bunifikasi.pajakku.com/notification/ws'
}
if(config.mode == "dev"){
    config.wsUrl = 'wss://bunifikasi.staging.pajakku.com/notification/ws'
} else {
    config.wsUrl = 'wss://bunifikasi.pajakku.com/notification/ws'
}

export default config