import React, {useEffect, useState} from 'react'
import { inject, observer } from 'mobx-react'
import LoaderInfo from '../../libs/react-mpk/components/LoaderInfo'
import iziToast from 'izitoast'
import t from 'counterpart'
import DataEsspService from '../DataEssp/DataEssp.service'
import errorService from '../../services/errorService'
import moment from 'moment'
import AppMode from './../../AppMode'

var env = {
    mode: AppMode.mode
}

const EbillingWidget = ({
  modalStore,
  authStore,
  className       = '',
  showCommandbar  = true,
  history,
  match
}) => {

  const basePath = `ebilling-widget`
  const baseId = 'mod-ebilling-widget'
  const [ploading, setPloading] = useState({ loading: true, message: '' })
  var [count, setCount] = useState(0)

  useEffect(()=> {
    var url = ''
    if(env.mode == 'prod'){
        url = 'https://ebilling.pajakku.com/static/libs/idbill-widget-pajakku.min.js'
    } else {
        url = 'https://e-billing.staging.pajakku.com/static/libs/idbill-widget-pajakku.min.js'
        // url = 'http://localhost:9100/static/libs/idbill-widget-pajakku.js'
    }
    // console.log(url, "TELL ME")
    const script = document.createElement("script");
    script.src = url;
    script.async = false;
    document.body.appendChild(script);
    
    var countDown = setInterval(() => {
      setCount(count++)
    }, 500)
    window.countDown = countDown
  }, [])

  var widgetClientId = ''
  if(env.mode == 'prod'){
      widgetClientId = "5HRCQqcTbnf6V32U6SMn4zKzEw9xwC9X8sSvhsv8"
  } else {
      widgetClientId = "BdJP5n1hzZNT5o7GAX5GWEQbJnJXvW6nUtNnm5ig"
  }

  useEffect(()=> {
    if(window.idBill){
      clearInterval(window.countDown)
      setPloading({ loading: false, message: '' })
      window.idBill.setOnMessageCallback( async (message) => {
        let { type } = message;
        switch(type){
          case 'on-get-new-access-token':
            authStore.getAccessToken()
            break;
          case 'ntpn':
            var dataEssps = []
            try {
              message.response.map((d)=> {
                dataEssps.push({
                  sptNpwp: d.npwp,
                  sptYear: parseInt(d.year),
                  sptMonth: parseInt(d.bulan1),
                  sptRev: d.pembetulan,
                  date: moment(new Date(d.paymentDate)).format('YYYY-MM-DD'),  
                  npwpSsp: d.npwp,
                  type: true,
                  no: d.ntpn,
                  total: d.amount,
                  kap: d.jpCode,
                  kjs: d.jsCode,
                })
              })
              let pullEssp  = await DataEsspService.esspPull(dataEssps)
              iziToast.success({
                title: t.translate('words.tarikNtpn'),
                message: `${t.translate('words.prosesDone')} ${t.translate('words.tarikNtpn')}`
              })
            } catch(e){
              errorService(e)
            }
            break;
          default:
          return;
        }
      })
      window.idBill.render('id-bill-container', {
        accessToken: authStore.getAccessToken(),
        widgetClientId: widgetClientId,
        // additionalId: 1,
        // additionalUsername: 'sinatria',
        // flag: 'OKOKOKOK'
      }, (event) => {});
    } if(count === 20){
      clearInterval(window.countDown)
      iziToast.error({
        title: `${t.translate('words.failed')}`,
        message: `${t.translate('words.ebillingWidgetError')}`
      })
    }
  }, [count])

  return (
    <div id="id-bill-container" className="mpk-full full-width full-height">
      { ploading.loading && <LoaderInfo /> }
    </div>
  )
}

export default inject('modalStore', 'authStore')(observer(EbillingWidget))
