import React, { useEffect } from 'react'
import { Router, Switch, Route, Redirect } from "react-router-dom"
import Spt from './Spt.routes'
import SptSupport from './Spt.support.routes'
import Company from './Company.routes'
import Index from './Index.routes'
import { autorun, toJS, values } from 'mobx';
import { inject, observer } from 'mobx-react'
import { Client, Message } from '@stomp/stompjs'
import iziToast from 'izitoast'
import AppMode from './../../AppMode'
import { Notifikasi } from '../../modules'

const Product = ({ history, navigationStore, authStore }) => {
  if (window.location.pathname === '/product')
    setTimeout(() => navigationStore.redirectTo('/product/index'), 500)

  return (
    <Router history={history}>
      <Switch>
        <Route
          path='/product/company/:companyId/daftar-spt/open/:sptId'
          render={props => (
            <Spt {...props} />
          )}
        />
        <Route
          path='/product/company/:companyId'
          render={props => (
            <Company {...props} />
          )}
        />
        <Route
          path='/product/index/daftar-spt/open/:sptId'
          render={props => (
            <SptSupport {...props}/>
          )}
        />
        <Route
          path='/product/index'
          render={props => (
            <Index {...props} />
          )}
        />
      </Switch>
    </Router>
  )
}

export default inject('navigationStore', 'authStore')(observer(Product))