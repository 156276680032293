import React, {useEffect, useState} from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import service from './OpPaymentAccount.service'
import { Chip, ListItem, List, Button} from 'react-md'
import bluebird from 'bluebird'
import { inject, observer } from 'mobx-react'
import errorServiceGet from '../../services/errorServiceGet'
import errorService from '../../services/errorService'
import fileSaver from 'file-saver';
import iziToast from 'izitoast'
import DataForm from '../../libs/react-mpk/components/DataForm'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import moment from 'moment'

const OpPaymentAccount = ({
  className       = '',
  showCommandbar  = true,
  history         = '',
  modalStore,
  onSearch        = false,
  match,
  ...props
}) => {
  const [data, setData] = useState([])
  const [getStatus, setStatus] = useState({})
  const [ploading, setPloading] = useState({ loading: false, message: '' })
  let [search, setSearch] = useState({})

  const baseId = 'mod-op-payment-account'
  const [basePath, setBasePath] = useState('paymentAccount')

  useEffect(async () => {
    if(localStorage.getItem(`${baseId}-saved-search`)){
      setSearch(JSON.parse((localStorage.getItem(`${baseId}-saved-search`))))
    }
  }, []) 

  async function initData() {
    try{
      const status = await service.coreStatus()
      setStatus(status.data)
    } catch{

    }
  }

  async function deleteBulkExc(item) {
    try {
      var proses = t.translate('words.hapus')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = [1]
      selected.map((d)=> selectedData.push(data[d]))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries(selectedData, async (di, di2)=> {
        setTimeout(async ()=> {
          try {
            let proccedThis = await service.delete(item.id)
          } catch(e){
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
          if((di2+1) == selectedData.length){
            setPloading({ loading: false, message: `` })
            if(errors.length == 0){
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er)=> {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )  
              })
            }
            try{
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if(checkboxRoot.checked){
                if(checkboxRoot.getAttribute('aria-checked')){
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            }catch(e){}
          }
        }, 1000 * di2)
      })
  } catch(e) {
      errorService(e)
    }
  }

  async function deleteBulk(item) {
    modalStore.showConfirm({
      title : t.translate('words.hapus'),
      children : t.translate('words.hapusIni'),
      onSubmit : (callback) => {
        deleteBulkExc(item)
        callback()
      }
    })
  }

  var actions = [
    new TableWrapper.action(`${t.translate('words.add')}`, 'mdi mdi-plus', (item) => {
      if(onSearch){
        history.push(`/onpremise/${basePath}/new`)
      } else {
        history.push(`${basePath}/new`)
      }
    }, true),
  ]

  var itemActions = [
    new TableWrapper.action(`${t.translate('words.Edit')}`, 'mdi mdi-pencil', (item) => {
      if(onSearch){
        history.push(`/onpremise/${basePath}/${item.id}`)
      } else {
        history.push(`${basePath}/${item.id}`)
      }
    }, true),
    new TableWrapper.action('Delete', 'mdi mdi-delete', (item) => { deleteBulk(item)}, true),
  ]

  try {
    if(onSearch){
      showCommandbar = false
      actions = []
      itemActions = [
        new TableWrapper.action(`${t.translate('words.pick')}`, 'mdi mdi-check', (item) => {
          setTimeout(()=> {
            props.setData({
              loading: false,
              content: {
                companyId: item.companyId,
                name: item.name,
                accountNumber: item.accountNumber,
                paymentAccount: item.id
              }
            })
            props.setShowModal(false)
          }, 500)
        }, true),
      ]
    }
  } catch(e){}

  return (
    <>
    <TableWrapper useFilter={false}
      selectable = {false}
      baseId = {baseId}
      title = {t.translate(`modules.${basePath}.title`)}
      className = {className}
      defaultData={[]}
      defaultSortBy="createdDate"
      sideHeader={
        <DataForm 
          baseId={`${baseId}-search`}
          defaultData={search}
          submitIconClassName = 'mdi mdi-magnify'
          submitLabel={`${t.translate('words.cari')}`}
          additionalAction={[
            {
              show: true,
              render: ()=> (
                <Button style={{ marginRight: 15 }} onClick={()=> {
                  setSearch({})
                  var newsearch = { page: 0 }; setSearch(newsearch); localStorage.removeItem(`${baseId}-saved-search`); localStorage.removeItem(`${baseId}-query`);
                  setTimeout(()=> {
                    document.getElementsByClassName('mdi-reload')[0].click()
                  }, 1000)
                }}>{t.translate('words.hapus')}</Button>
              )
            }
          ]}
          onSubmit={(values, callback)=> {
            values.page = 0;setSearch(values);localStorage.setItem(`${baseId}-saved-search`, JSON.stringify(values))
            setTimeout(()=> {
              document.getElementsByClassName('mdi-reload')[0].click()
              callback("", false, false, false)
            }, 1000)
          }}
          definitions={[
            {
              render : (
                <div>
                  {getStatus.success &&
                    <Chip style={{ background: "#4CAF50", color: 'white' }}>{t.translate("words.connected")}</Chip>
                  }
                  {!getStatus.success &&
                    <Chip style={{ background: "#F44336", color: 'white' }}>{t.translate("words.notConnected")}</Chip>
                  }
                </div>
              )
            },
            {
              render: (
                <div className="mpk-data-filter">
                  <div className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}>
                    <p>{t.translate(`words.pencarian`)}</p>
                  </div>
                </div>
              )
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.userId`),
              key: 'name.contains'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.companyId`),
              key: 'companyId.contains',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.accountNumber`),
              key: 'accountNumber.contains'
            },
          ]}
        />
      }
      columns={[
        {
          label: t.translate('words.bank'),
          searchable: true,
          sortable : true,
          key:'bank',
          render: item => (item.orderType.name)
        },
        {
          label: t.translate('words.userId'),
          searchable: true,
          sortable : true,
          key:'name',
          render: item => (item.name)
        },
        {
          label: t.translate('words.companyId'),
          searchable: true,
          sortable : true,
          key:'companyId',
          render: item => (item.companyId)
        },
        {
          label: t.translate('words.accountNumber'),
          searchable: true,
          sortable : true,
          key:'accountNumber',
          render: item => (item.accountNumber)
        },
        {
          label: t.translate('words.createdBy'),
          searchable: true,
          sortable : true,
          key: 'createdBy',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.createdBy)
        },
        {
          label: t.translate('words.createdDate'),
          searchable: true,
          sortable : true,
          key: 'createdDate',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.createdDate)
        },
        {
          label: t.translate('words.lastModifiedBy'),
          searchable: true,
          sortable : true,
          key: 'lastModifiedBy',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.lastModifiedBy)
        },
        {
          label: t.translate('words.lastModifiedDate'),
          searchable: true,
          sortable : true,
          key: 'lastModifiedDate',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.lastModifiedDate)
        },
      ]}
      actions={actions}
      itemActions={itemActions}
      onFetchData={ (params)=> {
        params = {...params,...search}
        return (
          new Promise(async (resolve, reject)=> {
            params = {...params,...search}
            try {
              await initData()
              let getPage = {}
              params.sortBy = params.sort.split(',')[0]
              if(onSearch){ 
                var temp = JSON.parse(localStorage.getItem('paymentOrder'))
                getPage = await service.getPersepsiByBankId(params, temp.paymentMethod.id)
              } else {
                getPage = await service.get(params)
              }
              delete search.page; setSearch(search); getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
              setData(getPage.data)
              resolve(getPage)
            } catch(e){
              resolve({ data: [] })
              errorService(e)
              // console.log(e)
            }
          })
        )
      }}
      showCommandbar={showCommandbar}
    />
  </>
  )
}

export default inject('temporaryStore', 'modalStore')(observer(OpPaymentAccount))
