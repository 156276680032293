import CrudService from "services/crudService";
import { http } from "../../libs/react-mpk/services";
import AppMode from "../../AppMode";

class LogEmailSupportService extends CrudService {
   constructor() {
      let baseUrl = "/bp/log/mail/manage";
      if (AppMode.onPremise) baseUrl = "/api/eunifikasi" + baseUrl;
      super(baseUrl);
   }

   // getOrganization(query) {
   //    return http.request({
   //       method: http.methods.GET,
   //       url: `/core/host/organization`,
   //       query,
   //    });
   // }

   exportCurrent(data, params = {}) {
      return http.request({
         method: http.methods.POST,
         url: `${this.path}/export`,
         data,
         config: {
            params: params,
         },
      });
   }

   exportAll(query) {
      return http.request({
         method: http.methods.GET,
         url: `${this.path}/export`,
         query,
      });
   }

   getBySpt(query) {
      return http.request({
         method: http.methods.GET,
         url: `/bp/log/mail/spt`,
         query,
      });
   }
}

const service = new LogEmailSupportService();
export default service;
