import React, { useEffect, useState } from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import Modal from '../../libs/react-mpk/components/Modal'
import t from 'counterpart'
import service from './SspArchive.service'
import moment from 'moment'
import { toast } from '../../libs/react-mpk/services'
import { format } from '../../libs/react-mpk/services/number.service'
import { Chip, ListItem, List, Button, Dialog, DialogHeader, DialogContent, DialogTitle, TextField, Table, TableBody, TableRow, TableCell } from 'react-md'
import { Flex } from '../../libs/react-mpk/components'
import ErrorService from './../../services/errorService'
import errorService from './../../services/errorService'
import { inject, observer } from 'mobx-react'
import iziToast from 'izitoast'
import LoadingOverlay from 'react-loading-overlay'
import bluebird from 'bluebird'
import errorServiceGet from '../../services/errorServiceGet'
import DataForm from '../../libs/react-mpk/components/DataForm'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import AppMode from '../../AppMode'
import SptService from '../DaftarSpt/DaftarSpt.service'
import { toJS } from 'mobx'
import UtilsService from './../../services/utilsService'

const SspArchive = ({
  className = '',
  showCommandbar = true,
  history,
  match,
  modalStore,
  authStore
}) => {

  const basePath = `ssp`
  const [totalSsp, setTotalSsp] = useState(0)
  const [totalSsp2, setTotalSsp2] = useState(0)
  const [ploading, setPloading] = useState({ loading: false, message: '' })
  const [data, setData] = useState([])
  const [fetchParam, setFetchParam] = useState([])
  const baseId = 'mod-ssp-archive'
  let [search, setSearch] = useState({})
  const [selectable, isSelectable] = useState(false)
  const [organization, setOrganization] = useState([])
  var insideSpt = false
  const [exportDialog, setExportDialog] = useState(false);

  useEffect(() => {
    if (localStorage.getItem(`${baseId}-saved-search`)) {
      setSearch(JSON.parse((localStorage.getItem(`${baseId}-saved-search`))))
    }
    if (window.location.href.indexOf('open') != -1) {
      isSelectable(true)
    }
    localStorage.setItem("essp", JSON.stringify(null))

    if (AppMode.onPremise) {
      getOrganizationsOp()
    } else {
      getOrganizations()
    }
  }, [])

  const getOrganizations = async (values)=> {
    var params = {
      page: 0,
      size: 1000
    }
    if(isNaN(values)){
      params['field'] = 'name'
      params['query'] = values
    } else {
      params['field'] = 'npwp'
      params['query'] = values
    }
    let organization = await service.getOrganization(params)
    var getAll = organization.data
    var organizationsFilter = []
    try {
      getAll.map((og)=> {
        og.label = og.npwp + " - " + og.name
        if(og.certExists) organizationsFilter.push({
          value: og,
          label: og.label
        })
      })
    } catch(e){
      organization.data.map((d)=> {
        d.label = d.npwp + " - " + d.name
        organizationsFilter.push({
          value: d,
          label: d.label
        })
      })
    }
    setOrganization(organizationsFilter)
    return organizationsFilter
  }

  const getOrganizationsOp = async (values) => {
    try {
      var params = {
        page: 0,
        size: 100,
        sort: 'createdDate,DESC'
      }
      if (isNaN(values)) {
        params['name.contains'] = values
      } else {
        params['npwp.contains'] = values
      }
      let res = await SptService.getOrganizationOp(params)
      res.data.map((d) => {
        d.label = d.npwp + " - " + d.name
        d.value = d.npwp
      })
      setOrganization(res.data)
      return res.data
    } catch (e) {
      ErrorService(e)
      return []
    }
  }


  async function hapusExc(item) {
    try {
      var proses = t.translate('words.hapus')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d) => selectedData.push(data[d]))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries(selectedData, async (di, di2) => {
        setTimeout(async () => {
          try {
            let proccedThis = await service.delete(di.id)
          } catch (e) {
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2 + 1}/${selectedData.length}` })
          if ((di2 + 1) == selectedData.length) {
            setPloading({ loading: false, message: `` })
            if (errors.length == 0) {
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er) => {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )
              })
            }
            try {
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if (checkboxRoot.checked) {
                if (checkboxRoot.getAttribute('aria-checked')) {
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            } catch (e) { }
          }
        }, 1000 * di2)
      })
    } catch (e) {
      errorService(e)
    }
  }

  async function hapus(item) {
    modalStore.showConfirm({
      title: t.translate('words.delete'),
      children: t.translate('words.hapusIni'),
      onSubmit: (callback) => {
        hapusExc(item)
        callback()
      }
    })
  }

  async function initData() {
    setTimeout(async ()=> {
      try {
        if (window.location.href.indexOf('open') != -1) {
          let spt = JSON.parse(localStorage.getItem('spt'))
          let totalSsp = await service.sptSummary({
            sptNpwp: spt.npwp,
            sptYear: spt.year,
            sptMonth: spt.month,
            sptRev: spt.rev
          })
          setTotalSsp(totalSsp.data.total)
        } else {
          let totalSsp = await service.summary()
          setTotalSsp(totalSsp.data.total)
        }
      } catch (e) {
        ErrorService(e)
      }
    }, 2000)
  }

  var actions = [
    // new TableWrapper.action(`${t.translate('words.Delete')}`, 'mdi mdi-delete', (item) => { hapus(item)}, true)
    new TableWrapper.action(`${t.translate('words.exportData')}`, 'mdi mdi-file-export', (item) => { exportData(item)}, true)
  ]
  if (window.location.href.indexOf('open') != -1) {
    actions = [
      new TableWrapper.action(`${t.translate('words.exportData')}`, 'mdi mdi-file-export', (item) => { exportData(item)}, true),
      new TableWrapper.action(`${t.translate('words.Delete')}`, 'mdi mdi-delete', (item) => { hapus(item) }, true),
      new TableWrapper.action(`${t.translate('words.Add')}`, 'mdi mdi-plus', () => history.push(`${basePath}/new`), true)
    ]
  }

  try {
    var spt = JSON.parse(localStorage.getItem('spt'))
    if (spt.status == 'FINISH' || spt.status == 'SUBMIT') {
      actions = [
        new TableWrapper.action(`${t.translate('words.exportData')}`, 'mdi mdi-file-export', (item) => { exportData(item)}, true)
      ]
    }
  } catch (e) { }

  var itemActions = [
    new TableWrapper.action(`${t.translate('words.history')}`, 'mdi mdi-history', (item) => {
      localStorage.setItem('bp', JSON.stringify(item))
      history.push(`${basePath}/${item.id}/history`)
    }, true),
  ]
  if (window.location.href.indexOf('open') != -1) {
    itemActions = [
      new TableWrapper.action(`${t.translate('words.Edit')}`, 'mdi mdi-pencil', (item) => history.push(`${basePath}/${item.id}`), true),
      new TableWrapper.action(`${t.translate('words.history')}`, 'mdi mdi-history', (item) => {
        localStorage.setItem('bp', JSON.stringify(item))
        history.push(`${basePath}/${item.id}/history`)
      }, true),
    ]
  }

  var searchDef = [
    {
      render: (
        <div className="mpk-data-filter">
          <div className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}>
            <p>{t.translate(`words.pencarian`)}</p>
          </div>
        </div>
      )
    },
    {
      render: (
        <div style={{ width: '100%' }}>
          {!AppMode.onPremise &&
            <Button onClick={() => exportData()} style={{ width: '100%' }} theme="primary" themeType="contained">{t.translate(`words.exportData`)}</Button>
          }
        </div>
      )
    },
    {
      inputType: inputTypes.CHECKBOX,
      label: t.translate(`words.allNpwps`),
      key: 'allNpwps',
      type: 'text',
      show : AppMode.onPremise ? false : true
    },
    {
      inputType: inputTypes.REACT_SELECT,
      label: t.translate(`words.npwp`),
      key: 'sptNpwp.in',
      labelKey: 'label',
      valueKey: 'label',
      data: organization,
      options: organization,
      async: true,
      defaultOptions: organization,
      isMulti: true,
      loadOptions: async (inputValues)=> {
        if(AppMode.onPremise){
          if(inputValues.length % 2){
            var options = await getOrganizationsOp(inputValues)
            return options
          } else {
            return organization
          }
        } else {
          if(inputValues.length % 2){
            var options = await getOrganizations(inputValues)
            return options
          } else {
            return organization
          }
        }
      },
      show: !insideSpt
    },
    {
      inputType: inputTypes.SELECT,
      label: t.translate(`words.month1`),
      key: 'month.greaterOrEqualThan',
      type: 'text',
      options: [
        { label: '1', value: '1' },
        { label: '2', value: '2' },
        { label: '3', value: '3' },
        { label: '4', value: '4' },
        { label: '5', value: '5' },
        { label: '6', value: '6' },
        { label: '7', value: '7' },
        { label: '8', value: '8' },
        { label: '9', value: '9' },
        { label: '10', value: '10' },
        { label: '11', value: '11' },
        { label: '12', value: '12' },
      ]
    },
    {
      inputType: inputTypes.SELECT,
      label: t.translate(`words.month2`),
      key: 'month.lessOrEqualThan',
      type: 'text',
      options: [
        { label: '1', value: '1' },
        { label: '2', value: '2' },
        { label: '3', value: '3' },
        { label: '4', value: '4' },
        { label: '5', value: '5' },
        { label: '6', value: '6' },
        { label: '7', value: '7' },
        { label: '8', value: '8' },
        { label: '9', value: '9' },
        { label: '10', value: '10' },
        { label: '11', value: '11' },
        { label: '12', value: '12' },
      ]
    },
    {
      inputType: inputTypes.INPUT,
      label: t.translate(`words.year`),
      key: 'year.equals',
      type: 'text'
    },
    {
      inputType: inputTypes.INPUT,
      label: t.translate(`words.rev`),
      key: 'rev.equals',
      type: 'text'
    },
    {
      inputType: inputTypes.INPUT,
      label: t.translate(`words.kjs`),
      key: 'kjs.contains',
      type: 'text'
    },
    {
      inputType: inputTypes.INPUT,
      label: t.translate(`words.kodeAkunPajak`),
      key: 'kap.contains',
      type: 'text'
    },
    {
      inputType: inputTypes.INPUT,
      label: t.translate(`words.nomorBuktiPenyetoran`),
      key: 'no.contains',
      type: 'text'
    },
    {
      inputType: inputTypes.DATEPICKER,
      label: t.translate(`words.date`),
      key: 'date.equals',
      type: 'date'
    },
    {
      inputType: inputTypes.SELECT,
      label: t.translate(`words.status`),
      key: 'status.equals',
      type: 'text',
      options: [
        { label: 'CREATED', value: 'CREATED' },
        { label: 'POSTING', value: 'POSTING' },
        { label: 'COMPLETED', value: 'COMPLETED' },
        { label: 'FINISH', value: 'FINISH' },
        { label: 'FAILED', value: 'FAILED' },
        { label: 'DELETED', value: 'DELETED' },
      ]
    },
  ]

  if (window.location.href.indexOf('open') != -1) {
    searchDef = [
      {
        render: (
          <div className="mpk-data-filter">
            <div className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}>
              <p>{t.translate(`words.pencarian`)}</p>
            </div>
          </div>
        )
      },
      {
        inputType: inputTypes.INPUT,
        label: t.translate(`words.kjs`),
        key: 'kjs.contains',
        type: 'text'
      },
      {
        inputType: inputTypes.INPUT,
        label: t.translate(`words.kodeAkunPajak`),
        key: 'kap.contains',
        type: 'text'
      },
      {
        inputType: inputTypes.INPUT,
        label: t.translate(`words.nomorBuktiPenyetoran`),
        key: 'no.contains',
        type: 'text'
      },
      {
        inputType: inputTypes.DATEPICKER,
        label: t.translate(`words.date`),
        key: 'date.equals',
        type: 'date'
      },
      {
        inputType: inputTypes.SELECT,
        label: t.translate(`words.status`),
        key: 'status.equals',
        type: 'text',
        options: [
          { label: 'CREATED', value: 'CREATED' },
          { label: 'POSTING', value: 'POSTING' },
          { label: 'COMPLETED', value: 'COMPLETED' },
          { label: 'FINISH', value: 'FINISH' },
          { label: 'FAILED', value: 'FAILED' },
          { label: 'DELETED', value: 'DELETED' },
        ]
      },
    ]
  }

  var exportData = () => {
    setExportDialog(true)
  }

  var exportSelected = async (dataMod) => {
    setExportDialog(false)
    var exportData = []
    if(dataMod){
      exportData = dataMod
    } else {
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d) => selectedData.push(data[d]))
      exportData = selectedData
      if(selected.length == 0){
        exportData = data
      }
    }
    try {
      var proses = t.translate('words.exportData')
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      let res = await service.exportCurrent(exportData)
      setPloading({ loading: false, message: `` })
      iziToast.success({
        title: proses,
        message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
      })
      window.open(res.data.url)
    } catch(e){
      setPloading({ loading: false, message: `` })
      ErrorService(e)
    }
  }

  var exportAll = async () => {
    setExportDialog(false)
    var exportAllExc = false
    var dataSelected = []
    try {
      var cQuery = JSON.parse(localStorage.getItem(`${baseId}-query`))
      var params = fetchParam; params.size = 200;
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })

      // INIT DATA
      if (window.location.href.indexOf('open') != -1) {
        let spt = JSON.parse(localStorage.getItem('spt'))
        params.sptNpwp = spt.npwp
        params.sptYear = spt.year
        params.sptMonth = spt.month
        params.sptRev = spt.rev
        let getPage = await service.getPage(params)
        delete search.page; setSearch(search); getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
        dataSelected = getPage.data
        if(parseInt(getPage.headers['x-total-count']) > 100){
          exportAllExc = true
        } else {
          exportAllExc = false
        }
      } else {
        params['type.equals'] = true
        let getPage = await service.get(params)
        delete search.page; setSearch(search); getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
        dataSelected = getPage.data
        if(parseInt(getPage.headers['x-total-count']) > 100){
          exportAllExc = true
        } else {
          exportAllExc = false
        }
      }
      // INIT DATA
      if(exportAllExc){
        var proses = t.translate('words.exportData')
        
        let res = await service.exportAll(fetchParam)
        setPloading({ loading: false, message: `` })
        iziToast.success({
          title: proses,
          message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDoneLogExport')}`
        })
      } else {
        exportSelected(dataSelected)
      }
    } catch(e){
      setPloading({ loading: false, message: `` })
      ErrorService(e)
    }
  } 

  return (
    <>
      <LoadingOverlay
        style={{
          position: 'inherit',
          background: 'red'
        }}
        active={ploading.loading}
        spinner
        text={ploading.message}
      >
      </LoadingOverlay>
      <Dialog
        visible={exportDialog}
        disableFocusOnMount={() => { }}
        onRequestClose={() => {
          setExportDialog(false)
        }}
        style={{
          width: 800,
        }}
      >
        <DialogHeader>
          <DialogTitle>{t.translate('words.exportData')}</DialogTitle>
        </DialogHeader>
        <DialogContent>
          <Button themeType="outline" theme="primary" style={{ marginRight: '12px' }} onClick={() => exportSelected()}>{t.translate('words.exportDataSelected')}</Button>
          <Button themeType="outline" onClick={() => exportAll()}>{t.translate('words.exportDataAll')}</Button>
        </DialogContent>
      </Dialog>
      <TableWrapper useFilter={false}
        tableFooter={(
          <Table style={{ width: '100%' }} dense disableBorders>
            <TableBody>
              <TableRow>
                <TableCell style={{width: '150px'}}><b>{t.translate('words.totalSsp')}</b></TableCell>
                <TableCell style={{width: '10px'}}>:</TableCell>
                <TableCell><b style={{ float: 'right' }}>{format(totalSsp2)}</b></TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{width: '150px'}}><b>{t.translate('words.totalSsp2')}</b></TableCell>
                <TableCell style={{width: '10px'}}>:</TableCell>
                <TableCell><b style={{ float: 'right' }}>{format(totalSsp)}</b></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
        baseId={baseId}
        title={t.translate('modules.sspArchive.title')}
        className={className}
        defaultData={[]}
        selectable={selectable}
        defaultSortBy="id"
        columns={[
          {
            label: t.translate('words.nomorBuktiSetor'),
            searchable: true,
            sortable: true,
            key: 'no',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.no)
          },
          {
            label: t.translate('words.status'),
            searchable: true,
            key: 'status',
            type: TableWrapper.dataTypes.STRING,
            render: item => {
              if (item.status == "UPLOADING") {
                return <Chip style={{ background: "#FFC108", color: 'white' }}>{(item.status + "").toUpperCase()}</Chip>
              } else if (item.status == "COMPLETED") {
                return <Chip style={{ background: "#2096F3", color: 'white' }}>{(item.status + "").toUpperCase()}</Chip>
              } else if (item.status == "FINISH") {
                return <Chip style={{ background: "#8BC34A", color: 'white' }}>{(item.status + "").toUpperCase()}</Chip>
              } else if (item.status == "FAILED") {
                return <Chip style={{ background: "#F44336", color: 'white' }}>{(item.status + "").toUpperCase()}</Chip>
              } else if (item.status == "DELETED") {
                return <Chip style={{ background: "grey", color: 'white' }}>{(item.status + "").toUpperCase()}</Chip>
              } else {
                return <Chip style={{ background: "black", color: 'white' }}>{(item.status + "").toUpperCase()}</Chip>
              }
            }
          },
          {
            label: t.translate('words.type'),
            searchable: true,
            sortable: true,
            key: 'type',
            type: TableWrapper.dataTypes.BOOLEAN,
            render: item => {
              if (item.type) {
                return <Chip>SSP</Chip>
              } else {
                return <Chip>PBK</Chip>
              }
            }
          },
          {
            label: t.translate('words.kodeAkunPajak'),
            searchable: true,
            sortable: true,
            key: 'kap',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.kap)
          },
          {
            label: t.translate('words.kodeJenisSetoran'),
            searchable: true,
            sortable: true,
            key: 'kjs',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.kjs)
          },
          {
            label: t.translate('words.tanggal'),
            searchable: true,
            sortable: true,
            key: 'date',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.date)
          },
          {
            label: t.translate('words.pphDisetor'),
            searchable: true,
            sortable: true,
            key: 'total',
            type: TableWrapper.dataTypes.NUMBER,
            render: item => (format(item.total))
          },
          {
            label: t.translate('words.sptMonth'),
            searchable: true,
            sortable: true,
            key: 'sptMonth',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.sptMonth),
            show : window.location.href.indexOf('open') != -1 ? false : true
          },
          {
            label: t.translate('words.sptYear'),
            searchable: true,
            sortable: true,
            key: 'sptYear',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.sptYear),
            show : window.location.href.indexOf('open') != -1 ? false : true
          },
          {
            label: t.translate('words.revisiSpt'),
            searchable: true,
            sortable: true,
            key: 'sptRev',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.sptRev),
            show : window.location.href.indexOf('open') != -1 ? false : true
          },
          {
            label: t.translate('words.message'),
            searchable: true,
            key: 'message',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.message)
          },
          {
            label: t.translate('words.createdBy'),
            searchable: true,
            sortable: true,
            key: 'createdBy',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.createdBy)
          },
          {
            label: t.translate('words.createdDate'),
            searchable: true,
            sortable: true,
            key: 'createdDate',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.createdDate)
          },
          {
            label: t.translate('words.lastModifiedBy'),
            searchable: true,
            sortable: true,
            key: 'lastModifiedBy',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.lastModifiedBy)
          },
          {
            label: t.translate('words.lastModifiedDate'),
            searchable: true,
            sortable: true,
            key: 'lastModifiedDate',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.lastModifiedDate)
          },
        ]}
        actions={actions}
        itemActions={itemActions}
        setPage={search.page} onFetchData={(params) => {
          return (
            new Promise(async (resolve, reject) => {
              params = {...params,...search}
              try{
                initData()
                params = {...params,...search}
                if (window.location.href.indexOf('open') != -1) {
                  let spt = JSON.parse(localStorage.getItem('spt'))
                  params.sptNpwp = spt.npwp
                  params.sptYear = spt.year
                  params.sptMonth = spt.month
                  params.sptRev = spt.rev
                  let getPage = await service.getPage(params)
                  delete search.page; setSearch(search); getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
                  try {
                    if(getPage.headers['x-summary'] == "true"){
                      setTotalSsp2(parseFloat(getPage.headers['x-pph']))
                    } else {
                      setTotalSsp2(0)
                    }
                  } catch(e){
                    setTotalSsp2(0)
                  }
                  setFetchParam(params)
                  setData(getPage.data)
                  resolve(getPage)
                } else {
                  params['type.equals'] = true
                  let getPage = await service.get(params)
                  delete search.page; setSearch(search); getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
                  try {
                    if(getPage.headers['x-summary'] == "true"){
                      setTotalSsp2(parseFloat(getPage.headers['x-pph']))
                    } else {
                      setTotalSsp2(0)
                    }
                  } catch(e){
                    setTotalSsp2(0)
                  }
                  setFetchParam(params)
                  setData(getPage.data)
                  resolve(getPage)
                }
              } catch(e){
                resolve()
                ErrorService(e)
              }
            })
          )
        }}
        sideHeader={
          <DataForm
            baseId={`${baseId}-search`}
            defaultData={search}
            submitIconClassName='mdi mdi-magnify'
            submitLabel={`${t.translate('words.cari')}`}
            onChange={(formData, key, val)=> {
              setFetchParam(formData)
            }}
            additionalAction={[
              {
                show: true,
                render: () => (
                  <Button style={{ marginRight: 15 }} onClick={() => {
                    setSearch({})
                    var newsearch = { page: 0 }; setSearch(newsearch); localStorage.removeItem(`${baseId}-saved-search`); localStorage.removeItem(`${baseId}-query`);
                    setTimeout(() => {
                      document.getElementsByClassName('mdi-reload')[0].click()
                    }, 1000)
                  }}>{t.translate('words.hapus')}</Button>
                )
              }
            ]}
            onSubmit={(values, callback) => {
              if (AppMode.onPremise) {
                if (values['sptNpwp.in'] && values['sptNpwp.in'].length > 0) {
                  var stringIn = ""
                  values['sptNpwp.in'].map((d) => {
                    stringIn += d.value + ","
                  })
                  values['sptNpwp.in'] = stringIn
                }
              } else {
                if (values['sptNpwp.in'] && values['sptNpwp.in'].length > 0) {
                  var stringIn = ""
                  values['sptNpwp.in'].map((d) => {
                    stringIn += d.value.npwp + ","
                  })
                  values['sptNpwp.in'] = stringIn
                }
                if(values.allNpwps){
                  var allNpwpString = ""
                  if(organization && organization.length > 0){
                    organization.map((d)=> {
                      allNpwpString += d.value.npwp + ","
                    })
                  }
                  values['sptNpwp.in'] = allNpwpString
                  delete values.allNpwps
                }
              }
              
              values.page = 0;setSearch(values);localStorage.setItem(`${baseId}-saved-search`, JSON.stringify(values))
              setTimeout(() => {
                document.getElementsByClassName('mdi-reload')[0].click()
                callback("", false, false)
              }, 1000)
            }}
            definitions={searchDef}
          />
        }
        showCommandbar={showCommandbar}
        showFilterPeriod={false}
      />
    </>
  )
}

export default inject('temporaryStore', 'modalStore', 'authStore')(observer(SspArchive))