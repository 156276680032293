import CrudService from '../../services/crudService';
import { http } from '../../libs/react-mpk/services';
import AppMode from '../../AppMode'

let service = null
let serviceUrl = '/master/manage/npwp'
if(AppMode.onPremise){
  service = new CrudService('/api/eunifikasi/master/manage/npwp');
  serviceUrl = '/api/eunifikasi/master/manage/npwp'
} else {
  service = new CrudService('/master/manage/npwp');
}

service.request = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/request/report`,
    query
  })
}

service.pemadananNpwp = async(data)=> {
    return http.request({
      method: http.methods.POST,
      url: `/master/manage/npwp/pemadanan`,
      data
    })
  }

export default service;